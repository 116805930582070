import React from 'react';
import i18next from 'i18next';
import DisplayedCurrencies from './DisplayedCurrencies';
import DisplayedDimensions from './DisplayedDimensions';
import DropDown from '../../../common/DropDown';
import './stylesheet.scss';

const ClosingDropDown = ({ title, displayIconDesktop = false, className }) => (
  <DropDown
    className={`common-drop-down-to-validate ${className || ''}`}
    title={title}
    icon="cogs"
    right
    displayIconDesktop={displayIconDesktop}
    render={({ close }) => (
      <>
        <DisplayedCurrencies />
        <DisplayedDimensions />
        <div className="submit-and-cancel">
          <button
            type="button"
            className="btn btn-default btn-flat pull-right"
            onClick={() => {
              close();
            }}
          >
            {i18next.t('search.bars.common.closingdropdown.m1')}
          </button>
        </div>
      </>
    )}
  />
);

export default ClosingDropDown;
