import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { fetchClassifieds, followClassified, unfollowClassified } from '../../../../redux/actions/myartprice/data';
import { isLogged as isLoggedSelector, getIdcustomer } from '../../../../redux/slices/userContext/selectors';
import { getFavoriteClassified } from '../../../../redux/selectors/myartprice/data';
import ButtonFollow from '../../../common/ButtonFollow';

const Follow = ({ idclassified, size, rsxFavorited = 'marketplace.classified.follow.m2', rsxNotFavorited = 'marketplace.classified.follow.m1', noLabel }) => {
  const dispatch = useDispatch();
  const isLogged = useSelector(isLoggedSelector);
  const favoriteClassifieds = useSelector(getFavoriteClassified);
  const idcustomer = useSelector(getIdcustomer);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (idcustomer && isEmpty(favoriteClassifieds)) {
      dispatch(fetchClassifieds());
    }
  }, []);

  useEffect(() => {
    if (favoriteClassifieds) {
      setIsFavorite(favoriteClassifieds.some(idfav => idfav === idclassified));
    }
  }, [favoriteClassifieds, idclassified]);

  const handleFollowClick = () => {
    if (!isLogged) {
      window.location = '/identity';
      return;
    }

    if (!isFavorite) {
      dispatch(followClassified(idclassified));
    } else {
      dispatch(unfollowClassified(idclassified));
    }
  };

  return <ButtonFollow isFavorite={isFavorite} size={size} handleFollowClick={handleFollowClick} rsxFavorited={rsxFavorited} rsxNotFavorited={rsxNotFavorited} noLabel={noLabel} />;
};

export default Follow;
