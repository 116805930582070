import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';
import axios from 'axios';
import { AUCTIONEER_FUTURE_SALES, AUCTIONEER_PAST_SALES } from '../../../constants/search/scope';
import FutureSale from './FutureSale';
import PastSale from './PastSale';
import { set } from '../../../redux/actions/ui/dictionary';
import { ACTIVE_AAPI_BEACON_FFROM, ACTIVE_MENU, MENU_FUTURE_SALES, MENU_PAST_SALES } from '../../../constants/auctioneers';
import HeaderList from '../common/HeaderList';
import { getSalesPush, getSalesProgress, getSalesShow } from '../../../redux/actions/auctioneers/sales';
import { ANALYTICS_FROM_AUCTIONEERS_FUTURE_SALES, ANALYTICS_FROM_AUCTIONEERS_PAST_SALES, AUCTIONEER_WRONG_TYPE } from '../../../constants/rails';
import { stats as statsSelector } from '../../../redux/selectors/auctioneers/stats';
import { getAgreement } from '../../../redux/selectors/agreements';
import Search from '../../search/Search';
import AdvancedButton from '../../search/helpers/AdvancedButton';
import Sort from '../../search/helpers/Sort';
import { SALES_DEFAULT } from '../../../constants/search/sorts';
import Preferences from '../../search/helpers/Preferences';
import Params from '../../search/Params';
import ArtistSelect from '../../search/helpers/items/ArtistSelect';
import IdentificationsSelect from '../../search/helpers/items/IdentificationsSelect';
import CategorySelect from '../../search/helpers/items/CategorySelect';
import Item from '../../search/Search/Item';
import Select from '../../search/inputs/Select';
import SaleDate from '../../search/helpers/items/SaleDate';
import CountrySelect from '../../search/helpers/items/CountrySelect';
import StateSelect from '../../search/helpers/items/StateSelect';
import CitySelect from '../../search/helpers/items/CitySelect';
import { queryStringToObject } from '../../../utils/http';
import { fetchResults } from '../../../services/search';
import { update } from '../../../redux/actions/dbEntities';
import { isInViewport, scrollToElement } from '../../../utils/ui';
import Keyword from '../../search/helpers/items/Keyword';
import Checkbox from '../../search/inputs/Checkbox';
import A18n from '../../common/A18n';
import Pagination from '../../common/Pagination';
import './stylesheet.scss';

const config = {
  [AUCTIONEER_FUTURE_SALES]: {
    sort: 'datesale_asc',
    period: 'future',
    activeMenu: MENU_FUTURE_SALES,
    aapiBeaconFrom: ANALYTICS_FROM_AUCTIONEERS_FUTURE_SALES,
  },
  [AUCTIONEER_PAST_SALES]: {
    sort: 'datesale_desc',
    period: 'past',
    activeMenu: MENU_PAST_SALES,
    aapiBeaconFrom: ANALYTICS_FROM_AUCTIONEERS_PAST_SALES,
  },
};

const Sales = ({ searchScope, titleRsx, noDataRsx }) => {
  const refTitle = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const stats = useSelector(state => statsSelector(state));
  const [params, setParams] = useState({
    p: 1,
    sort: config[searchScope].sort,
    ...queryStringToObject(window.location.search),
  });
  const agreement = useSelector(state => getAgreement(state, { idagreementtype: 3 }));
  const [data, setData] = useState({});

  const handlePaginationClick = p => {
    setParams({ ...params, ...{ p } });

    if (!isInViewport('#top-pagination')) {
      scrollToElement(refTitle);
    }
  };

  useDeepCompareEffect(() => {
    setData({});
    setLoading(true);
    fetchResults(params, searchScope, true)
      .then(searchData => {
        const { sales, idsales } = searchData.results;
        // update dataStore with entities
        dispatch(update({ sales }));
        setData({
          sales,
          idsales,
          page: searchData.page,
          perPage: searchData.perPage,
          totalPages: searchData.totalPages,
          totalCount: searchData.totalCount,
        });
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (!axios.isCancel(err)) {
          throw new Error(err);
        }
      });
  }, [params]);

  useEffect(() => {
    dispatch(set(ACTIVE_MENU, config[searchScope].activeMenu));
    dispatch(set(ACTIVE_AAPI_BEACON_FFROM, config[searchScope].aapiBeaconFrom));

    if (parseInt(params.p, 10) > 1 || params.sort !== config[searchScope].sort) {
      setParams({
        p: 1,
        sort: config[searchScope].sort,
      });
    }
  }, [searchScope]);

  useEffect(() => {
    if (data?.idsales) {
      dispatch(getSalesProgress({ ids: data.idsales }));
      dispatch(getSalesPush({ ids: data.idsales }));
      dispatch(getSalesShow({ ids: data.idsales }));
    }
  }, [data]);

  return (
    <div className={`auctioneers-sales ${config[searchScope].period}`}>
      <HeaderList period={config[searchScope].period} title={i18next.t(titleRsx)} hideMissingSaleLink={!loading && data.idsales && data.idsales.length === 0} />
      <Search
        autoUpdateUrl
        searchScope={searchScope}
        advancedParams={params}
        onChange={({ searchParams }) => {
          setParams(searchParams);
        }}
        horizontalBar={({ drawerId }) => (
          <>
            <div className="container-filters sales-horizontal-bar">
              <div className="container">
                <div className="filters-container">
                  <AdvancedButton drawerId={drawerId} />
                  <div className="filler" />
                  <Sort
                    right
                    options={SALES_DEFAULT}
                    value={params?.sort}
                    onChange={sortOption => {
                      setParams({
                        ...params,
                        sort: sortOption,
                      });
                    }}
                  />
                  <Preferences
                    hiddenFields={{
                      currencies: true,
                      lotsViewFormat: true,
                      dimension: true,
                      artistsViewFormat: true,
                      freshness: true,
                    }}
                    right
                    rsx={{
                      title: false,
                      perPage: 'sales.search.filters.display.itemsperpage.m1',
                    }}
                    onChange={({ itemsPerPage }) => {
                      setParams({
                        ...params,
                        ipp: itemsPerPage,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="container" style={{ marginTop: 5 }}>
              <Params
                searchScope={searchScope}
                onChange={p => {
                  setParams(p);
                }}
              />
            </div>
          </>
        )}
        drawer={() => (
          <div>
            <Keyword />
            <Item name="exact_match">
              <Checkbox label={<A18n rsx="lots.search.form.exactmatch.m1" />} />
            </Item>
            <hr />
            <ArtistSelect />
            <IdentificationsSelect />
            <div className="info">{i18next.t('lots.search.filters.others.m2')}</div>
            <hr />
            <CategorySelect />
            <hr />
            <Item label={i18next.t('lots.search.filters.inputs.auctioneer.m1')} name="idauctioneer">
              <Select facet="idauctioneer" placeholder={i18next.t('components.search.inputs.auctioneer_search.m3')} />
            </Item>
            <Item label={i18next.t('components.search.helpers.items.sale_type.m1')} name="idsaletype">
              <Select facet="idsaletype" placeholder={i18next.t('components.search.helpers.items.sale_type.m2')} />
            </Item>
            <SaleDate />
            <hr />
            <CountrySelect />
            <StateSelect />
            <CitySelect />
          </div>
        )}
      >
        {data.totalCount > data.perPage && (
          <div
            id="top-pagination"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Pagination className="pagination" perPage={data.perPage} pagePadding={1} loading={loading} totalCount={data.totalCount} activePage={data.page} onClick={p => handlePaginationClick(p)} />
          </div>
        )}

        <div className="data-container" style={{ marginTop: 25 }}>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 data">
                {!loading &&
                  data.idsales &&
                  data.idsales.map(idsale => {
                    if (searchScope === AUCTIONEER_FUTURE_SALES) {
                      return <FutureSale key={`sale-future-${idsale}`} idsale={idsale} />;
                    }

                    return <PastSale key={`sale-past-${idsale}`} idsale={idsale} />;
                  })}

                {loading && (
                  <div className="spinner">
                    <i className="fa fa-5x fa-spinner fa-spin" />
                  </div>
                )}

                {!loading && (!data.idsales || data.idsales.length === 0) && (
                  <div className="no-data">
                    {stats.error === AUCTIONEER_WRONG_TYPE && (
                      <>
                        <p>{i18next.t('auctioneers.errors.m1')}</p>
                        <p>
                          <a href={`/auctioneer/control-center/${agreement?.signedVersion > 0 ? 'contact?key=m10' : 'agreement'}`}>{i18next.t('auctioneers.errors.m2')}</a>
                        </p>
                      </>
                    )}
                    {stats.error !== AUCTIONEER_WRONG_TYPE && <p>{i18next.t(noDataRsx)}</p>}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {data.totalCount > data.perPage && (
          <div
            id="top-pagination"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 20,
            }}
          >
            <Pagination className="pagination" perPage={data.perPage} pagePadding={1} loading={loading} totalCount={data.totalCount} activePage={data.page} onClick={p => handlePaginationClick(p)} />
          </div>
        )}
      </Search>
    </div>
  );
};

export default Sales;
