import React from 'react';
import i18next from 'i18next';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Line, LineChart, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { getChronologicalData, isChronologicalDataLoading, getChronologicalDisplayedChartTab } from '../../../../redux/selectors/indexes/chronological';
import { getFormatter, getFormatOptions } from '../../../../utils/indexes/chronological';
import { recursiveReducer } from '../../../../utils/indexes/common/rechartsTickFormat';
import { getCurrency, getLang } from '../../../../redux/selectors/preferences';
import { CustomYAxisLabel } from '../../common/CustomYAxisLabel';
import { LANGUAGES } from '../../../../constants/preferences';
import Spinner from '../../../common/spinners/Spinner';

const ChronologicalAreaChart = ({ intl }) => {
  const isDataLoading = useSelector(isChronologicalDataLoading);
  const data = useSelector(getChronologicalData);
  const currency = useSelector(getCurrency);
  const lang = useSelector(getLang);
  const tab = useSelector(getChronologicalDisplayedChartTab);

  if (isDataLoading) {
    return (
      <div style={{ height: 400 }}>
        <Spinner />
      </div>
    );
  }
  if (!data) {
    return <div style={{ height: 400 }} />;
  }

  const sortedData = [...data].sort((a, b) => b.records - a.records);
  const numberOfDigitsReduced = recursiveReducer(sortedData[0]?.records);
  const isBillionsAndLangChinese = numberOfDigitsReduced === 9 && lang === LANGUAGES.CHINESE ? 1 : 0;
  const divider = 10 ** (numberOfDigitsReduced - isBillionsAndLangChinese);
  const tickFormatter = numberOfDigitsReduced > 0 ? value => intl.formatNumber(value / divider, getFormatOptions({ tab })) : value => intl.formatNumber(value, getFormatOptions({ tab, currency }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data} margin={{ top: 5, right: 5, bottom: 5, left: -35 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickSize={5} margin={{ bottom: 5 }} tickFormatter={tickFormatter} label={numberOfDigitsReduced > 0 ? <CustomYAxisLabel currency={currency} numberOfDigitsReduced={numberOfDigitsReduced} /> : undefined} />
        <Tooltip formatter={getFormatter({ currency, tab })} />
        <Line name={i18next.t('component.indexes.record')} type="linear" stackId="1" dataKey="records" fill="#3366cc" animationDuration={400} animationBegin={35} activeDot={{ r: 9 }} />
        <Line name={i18next.t('component.indexes.average')} type="linear" stackId="1" dataKey="average" stroke="#dc3912" fill="#dc3912" animationDuration={400} animationBegin={35} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default injectIntl(ChronologicalAreaChart);
