import React, { useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { getCart, getParams, getPromocodeStatus } from '../../../../../redux/slices/payments';
import SubscriptionCartLine from './SubscriptionCartLine';
import { usePromotionDescriptionFor } from '../constant';
import A18n from '../../../../common/A18n';
import CartLine from './CartLine';
import Loading from '../Loading';
import './stylesheet.scss';

const Cart = () => {
  const cart = useSelector(getCart);
  const { promocode = '' } = useSelector(getParams);
  const [description, setDescription] = useState('');
  const [displayPromotionDescription, setDisplayPromotionDescription] = useState(false);
  const promocodeStatus = useSelector(getPromocodeStatus);
  const cartLines = get(cart, 'main_products', []);
  const mainCartLine = cartLines[0];

  useEffect(() => {
    if (usePromotionDescriptionFor.includes(promocode)) {
      if (get(cart, 'promotion_from_promocode.description')) {
        setDisplayPromotionDescription(true);
        setDescription(get(cart, 'promotion_from_promocode.description'));
      } else {
        setDisplayPromotionDescription(false);
        setDescription('');
      }
    } else {
      setDisplayPromotionDescription(false);
    }
  }, [promocode, cart]);

  if (!mainCartLine?.product || cart.loading) return <Loading />;

  const currency = cart.currency.iso3.toLowerCase();
  const mainProduct = mainCartLine.product;
  const total = get(cart, 'main_products', []).reduce((acc, lineCart) => acc + lineCart.product.prices[cart.currency.iso3.toLowerCase()] * lineCart.product_quantity, 0);
  const advantages = get(cart, 'main_products', [])
    .flatMap(({ component_products: componentProducts = [] }) => componentProducts)
    .filter(cartLine => cartLine.prices[currency] < 0);

  return (
    <>
      <div className="encart">
        <div className="row header">
          <h3>
            <span>{mainProduct.theme ? mainProduct.theme : <A18n rsx="payments.common.cartsummary.m17" />}</span>
            {/* eslint-disable-next-line react/style-prop-object */}
            <FormattedNumber value={total} style="currency" currency={currency} />
          </h3>
        </div>

        {cartLines.map(cartLine => {
          const { product } = cartLine;
          if (!product) {
            return null;
          }

          const key = `${product.id}-${get(product, `prices.${currency}`, 0)}-${product.quantity}`;
          const { isSubscription, isStore, isImage, isReduction } = product;

          if (isSubscription || isStore) {
            return <SubscriptionCartLine key={key} line={mainCartLine} currency={currency} showFirstline={false} />;
          }
          if (isImage || isReduction) {
            return null;
          }

          return <CartLine key={key} line={cartLine} currency={currency} />;
        })}
      </div>

      {!isEmpty(advantages) && promocodeStatus === 1 && (
        <div className="cart-details">
          <h4>
            {advantages.length > 1 && <A18n rsx="payments.common.cartsummary.m11" />}
            {advantages.length === 1 && <A18n rsx="payments.common.cartsummary.m12" />}
          </h4>

          {/* eslint-disable-next-line react/no-danger */}
          {displayPromotionDescription && <div className="promo-description" dangerouslySetInnerHTML={{ __html: description }} />}

          {!displayPromotionDescription &&
            advantages.map(product => (
              <div className="line" key={`${product.id}-${product.prices[currency]}-${product.quantity}`}>
                {product.title}{' '}
                {product.prices[currency] !== 0 && (
                  <span>
                    {/* eslint-disable-next-line react/style-prop-object */}
                    (<FormattedNumber value={product.prices[currency] * product.quantity} style="currency" currency={currency} />)
                  </span>
                )}
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default Cart;
