import i18next from 'i18next';
import { Link } from 'react-router';
import React, { useState, useEffect } from 'react';
import { delayedAapi } from '../../../../../services/analytics/aapi';
import { formatFns } from '../../../../../utils/dates/format';
import Follow from '../../../Follow';
import Links from '../../Links';
import NoPict from '../NoPict';
import './stylesheet.scss';

const Wide = ({ lot, urlTarget, title }) => {
  const [imgLoading, setLoading] = useState(true);

  useEffect(() => {
    if (lot.imageUrl) {
      delayedAapi('lotimage', { idlot: lot.id, index: 0, op: 'list' });
    }
  }, [lot.imageUrl]);

  return (
    <div className={`lots-tile-access-wide ${imgLoading ? 'load' : ''}`}>
      <Link to={urlTarget} id={`lot-${lot.id}`} title={`${title}`}>
        <div className="lot-img" style={{ cursor: 'cursor' }}>
          {lot.imageUrl && <img alt="" src={lot.imageUrl} onLoad={() => setLoading(false)} />}
          {!lot.imageUrl && <NoPict />}
        </div>
      </Link>
      <div className="cell-1">
        <div className="lot-title">
          <Link to={urlTarget} title={`${title}`}>
            {title}
          </Link>
        </div>

        <div className="lot-artist">{lot.artistOrAssociation}</div>

        <div className="technique">{lot.technique}</div>

        {/* eslint-disable-next-line react/no-danger */}
        {lot.dimension && (
          <div className="size">
            <span dangerouslySetInnerHTML={{ __html: lot.dimension }} />
          </div>
        )}

        {lot.estimation && (lot.estimation.high || lot.estimation.low) && (
          <div className="estimation">
            {i18next.t('lots.show.m12')}:{' '}
            <span className={!lot.isPast ? 'strong' : ''}>
              {lot.estimation.low && <span dangerouslySetInnerHTML={{ __html: lot.estimation.low }} />}
              {lot.estimation.high && lot.estimation.low && ' - '}
              {lot.estimation.high && <span dangerouslySetInnerHTML={{ __html: lot.estimation.high }} />}
            </span>
          </div>
        )}

        {lot.startingPrice && !lot.estimation && <div dangerouslySetInnerHTML={{ __html: lot.startingPrice }} />}

        {lot.isPast && lot.price && (
          <div className="prices" title={lot.lotstatus === 1 && lot.priceWithTaxes ? `${i18next.t('lots.small.m1')}: ${lot.priceWithTaxes}` : null}>
            {lot.lotstatus === 1 && (
              <>
                {i18next.t('myartprice.common.lot.m2')}: <span className="strong" dangerouslySetInnerHTML={{ __html: lot.price }} />
              </>
            )}
            {lot.lotstatus !== 1 && <span dangerouslySetInnerHTML={{ __html: lot.price }} />}
          </div>
        )}
      </div>
      <div className="cell-2">
        <div className="lot-auctioneer">
          {(!lot.saledatestatus || lot.saledatestatus === 1) && (
            <>
              {lot.saleDtStart} {lot.saleDtStart !== lot.saleDtEnd && `- ${lot.saleDtEnd}`}
              {!lot.isPast && lot.saledatestatus === 1 && (
                <i className="display-block">
                  <i className="fa fa-info-circle marg marg-r-5" />
                  {i18next.t('sale_helper.sale_date_status.m1')}
                </i>
              )}
            </>
          )}

          {!lot.isPast && lot.saledatestatus === 2 && (
            <>
              {formatFns(new Date(lot.saleDtStart), 'MMMM yyyy')} {lot.saleDtStart !== lot.saleDtEnd && `- ${formatFns(new Date(lot.saleDtEnd), 'MMMM yyyy')}`}
              <i className="display-block">
                <i className="fa fa-info-circle marg marg-r-5" />
                {i18next.t('sales.tile.sale.m4')}
              </i>
            </>
          )}

          {!lot.isPast && lot.saledatestatus === 3 && (
            <i className="display-block">
              <i className="fa fa-info-circle marg marg-r-5" />
              {i18next.t('sale_helper.sale_date_status.m2')}
            </i>
          )}
        </div>

        <div className="lot-auctioneer" title={lot.auctioneerName}>
          {lot.auctioneerName}
        </div>
        <Links url={urlTarget} />
      </div>

      {/* Follow lot button */}
      <Follow idlot={lot.id} size="lg" />
    </div>
  );
};

export default Wide;
