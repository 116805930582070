import React from 'react';
import { useNavigate } from 'react-router';
import { FormattedDate } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getMessage } from '../../../../../redux/selectors/dbEntities';
import { markRead } from '../../../../../redux/actions/account/messages';
import './stylesheet.scss';

const Message = ({ id }) => {
  const dispatch = useDispatch();
  const message = useSelector(state => getMessage(state, { id }));
  const navigate = useNavigate();

  if (!message) {
    return null;
  }

  const displayMessage = () => {
    dispatch(markRead({ id }));
    navigate(id);
  };

  return (
    <div className={`account-messages-list-message ${message.readed ? 'readed' : ''}`} onClick={() => displayMessage()}>
      <div className="date">
        <FormattedDate value={new Date(message.dt)} />
        {message.readed && 1}
        {!message.readed && 0}
      </div>
      <div className="subject">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: message.subject }} />
      </div>
    </div>
  );
};

export default Message;
