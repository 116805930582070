import classNames from 'classnames';
import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import { ANALYTICS_CLICK_OPERATION_SCROLL, ANALYTICS_FROM_ARTIST } from '../../../../../constants/rails';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { getArtist } from '../../../../../redux/slices/artist';
import ScrollCommon from '../../../../common/Scroll';
import { UiContext } from '../UiProvider';
import './stylesheet.scss';

const Scroll = ({ containerClassname, children, renderComplement, aapiBeaconSection }) => {
  const artist = useSelector(getArtist);
  const { hideLeftMenu } = useContext(UiContext);

  const handleScroll = () => {
    aapiBeacon('click', {
      op: ANALYTICS_CLICK_OPERATION_SCROLL,
      from: ANALYTICS_FROM_ARTIST,
      artistId: artist.id,
      section: aapiBeaconSection,
    });
  };

  return (
    <ScrollCommon className={classNames({ 'no-menu': hideLeftMenu })} renderComplement={renderComplement} onScroll={handleScroll} containerClassname={containerClassname}>
      {children}
    </ScrollCommon>
  );
};

export default Scroll;
