import { injectIntl } from 'react-intl';
import i18next from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';
import { getLot } from '../../../../../redux/selectors/dbEntities';
import './stylesheet.scss';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import { ANALYTICS_FROM_AUCTIONEERS_SALE, ANALYTICS_OPERATION_AUCTIONEERS_TO_LOT } from '../../../../../constants/rails';

const Lot = ({ id, push = false, hideNumber = false, idsale, section, intl }) => {
  const lot = useSelector(state => getLot(state, { id }));

  if (!lot) {
    return null;
  }

  return (
    <div className="auctioneers-sale-common-lot">
      {!hideNumber && <div className="number">{lot.number}</div>}
      <div className="img">
        <Link
          to={`/auctioneer/control-center/sale/${idsale}/${lot.id}/${lot.crypto}`}
          onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_AUCTIONEERS_SALE, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_LOT, idlot: lot.id, section })}
        >
          {lot.imageUrl && <img className="shadow" src={lot.imageUrl} alt={lot.title} />}
          {!lot.imageUrl && <i className="fa fa-file-image-o" />}
        </Link>
      </div>
      <div className="title">
        <Link
          to={`/auctioneer/control-center/sale/${idsale}/${lot.id}/${lot.crypto}`}
          onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_AUCTIONEERS_SALE, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_LOT, idlot: lot.id, section })}
        >
          {lot.title}
        </Link>
      </div>
      <div className="artist">{lot.artistOrAssociation}</div>
      <div className="category">{lot.technique}</div>
      {lot?.price && lot.lotstatus !== 4 && <div className="prices">{lot.price}</div>}
      {lot.push && push && <div className="push">{i18next.t('auctioneers.sale.common.lot.m1').replace('%pushCount%', intl.formatNumber(lot.push))}</div>}
    </div>
  );
};

export default injectIntl(Lot);
