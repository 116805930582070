/* eslint-disable global-require */
module.exports = [
  'artprice.landingpages.services.row2.m1',
  'artprice.landingpages.services.row2.m2',
  'artprice.landingpages.services.row2.m3',
  'artprice.landingpages.services.row2.m4',
  'artprice.landingpages.services.row2.m5',
  'artprice.landingpages.services.row2.m6',
  'artprice.landingpages.services.row2.m7',
  'artprice.landingpages.services.row2.m8',
];
