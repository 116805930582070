import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import i18next from 'i18next';
import { set as setUiDictionary } from '../../../../redux/actions/ui/dictionary';
import {
  SHORTCUT_SUMMARY,
  VERTICAL_BAR_CURRENT_ELEMENT_KEY,
  SHORTCUTS_LOTS,
  SHORTCUTS_LOTS_FOLLOW,
  SHORTCUT_MARKETPLACE,
  SHORTCUT_LOTS_FOLLOW_FUTURE,
  SHORTCUTS_MARKETPLACE,
  SHORTCUTS_SETTINGS,
} from '../../../../constants/myartprice';
import './stylesheet.scss';
import { get as getUiDictionary } from '../../../../redux/selectors/ui/dictionary';
import { getFavoriteArtist, getFavoriteLot, getFavoriteClassified, getFavoriteStore } from '../../../../redux/selectors/myartprice/data';

const CounterBadge = ({ children }) => <span className="badge counter-badge"> {children} </span>;

const TabsTitle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentElement = useSelector(state => getUiDictionary(state, { id: VERTICAL_BAR_CURRENT_ELEMENT_KEY }), shallowEqual);
  const favoriteArtist = useSelector(getFavoriteArtist);
  const favoriteClassified = useSelector(getFavoriteClassified);
  const favoriteLot = useSelector(getFavoriteLot);
  const favoriteStore = useSelector(getFavoriteStore);

  const handleSectionClick = element => {
    if (element !== currentElement) {
      dispatch(setUiDictionary('returnToIdlot', null)); // prevent auto scroll to last selected lot

      // eslint-disable-next-line default-case
      switch (element) {
        case SHORTCUT_SUMMARY:
          navigate('/myartprice/artists');
          break;
        case SHORTCUT_LOTS_FOLLOW_FUTURE:
          navigate('/myartprice/lots');
          break;
        case SHORTCUT_MARKETPLACE:
          navigate('/myartprice/store');
          break;
      }
    }
  };

  return (
    <div className="myartprice-common-tab-tile">
      <div className="bottom-line">&nbsp;</div>
      <div className="container">
        <div onClick={() => handleSectionClick(SHORTCUT_SUMMARY)} className={`tab ${SHORTCUTS_LOTS.concat(SHORTCUTS_SETTINGS).includes(currentElement) && 'active'}`}>
          {i18next.t('components.myartprice.common.title.m1')} <CounterBadge>{favoriteArtist.length}</CounterBadge>
        </div>
        <div onClick={() => handleSectionClick(SHORTCUT_LOTS_FOLLOW_FUTURE)} className={`tab ${SHORTCUTS_LOTS_FOLLOW.includes(currentElement) && 'active'}`}>
          {i18next.t('components.myartprice.common.title.m2')} <CounterBadge>{favoriteLot.length + favoriteClassified.length}</CounterBadge>
        </div>
        <div onClick={() => handleSectionClick(SHORTCUT_MARKETPLACE)} className={`tab ${SHORTCUTS_MARKETPLACE.includes(currentElement) && 'active'}`}>
          {i18next.t('components.myartprice.common.title.m3')} <CounterBadge>{favoriteStore.length}</CounterBadge>
        </div>
      </div>
    </div>
  );
};

export default TabsTitle;
