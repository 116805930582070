import React from 'react';
import { Route, Routes } from 'react-router';
import New from './New';
import List from './List';
import Message from './Message';
import Reply from './Message/Reply';
import LeftBar from './LeftBar';
import './stylesheet.scss';

const Messages = ({ title, idmessagetype }) => (
  <div className="account-messages">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <h1>{title}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 content">
          <div className="left-menu">
            <LeftBar />
          </div>
          <div className="body">
            <Routes>
              <Route path="/" element={<h3>.</h3>} />
              <Route path="/new" element={<New defaultValues={{ idmessagetype }} />} />
              <Route path="/inbox" element={<List folder="inbox" />} />
              <Route path="/sent" element={<List folder="sent" />} />
              <Route path="/trash" element={<List folder="trash" />} />
              <Route path="/:folder/:id" element={<Message />} />
              <Route path="/:folder/:id/reply" element={<Reply />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Messages;
