import React from 'react';
import i18next from 'i18next';
import { useNavigate } from 'react-router';
import { noop } from 'lodash';
import { queryStringToObject } from '../../../utils/http';

const ButtonBack = ({ style, onClick = noop }) => {
  const queryStringObject = queryStringToObject(window.location.search);
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (queryStringObject.back) {
      navigate(queryStringObject.back);
    } else {
      navigate(-1);
    }
  };

  return (
    <button type="button" className="back" onClick={handleClick} style={style}>
      {i18next.t('component.myartprice.lots.detail.m1')}
    </button>
  );
};

export default ButtonBack;
