import React from 'react';
import { FormattedNumber } from 'react-intl';
import { LOTS_OFFICIAL_COUNT } from '../../../../../constants/rails';
import './stylesheet.scss';
import A18n from '../../../../common/A18n';

const Stamps = () => (
  <div className="x-landingpage-x-row6-stamps">
    <div className="items">
      <div className="item">
        <div className="number">
          <FormattedNumber value={210000000} />
        </div>
        <p>
          <A18n rsx="artprice.landingpages.services.row6.stamps.m1" />
        </p>
      </div>
      <div className="item">
        <div className="number">
          <FormattedNumber value={LOTS_OFFICIAL_COUNT} />
        </div>
        <p>
          <A18n rsx="artprice.landingpages.services.row6.stamps.m2" />
        </p>
      </div>
      <div className="item">
        <div className="number">
          <FormattedNumber value={36000} />
        </div>
        <p>
          <A18n rsx="artprice.landingpages.services.row6.stamps.m3" />
        </p>
      </div>
    </div>
  </div>
);

export default Stamps;
