import React, { useEffect, useRef } from 'react';
import Consignment from './Consignment';
import Records from './Records';
import Agenda from './Agenda';
import Header from './Header';
import Sales from './Sales';
import './stylesheet.scss';
import Lots from './Lots';
import BackToTopButton from '../../common/BackToTopButton';
import A18n from '../../common/A18n';
import { scrollToElement } from '../../../utils/ui';

export const LANDING_PAGE_LOT = 21;
export const LANDING_PAGE_SALE = 22;
export const LANDING_PAGE_RECORD = 23;
export const LANDING_PAGE_SALE_LOT = 24;
export const LANDING_PAGE_CONSIGNMENT = 25;

const Landingpage = ({ data }) => {
  const refRecords = useRef();
  const refSales = useRef();
  const refLots = useRef();
  const { ails = {}, aillandingpage } = data;
  const sales = ails[LANDING_PAGE_SALE];
  const salelots = ails[LANDING_PAGE_SALE_LOT];
  const lots = ails[LANDING_PAGE_LOT];
  const records = ails[LANDING_PAGE_RECORD];
  const consignment = ails[LANDING_PAGE_CONSIGNMENT]?.[0];

  const refs = {
    [`#${LANDING_PAGE_RECORD}`]: refRecords,
    [`#${LANDING_PAGE_SALE}`]: refRecords,
    [`#${LANDING_PAGE_LOT}`]: refLots,
  };

  useEffect(() => {
    if (Object.keys(refs).includes(window.location.hash)) {
      scrollToElement(refs[window.location.hash]);
    }
  }, [window.location.hash]);

  return (
    <div className="ails-landingpage">
      <div className="logo-sticky">
        <A18n rsx="ails.landingpage.m1" replace={[['%name%', aillandingpage.name]]} />
      </div>
      <Header data={data} />
      <Consignment consignment={consignment} />
      <Sales sales={sales} salelots={salelots} ref={refSales} />
      <Lots lots={lots} ref={refLots} />
      <Records records={records} ref={refRecords} />
      <Agenda data={data} />
      <BackToTopButton />
    </div>
  );
};

export default Landingpage;
