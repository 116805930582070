import i18Next from 'i18next';
import { Link, useParams } from 'react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLists, getList, getLists } from '../../../../redux/slices/account/lotslists';
import { getLot } from '../../../../redux/selectors/dbEntities';
import './stylesheet.scss';

const Breadcrumb = () => {
  const dispatch = useDispatch();
  const { id, idlot } = useParams();
  const lists = useSelector(getLists);
  const list = useSelector(state => getList(state, { id }));
  const lot = useSelector(state => getLot(state, { id: idlot }));

  useEffect(() => {
    if (Object.values(lists).length === 0) {
      dispatch(fetchLists());
    }
  }, []);

  return (
    <div className="lot-list-breadcrumb">
      <div className="breadcrumb-item">
        <a href="/">{i18Next.t('marketplace.sellermenu.m13')}</a>
      </div>
      <div className="breadcrumb-separator" />
      <div className="breadcrumb-item">
        {list && <Link to="/account/lots-lists">{i18Next.t('layouts.header.m46')}</Link>}
        {!list && <span>{i18Next.t('layouts.header.m46')}</span>}
      </div>
      {list && (
        <>
          <div className="breadcrumb-separator" />
          <div className="breadcrumb-item">
            {lot && <Link to={`/account/lots-lists/${list.id}`}>{list.label}</Link>}
            {!lot && <span>{list.label}</span>}
          </div>
        </>
      )}
      {lot && (
        <>
          <div className="breadcrumb-separator" />
          <div className="breadcrumb-item">{lot.title}</div>
        </>
      )}
    </div>
  );
};

export default Breadcrumb;
