import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import Artist from '../../../common/dbEntitiesSmallViews/Artist';
import { unfollowArtist } from '../../../../redux/actions/myartprice/data';
import Modal from '../../../common/ui/Modal';
import './stylesheet.scss';

const Modale = ({ open, idartist, handleOnClose }) => {
  const dispatch = useDispatch();

  if (!open) {
    return null;
  }

  return (
    <Modal
      className="myartprice-common-modale-artist-follow-settings"
      defaultOpen={open}
      onClose={() => handleOnClose()}
      title="Favoris"
      style={{
        maxWidth: '500px',
        maxHeight: '90%',
        marginLeft: '1rem',
        marginRight: '1rem',
      }}
      render={() => (
        <div className="settings-categories">
          <Alert color="info" className="is-favorite" fade={false}>
            <Artist id={idartist} /> a été ajouté a vos favoris
          </Alert>

          <div className="actions">
            <Button
              color="default"
              onClick={() => {
                dispatch(unfollowArtist({ idartists: [idartist] }));
                handleOnClose();
              }}
            >
              Annuler
            </Button>

            <Button
              color="primary"
              onClick={() => {
                handleOnClose();
              }}
            >
              Valider
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default Modale;
