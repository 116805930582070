import React, { useRef, useState } from 'react';
import A18n from '../../../../../common/A18n';
import AutoHeight from '../../../../../common/AutoHeight';
import { useViewport } from '../../../../../../hooks/useViewport';

const Display = ({ terms }) => {
  const [displayPart2, setDisplayPart2] = useState(false);
  const ref = useRef(null);
  const { viewportWidth } = useViewport();

  return (
    <AutoHeight>
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: terms.part1 }} ref={ref} />

      {terms?.part2 && (
        <>
          {/* eslint-disable-next-line react/no-danger */}
          {displayPart2 && <p dangerouslySetInnerHTML={{ __html: terms?.part2 }} />}

          <p>
            {!displayPart2 && (
              <a
                href="#"
                onClick={() => {
                  setDisplayPart2(true);

                  if (viewportWidth <= 768) {
                    window.scrollTo({
                      top: ref.current.getBoundingClientRect().top + window.scrollY - 250,
                      behavior: 'smooth',
                    });
                  }
                }}
              >
                + <A18n rsx="ails.landingpage.header.terms.m1" />
              </a>
            )}
            {displayPart2 && (
              <a href="#" onClick={() => setDisplayPart2(false)}>
                - <A18n rsx="ails.landingpage.header.terms.m2" />
              </a>
            )}
          </p>
        </>
      )}
    </AutoHeight>
  );
};
export default Display;
