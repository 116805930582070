/* eslint-disable global-require */

module.exports = [
  'marketplace.searchbars.classifieds.bar.m6',
  'artists.header_search.m3',
  'artists.header_search.m4',
  'artists.header_search.m5',
  'artists.header_search.m6',
  'artprice.artistsearch.m21',
  'artprice.artistsearch.m21',
  'artprice.ps.artitems.m10',
  'artprice.ps.artitems.m11',
  'artprice.ps.artitems.m12',
  'artprice.ps.artitems.m13',
  'artprice.ps.artitems.m14',
  'artprice.ps.artitems.m17',
  'artprice.ps.artitems.m18',
  'artprice.ps.artitems.m9',
  'constants.marketplace.searchbars.m2',
  'constants.marketplace.searchbars.m3',
  'javascript.constants.m3',
  'javascript.constants.m4',
  'lots.header_search.m14',
  'lots.header_search.m15',
  'lots.header_search.m8',
  'lots.header_search.m9',
  'marketplace.home.index.m7',
  'myartprice.history.html.m1',
  'sales.show.m1',
  'sales.show.m2',
];
