import i18next from 'i18next';
import React, { useState, useEffect, useRef } from 'react';
import ProvidedLotFollowButton from '../../Follow';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import ArtistFollow from '../../../artists/Follow';
import ImagePopup from '../../../ImagePopup';
import LotSimilar from '../../LotSimilar';
import './stylesheet.scss';
import Sale from './Sale';

const Access = ({ lot, currency }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const nextImage = () => {
    const newIndex = currentImage + 1 === lot.imageUrls.length ? 0 : currentImage + 1;
    aapiBeacon('lotimage', {
      idlot: lot.id,
      index: newIndex,
      op: 'detail',
    });
    setCurrentImage(newIndex);
  };

  const previousImage = () => {
    const newIndex = currentImage - 1 < 0 ? lot.imageUrls.length - 1 : currentImage - 1;
    aapiBeacon('lotimage', {
      idlot: lot.id,
      index: newIndex,
      op: 'detail',
    });
    setCurrentImage(newIndex);
  };

  useEffect(() => {
    if (lot.imageUrls[0]) {
      aapiBeacon('lotimage', {
        idlot: lot.id,
        index: 0,
        op: 'detail',
      });
    }
  }, [lot.imageUrls[0]]);

  const [openZoomModal, setOpenZoomModal] = useState(false);

  // compare the size of the div which contains the lot reproduction with the original size of the lot image
  // to determine if we display a link to the image fullscreen modal
  const refReproduction = useRef();
  const [displayZoomModal, setDisplayZoomModal] = useState(false);

  useEffect(() => {
    if (lot.imageUrls.length > 0) {
      const imgDisplayHeight = refReproduction.current.clientHeight;
      const imgDisplayWidth = refReproduction.current.clientWidth;
      setDisplayZoomModal(!lot.imageUrls[currentImage].geometry || lot.imageUrls[currentImage].geometry[0] > imgDisplayWidth || lot.imageUrls[currentImage].geometry[1] > imgDisplayHeight);
    }
  }, [refReproduction]);

  // eslint-disable-next-line
  const hasDetails =
    lot.distinctivesSigns ||
    lot.lotartistassociationcomplement ||
    lot.signaturePosition ||
    lot.literature ||
    lot.description ||
    lot.provenance ||
    lot.exposition ||
    lot.condition ||
    (lot.quantity && lot.quantity > 1) ||
    lot.contractid;

  const hasNFTDetails = lot.tokenid || lot.walletid || lot.minted_on || lot.media_type || lot.url_plateform || lot.transactionid;

  return (
    <div className="lots-lot-access">
      <ImagePopup open={openZoomModal} setOpen={setOpenZoomModal} initalIndex={currentImage} lot={lot} onClose={() => setOpenZoomModal(false)} />
      <div className="container">
        <div className="row">
          <div className="col-xs-12 content">
            <div className="datas">
              <div className="artist">
                <a href={lot.artistUrl}>{lot.artistOrAssociationWithDates}</a>
                <ArtistFollow idartist={lot.artistId} size="xs" />
              </div>
              <h1 className="title">
                <i>{lot.title}</i>
                {lot.creationDate && ` (${lot.creationDate})`} &nbsp; <ProvidedLotFollowButton idlot={lot.id} size="xs" />
              </h1>

              {(lot.number || lot.auctioneerUrl) && (
                <div className="lot-url">
                  {lot.number && i18next.t('artprice.lib.psresultctrl.m18')} {lot.number}
                  {lot.number && lot.numberExtension && `/${lot.numberExtension}`}
                  {lot.auctioneerUrl && (
                    <>
                      {lot.number && ' - '}
                      <a target="_blank" rel="noreferrer" href={lot.auctioneerUrl}>
                        {i18next.t('lots.show.m20')}
                      </a>
                    </>
                  )}
                </div>
              )}

              <div className="description">
                <h2>{lot.category}</h2>
                <h2>{lot.techniqueAndPrecision}</h2>
                {lot.numberAndEdition && <h3> {lot.numberAndEdition} </h3>}
                <h4>{lot.dimension}</h4>
              </div>
              <div className="prices">
                {lot.price && (
                  <div className="price">
                    {i18next.t('artprice.lib.psresultctrl.m6')}: <span dangerouslySetInnerHTML={{ __html: lot.price }} />
                    {lot.lotstatus === 1 && lot.iso3Currency !== currency && <span className="original-currency">({lot.priceOriginal})</span>}
                  </div>
                )}
                {lot.priceWithTaxes && lot.lotstatus !== 7 && (
                  <div className="price-tax">
                    {i18next.t('lots.small.m1')}: <span dangerouslySetInnerHTML={{ __html: lot.priceWithTaxes }} />
                    {lot.lotstatus === 1 && lot.iso3Currency !== currency && <span className="original-currency">({lot.priceWithTaxesOriginal})</span>}
                  </div>
                )}
                {lot.isPast && lot.startingPrice && (
                  <div className="start">
                    {i18next.t('lots.small.m2')}: <span dangerouslySetInnerHTML={{ __html: lot.startingPrice }} />
                    {lot.iso3Currency !== currency && <span className="original-currency">({lot.startingPriceOriginal})</span>}
                  </div>
                )}
                {lot.estimation && (
                  <div className={lot.price ? '' : 'estimation'}>
                    {i18next.t('lots.show.m12')}: {lot.estimation.low && <span dangerouslySetInnerHTML={{ __html: lot.estimation.low }} />}
                    {lot.estimation.high && lot.estimation.low && ' - '}
                    {lot.estimation.high && <span dangerouslySetInnerHTML={{ __html: lot.estimation.high }} />}
                    {lot.iso3Currency !== currency && (
                      <span className="original-currency">
                        ({lot.estimation.low && lot.estimation.lowOriginal}
                        {lot.estimation.high && lot.estimation.low && ' - '}
                        {lot.estimation.high && lot.estimation.highOriginal})
                      </span>
                    )}
                  </div>
                )}
                {!lot.isPast && lot.startingPrice && (
                  <div className="start">
                    {i18next.t('lots.small.m2')}: <span dangerouslySetInnerHTML={{ __html: lot.startingPrice }} />
                    {lot.iso3Currency !== currency && <span className="original-currency">({lot.startingPriceOriginal})</span>}
                  </div>
                )}
              </div>
              <div className="sale">
                <Sale lot={lot} />
              </div>
              {(hasDetails || hasNFTDetails) && (
                <div className="details">
                  <div className="block details-title">{i18next.t('lot.show.access.m1')}</div>
                  <div className="block">
                    {lot.distinctivesSigns}
                    {lot.signaturePosition && lot.distinctivesSigns && ' / '}
                    {lot.signaturePosition}
                  </div>

                  {lot.lotartistassociationcomplement && (
                    <div className="block">
                      <i>{i18next.t('lot.show.access.m4')}</i>: {lot.lotartistassociationcomplement}
                    </div>
                  )}

                  {lot.literature && (
                    <div className="block">
                      <i>{i18next.t('lot.show.access.m6')}</i>: {lot.literature}
                    </div>
                  )}

                  {lot.description && (
                    <div className="block">
                      <i>{i18next.t('artprice.lib.psresultctrl.m23')}</i>: {lot.description}
                    </div>
                  )}

                  {lot.provenance && (
                    <div className="block">
                      <i>{i18next.t('lots.show.m9')}</i>: {lot.provenance}
                    </div>
                  )}
                  {lot.exposition && (
                    <div className="block">
                      <i>{i18next.t('lots.show.m10')}</i>: {lot.exposition}
                    </div>
                  )}
                  {lot.condition && (
                    <div className="block">
                      <i>{i18next.t('lots.show.m11')}</i>: {lot.condition}
                    </div>
                  )}
                  {lot.quantity && lot.quantity > 1 && (
                    <div className="block">
                      <i>{i18next.t('artprice.client.payment.billdetail.m12')}</i>: {lot.quantity}
                    </div>
                  )}
                  {lot.reproductionPage && <div className="block">{i18next.t('lot.show.access.m2').replace('%page%', lot.reproductionPage)}</div>}

                  {/* NFT */}
                  {lot.contractid && (
                    <div className="block">
                      {i18next.t('lots.show.m21')} {lot.contractid}
                    </div>
                  )}
                  {lot.tokenid && (
                    <div className="block">
                      {i18next.t('lots.show.m22')} {lot.tokenid}
                    </div>
                  )}
                  {lot.walletid && (
                    <div className="block">
                      {i18next.t('lots.show.m23')} {lot.walletid}
                    </div>
                  )}
                  {lot.minted_on && (
                    <div className="block">
                      {i18next.t('lots.show.m24')} {lot.minted_on}
                    </div>
                  )}
                  {lot.media_type && (
                    <div className="block">
                      {i18next.t('lots.show.m25')} {lot.media_type}
                    </div>
                  )}
                  {lot.url_plateform && (
                    <div className="block">
                      {i18next.t('lots.show.m26')}{' '}
                      <a href={lot.url_plateform} target="_blank" rel="noreferrer">
                        {lot.url_plateform.toLowerCase()}
                      </a>
                    </div>
                  )}
                  {lot.transactionid && (
                    <div className="block">
                      {i18next.t('lots.show.m27')} {lot.transactionid}
                    </div>
                  )}
                </div>
              )}
            </div>

            {lot.imageUrls.length > 0 && (
              <div className="reproductions" ref={refReproduction}>
                {lot.imageUrls.length > 1 && (
                  <>
                    <div className="img-nav img-nav-previous" onClick={() => previousImage()}>
                      <i className="fa fa-chevron-left" />
                    </div>
                    <div className="img-nav img-nav-next" onClick={() => nextImage()}>
                      <i className="fa fa-chevron-right" />
                    </div>
                  </>
                )}
                <img
                  role="presentation"
                  alt={lot.title}
                  title={lot.title}
                  src={lot.imageUrls[currentImage].url}
                  className={`banner ${displayZoomModal ? 'pointer' : ''}`}
                  onClick={() => displayZoomModal && setOpenZoomModal(true)}
                />
                {displayZoomModal && (
                  <div style={{ marginTop: 15 }}>
                    <a onClick={() => setOpenZoomModal(true)} className="pointer">
                      <i className="fa fa-search-plus marg marg-r-5" aria-hidden="true" /> {i18next.t('artprice.lib.artitem.m9')}
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <LotSimilar idlot={lot.id} idartist={lot.artistId} />
    </div>
  );
};

export default Access;
