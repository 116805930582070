import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SearchContext from '../Context';
import { getAdvancedParams, getBaseParams } from '../../../../redux/slices/search/scoped';
import { EMPTY_OBJECT } from '../../../../constants/utils';

const QuickFilters = ({ className, render, searchScope, onChange }) => {
  const baseParams = useSelector(state => getBaseParams(state, { searchScope }));
  const advancedParams = useSelector(state => getAdvancedParams(state, { searchScope }));
  const [quickParams, setQuickParams] = useState(EMPTY_OBJECT);

  const searchContextValue = useMemo(
    () => ({
      values: { ...baseParams, ...advancedParams, ...quickParams },
      onChange: changes => {
        setQuickParams(prevQuickParams => ({ ...prevQuickParams, ...changes }));
      },
      searchScope,
    }),
    [searchScope, quickParams, setQuickParams],
  );

  useEffect(() => {
    onChange(quickParams);
  }, [quickParams]);

  useEffect(() => {
    setQuickParams(EMPTY_OBJECT);
  }, [advancedParams, setQuickParams]);

  return (
    <div className={`Search-QuickFilters ${className ?? ''}`.trim()}>
      <SearchContext.Provider value={searchContextValue}>{render()}</SearchContext.Provider>
    </div>
  );
};

export default QuickFilters;
