import i18next from 'i18next';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';
import { areBaseFacetsLoading, getAdvancedFacets, getAdvancedParams, getBaseFacets, updateAdvancedParams, updateDraftParams } from '../../../../../../../../redux/slices/search/scoped';
import { getSearchScope } from '../../../../../../../../redux/selectors/myartprice/data';
import { usePaginatedSearch } from '../../../../../../../../hooks/myartprice/usePaginatedSearch';
import { updateUrlQuery } from '../../../../../../../../utils/http';

const getFilteredAdvancedParams = ({ advancedParams, facetName }) =>
  Object.keys(advancedParams)
    .filter(key => facetName !== key)
    .reduce((obj, key) => ({ ...obj, [key]: advancedParams[key] }), {});

const All = ({ facetName, onFilterClick }) => {
  const [selected, setSelected] = useState(false);
  const searchScope = useSelector(getSearchScope);
  const loading = useSelector(state => areBaseFacetsLoading(state, { searchScope }));
  const advancedFacets = useSelector(state => getAdvancedFacets(state, { searchScope }));
  const baseFacets = useSelector(state => getBaseFacets(state, { searchScope }));
  const advancedParams = useSelector(state => getAdvancedParams(state, { searchScope }));
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);

  const { fetch } = usePaginatedSearch({ scope: searchScope });

  useEffect(() => {
    if (!isEmpty(baseFacets)) {
      const isAdvancedParams = !isEmpty(getFilteredAdvancedParams({ advancedParams, facetName }));

      if (isAdvancedParams) {
        if (advancedFacets[facetName]) {
          setCount(Object.values(advancedFacets[facetName]).reduce((value, f) => value + f.count, 0));
        } else {
          setCount(0);
        }
      } else {
        setCount(Object.values(baseFacets[facetName]).reduce((value, f) => value + f.count, 0));
      }
    } else {
      setCount(0);
    }

    setSelected(get(advancedParams, facetName, null) === null);
  }, [facetName, advancedFacets, baseFacets]);

  const handleFacetClick = () => {
    if (loading) return;

    const newParams = getFilteredAdvancedParams({ advancedParams, facetName });

    dispatch(updateDraftParams({ searchScope, params: newParams }));
    dispatch(updateAdvancedParams({ searchScope, params: newParams }));
    fetch({ params: newParams, p: 1 });
    updateUrlQuery(newParams);

    if (onFilterClick) onFilterClick(facetName);
  };

  return (
    <div role="presentation" className={classNames({ facet: true, loading })} onClick={() => handleFacetClick()}>
      <div className="check">
        <i className={classNames({ fa: true, 'fa-dot-circle-o': selected, 'fa-circle-o': !selected, loading })} aria-hidden="true" />
      </div>
      <div className={classNames({ name: true, selected })}>{i18next.t('components.search.common.quickfilter.m1')}</div>
      <div className={classNames({ count: true, selected })}>{count > 0 ? <span>{count}</span> : <span>-</span>}</div>
    </div>
  );
};

export default All;
