import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { getCurrency } from '../../../../redux/selectors/preferences';
import { getCart } from '../../../../redux/slices/payments';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const MIN_PRICE_FOR_OFFLINE_MAYMENT = 36;

const AvailablePayments = ({ hideOfflinePayments }) => {
  const cart = useSelector(getCart);
  const currency = useSelector(getCurrency);
  const [displayOfflinePayments, setDisplayOfflinePayments] = useState(false);

  useEffect(() => {
    if (hideOfflinePayments) {
      setDisplayOfflinePayments(false);
    } else {
      const price = get(cart, `main_products[0].product.prices[${currency}]`);
      const isRecurrentSubscription = get(cart, 'main_products[0].product.isRecurrentSubscription', false);
      if (price < MIN_PRICE_FOR_OFFLINE_MAYMENT || isRecurrentSubscription || cart.loading) {
        setDisplayOfflinePayments(false);
      } else {
        setDisplayOfflinePayments(true);
      }
    }
  }, [cart, hideOfflinePayments]);

  return (
    <div className="payment-common-available-payments">
      <h4>
        <A18n rsx="payments.common.availlablepaymennts.m1" />
      </h4>
      <div className="cards">
        <img src={`${IMAGE_SOURCE}cards/visa.svg`} alt="visa" />
        <img src={`${IMAGE_SOURCE}cards/mastercard.svg`} alt="mastercard" />
        <img src={`${IMAGE_SOURCE}cards/americanexpress.svg`} alt="americanexpress" />
        {cart?.main_products?.[0]?.product?.duration !== 1 && <img src={`${IMAGE_SOURCE}cards/paypal.svg`} alt="paypal" />}
        <img src={`${IMAGE_SOURCE}cards/discover.svg`} alt="discover" />
      </div>
      {displayOfflinePayments && (
        <p>
          <A18n rsx="payments.common.availlablepaymennts.m3" />
        </p>
      )}
    </div>
  );
};
export default AvailablePayments;
