import { shuffle } from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import { ANALYTICS_FROM_ARTIST, ANALYTICS_SECTION_ARTIST_FUTURE_LOTS } from '../../../../constants/rails';
import { getLandingPage } from '../../../../redux/slices/artist';
import '../../../common/stylesheets/buttons.scss';
import { UiContext } from '../common/UiProvider';
import A18n from '../../../common/A18n';
import Scroll from '../common/Scroll';
import './stylesheet.scss';
import { LANDING_PAGE_RECORD } from '../../../ails/Landingpage';

const Landingpage = () => {
  const { landingpage, consignment, records } = useSelector(getLandingPage);
  const { hideLeftMenu } = useContext(UiContext);
  const [displayedRecords, setDisplayedRecords] = useState([]);

  useEffect(() => {
    if (landingpage) {
      setDisplayedRecords(consignment ? [shuffle(records)[0]] : shuffle(records).slice(0, 2));
    }
  }, [consignment, records, landingpage]);

  if (!landingpage) return null;

  return (
    <div className={classNames({ 'artists-artist-landingpage': true, 'block-no-menu': hideLeftMenu, block: !hideLeftMenu })}>
      <div className="container">
        <h2>
          <A18n rsx="ails.landingpage.m1" replace={[['%name%', landingpage.auctioneer]]} />
        </h2>
        <p className="sub-h2">
          <A18n rsx="artists.artist.landingpage.m3" />
        </p>
      </div>
      <Scroll aapiBeaconSection={ANALYTICS_SECTION_ARTIST_FUTURE_LOTS} containerClassname={classNames({ content: true, 'with-consignment': consignment, 'two-records': displayedRecords.length === 2 })}>
        {consignment && (
          <div className="consignment">
            <div className="title">
              <A18n rsx="ails.landingpage.consignment.m4" />
            </div>

            <div className="info">{consignment.title}</div>

            <div className="actions">
              <div className="btn-container">
                <a className="artp-btn" key={consignment.id} href={`/redir?ia=${landingpage.id}&f=${ANALYTICS_FROM_ARTIST}`}>
                  <A18n rsx="artists.artist.landingpage.m1" />
                </a>
              </div>

              <div className="logo">
                <img alt={landingpage.auctioneer} src={landingpage.logo[0]} style={{ width: 200 }} />
              </div>
            </div>
          </div>
        )}

        {!consignment && (
          <div className="sales">
            <div className="logo">
              <img alt={landingpage.auctioneer} src={landingpage.logo[0]} style={{ width: 200 }} />
            </div>
            <div className="actions">
              <a className="artp-btn" key={consignment?.id} href={`/redir?ia=${landingpage?.id}&f=${ANALYTICS_FROM_ARTIST}`}>
                <A18n rsx="artists.artist.landingpage.m2" />
              </a>
            </div>
          </div>
        )}

        {displayedRecords.map(record => (
          <div
            key={`id${landingpage?.id}`}
            className="record"
            onClick={() => {
              window.location = `/redir?ia=${landingpage?.id}&f=${ANALYTICS_FROM_ARTIST}&anchor=${LANDING_PAGE_RECORD}`;
            }}
          >
            <div className="encart">
              <A18n rsx="ails.landingpage.records.record.m1" />
            </div>

            <div className="data">
              <div className="left">
                <p className="price">{record.price}</p>
                <p className="artist">{record.artist_name}</p>
                <p className="title">{record.title}</p>
              </div>
              <div className="right">
                <img alt={record.title} src={record.images.square} />
              </div>
            </div>
          </div>
        ))}
      </Scroll>
    </div>
  );
};

export default Landingpage;
