import React from 'react';
import i18next from 'i18next';
import Item from '../../../Search/Item';
import A18n from '../../../../common/A18n';
import DependsOnSelect from '../../../inputs/Select/DependsOnSelect';

const DEPENDS_ON_CONFIG = {
  facet: 'idcountry',
  placeholder: <A18n rsx="lots.search.form.state.m1" />,
};

const CitySelect = () => (
  <Item label={i18next.t('search.helpers.items.city.select.m1')} name="idcity">
    <DependsOnSelect facet="idcity" placeholder={i18next.t('search.helpers.items.city.select.m2')} dependsOn={DEPENDS_ON_CONFIG} />
  </Item>
);

export default CitySelect;
