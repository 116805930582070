import classNames from 'classnames';
import React, { useRef } from 'react';
import { IMAGE_SOURCE } from '../../../constants/rails';
import { useHorizontalScroll } from '../../../hooks/useHorizontalScroll';
import { useViewport } from '../../../hooks/useViewport';
import './stylesheet.scss';

const Scroll = ({ className = '', containerClassname, children, renderComplement, onScroll, containerStyle = {} }) => {
  const ref = useRef();
  const { leftDisabled, rightDisabled, displayNav, handleScrollClick } = useHorizontalScroll({ ref });
  const { viewportWidth } = useViewport();

  const hScroll = direction => {
    handleScrollClick(direction);

    onScroll?.();
  };

  return (
    <div className={classNames({ scroll: true, [className]: true })}>
      {ref.current?.scrollLeft > 0 && (
        <div className="shadow-left-container" style={{ height: ref.current.clientHeight }}>
          <div className="shadow-left" />
        </div>
      )}
      <div className={classNames({ body: true }, containerClassname)} ref={ref} style={containerStyle}>
        {children}
      </div>

      {(displayNav || renderComplement) && (
        <div className="container footer">
          {displayNav && viewportWidth >= 768 && (
            <div className="scroll-navigation">
              <button type="button" className="artp-btn artp-btn-artist btn-previous" onClick={() => hScroll('left')} disabled={leftDisabled}>
                <img src={`${IMAGE_SOURCE}artist/arrow-left.png`} alt="previous" />
              </button>
              <button type="button" className="artp-btn artp-btn-artist btn-next" onClick={() => hScroll('right')} disabled={rightDisabled}>
                <img src={`${IMAGE_SOURCE}artist/arrow-right.png`} alt="next" />
              </button>
            </div>
          )}
          {renderComplement && <div className="complement"> {renderComplement()}</div>}
        </div>
      )}
    </div>
  );
};

export default Scroll;
