import React from 'react';
import './stylesheet.scss';
import Faq from './Faq';
import A18n from '../../../common/A18n';

const Row7 = () => (
  <div className="x-landingpage-x-row7">
    <div className="container">
      <h2>
        <A18n rsx="artprice.landingpages.services.row7.m1" />
      </h2>
      <h3>
        <A18n rsx="artprice.landingpages.services.row7.m2" />
      </h3>
      <Faq />
    </div>
  </div>
);

export default Row7;
