import React from 'react';
import { FormattedNumber } from 'react-intl';
import { ARTISTS_OFFICIAL_COUNT, IMAGE_SOURCE } from '../../../../../constants/rails';
import './stylesheet.scss';
import A18n from '../../../../common/A18n';

const Stamps = () => (
  <div className="x-landingpage-x-row1-stamps">
    <div className="items">
      <div className="item">
        <div className="title">
          <div className="number">
            <FormattedNumber value={9.3} />
          </div>
          <div className="unit">
            <A18n rsx="artprice.landingpages.services.row1.stamps.m1" />
          </div>
        </div>
        <p>
          <A18n rsx="artprice.landingpages.services.row1.stamps.m2" />
        </p>
      </div>
      <div className="item">
        <div className="title">
          <div className="number">
            <FormattedNumber value={30} />
          </div>
          <div className="unit">
            <A18n rsx="artprice.landingpages.services.row1.stamps.m1" />
          </div>
        </div>
        <p>
          <A18n rsx="artprice.landingpages.services.row1.stamps.m3" />
        </p>
      </div>
      <div className="item">
        <div className="title">
          <div className="number">
            <FormattedNumber value={ARTISTS_OFFICIAL_COUNT} />
          </div>
        </div>
        <p>
          <A18n rsx="artprice.landingpages.services.row1.stamps.m4" />
        </p>
      </div>
      <div className="item">
        <div className="title">
          <div className="ai">
            <img
              src={`${IMAGE_SOURCE}subscription/ai.svg`}
              alt="Intuitive Art Market"
              style={{
                height: 70,
              }}
            />
          </div>
        </div>
        <p>
          <A18n rsx="artprice.landingpages.services.row1.stamps.m5" />
        </p>
      </div>
    </div>
  </div>
);

export default Stamps;
