import React from 'react';
import { string } from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router';
import ReduxProvider from '../providers/ReduxProvider';
import RouterProvider from '../providers/RouterProvider';
import Viewport from '../common/Viewport';
import Sales from './Sales';
import Subscription from './Subscription';
import TopBar from './common/TopBar';
import Sale from './Sale';
import Lot from './Sale/lots/Lot';
import Contact from './Contact';
import Agreement from './Agreement';
import Docs from './Docs';
import { AUCTIONEER_FUTURE_SALES, AUCTIONEER_PAST_SALES } from '../../constants/search/scope';

// https://fr.artprice.ninja/admin/login/200955726
// https://fr.artprice.ninja/admin/login/200148237
// https://fr.artprice.ninja/admin/login/200941840

// Auctioneer non partenaire
// https://fr.artprice.ninja/admin/login/200313636

// NON MAPPE
// https://fr.artprice.ninja/admin/login/1865

// NO FS
// https://fr.artprice.ninja/admin/login/200577416

const Auctioneer = ({ mapped }) => (
  <>
    <TopBar mapped={mapped} />
    <Outlet />
  </>
);

const ProvidedAuctioneer = ({ locale = 'en', mapped }) => (
  <div className="auctioneer-control-center">
    <CookiesProvider>
      <IntlProvider locale={locale}>
        <ReduxProvider>
          <Viewport />
          <RouterProvider
            routes={[
              {
                path: '/auctioneer',
                element: <Auctioneer mapped={mapped} />,
                children: [
                  {
                    path: 'control-center/docs',
                    element: <Docs />,
                  },
                  {
                    path: 'control-center/agreement',
                    element: <Agreement />,
                  },
                  {
                    path: 'control-center/subscription',
                    element: <Subscription />,
                  },
                  {
                    path: 'control-center/future-sales',
                    element: <Sales titleRsx="auctioneers.futuresales.m1" noDataRsx="auctioneers.futuresales.m2" searchScope={AUCTIONEER_FUTURE_SALES} />,
                  },
                  {
                    path: 'control-center/past-sales',
                    element: <Sales titleRsx="auctioneers.pastsales.m1" noDataRsx="auctioneers.pastsales.m2" searchScope={AUCTIONEER_PAST_SALES} />,
                  },
                  {
                    path: 'control-center/sale',
                    element: <Sale />,
                  },
                  {
                    path: 'control-center/sale/:id/:idlot/:crypto',
                    element: <Lot />,
                  },
                  {
                    path: 'control-center/contact/:idsale?',
                    element: <Contact />,
                  },
                ],
              },
            ]}
          />
        </ReduxProvider>
      </IntlProvider>
    </CookiesProvider>
  </div>
);

ProvidedAuctioneer.propTypes = {
  locale: string.isRequired,
};

export default ProvidedAuctioneer;
