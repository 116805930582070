import React from 'react';
import { useParams } from 'react-router';
import Preview from '../../pdf/Preview';
import A18n from '../../../../common/A18n';

const Pdf = () => {
  const { id } = useParams();

  return (
    <div className="container">
      <h1>
        <A18n rsx="components.lotslists.show.m5" />
      </h1>
      <div style={{ height: 800 }}>
        <Preview id={id} />
      </div>
    </div>
  );
};

export default Pdf;
