import i18next from 'i18next';
import { THEME_PREMIUM, THEME_BASICS, THEME_BASICS_STORE, THEME_PROFESSIONAL, THEME_STORE } from '../../../../constants/rails';

export const UNFOLD_KEY = 'SUBSCRIPTION_UNFOLD_KEY';
export const QUANTITY_UP = 'up';
export const QUANTITY_DOWN = 'down';
export const UPGRADE = 'upgrade';
export const NEW = 'new';
export const SUBSCRIPTION = 'subscription';
export const STORE = 'store';
export const PERIODS = [
  { period: 1, label: i18next.t('subscriptions.list.constants.m1') },
  { period: 30, label: i18next.t('subscriptions.list.constants.m2') },
  { period: 365, label: i18next.t('subscriptions.list.constants.m3') },
  { period: 730, label: i18next.t('subscriptions.list.constants.m4') },
];

export const PERIODS_STORE = [
  { period: 30, label: i18next.t('subscriptions.list.constants.m2') },
  { period: 365, label: i18next.t('subscriptions.list.constants.m3') },
];

export const THEMES_ORDER = [THEME_STORE, THEME_BASICS, THEME_BASICS_STORE, THEME_PREMIUM, THEME_PROFESSIONAL];
