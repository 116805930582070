import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart, getCartUnavailable, getParams } from '../../redux/slices/payments';
import RouterProvider from '../providers/RouterProvider';
import Spinner from '../common/spinners/Spinner';
import Informations from './Informations';
import Platforms from './Platforms';
import Proforma from './Proforma';
import Layout from './Layout';
import Ok from './Ok';

const Payment = () => {
  const dispatch = useDispatch();
  const cartParams = useSelector(getParams);
  const cartUnavailable = useSelector(getCartUnavailable);

  useEffect(() => {
    if (!isEmpty(cartParams)) {
      const { type, image, promocode, idphysicproducts, idphysicsale } = cartParams;

      dispatch(
        fetchCart({
          type,
          image,
          promocode,
          idphysicproducts,
          idphysicsale,
        }),
      );
    }
  }, [cartParams]);

  useEffect(() => {
    if (cartUnavailable) {
      window.location = `/subscription${window.location.search}`;
    }
  }, [cartUnavailable]);

  if (cartUnavailable) {
    return (
      <div style={{ marginTop: 60 }}>
        <Spinner faSize={4} />
      </div>
    );
  }

  return (
    <RouterProvider
      routes={[
        {
          path: '/payments',
          element: <Layout />,
          children: [
            {
              path: 'platforms',
              element: <Platforms />,
            },
            {
              path: 'informations',
              element: <Informations />,
            },
            {
              path: 'ok',
              element: <Ok />,
            },
            {
              path: 'proforma/:idphysicsale',
              element: <Proforma />,
            },
          ],
        },
      ]}
    />
  );
};

export default Payment;
