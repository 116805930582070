import React from 'react';
import i18next from 'i18next';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import './stylesheet.scss';
import { ACTIVE_MENU, SENT, TRASH, INBOX, KEY_CURRENT_PAGE } from '../../../../constants/account/messages';
import { get } from '../../../../redux/selectors/ui/dictionary';
import { set } from '../../../../redux/actions/ui/dictionary';
import { fetch } from '../../../../redux/actions/account/messages';

const LeftBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeMenu = useSelector(state => get(state, { id: ACTIVE_MENU }));

  const handleClick = folder => {
    navigate(folder);
    dispatch(fetch({ folder, page: 1 }));
    dispatch(set(KEY_CURRENT_PAGE, 1));

    switch (folder) {
      case 'trash':
        dispatch(set(ACTIVE_MENU, TRASH));
        break;
      case 'sent':
        dispatch(set(ACTIVE_MENU, SENT));
        break;
      default:
        dispatch(set(ACTIVE_MENU, INBOX));
    }
  };

  return (
    <div className="account-left-bar">
      <div className="action">
        <button type="button" className="btn btn-primary" onClick={() => navigate('new')}>
          <i className="fa fa-plus" /> {i18next.t('account.messages.leftbar.m1')}
        </button>
      </div>
      <div className={`section ${activeMenu === INBOX ? 'active' : ''}`} onClick={() => handleClick('inbox')}>
        {i18next.t('account.messages.leftbar.m2')}
      </div>
      <div className={`section ${activeMenu === SENT ? 'active' : ''}`} onClick={() => handleClick('sent')}>
        {i18next.t('account.messages.leftbar.m3')}
      </div>
      <div className={`section ${activeMenu === TRASH ? 'active' : ''}`} onClick={() => handleClick('trash')}>
        {i18next.t('account.messages.leftbar.m4')}
      </div>
    </div>
  );
};

export default LeftBar;
