import i18next from 'i18next';
import React, { useState } from 'react';
import IdentificationsSelect from '../../../search/helpers/items/IdentificationsSelect';
import { cleanPathname, objectToQueryString, queryStringToObject } from '../../../../utils/http';
import TechniqueSelect from '../../../search/helpers/items/TechniqueSelect';
import CategorySelect from '../../../search/helpers/items/CategorySelect';
import ArtistSelect from '../../../search/helpers/items/ArtistSelect';
import CreationYear from '../../../search/helpers/items/CreationYear';
import Reproduction from '../../../search/helpers/items/Reproduction';
import AdvancedButton from '../../../search/helpers/AdvancedButton';
import Dimension from '../../../search/helpers/items/Dimension';
import Preferences from '../../../search/helpers/Preferences';
import { SALE_LOTS } from '../../../../constants/search/sorts';
import Signed from '../../../search/helpers/items/Signed';
import LoadingOverlay from '../../../common/LoadingOverlay';
import Checkbox from '../../../search/inputs/Checkbox';
import Prices from '../../../search/inputs/Prices';
import Sort from '../../../search/helpers/Sort';
import Item from '../../../search/Search/Item';
import Search from '../../../search/Search';
import Params from '../../../search/Params';
import A18n from '../../../common/A18n';
import Count from '../common/Count';
import '../stylesheet.scss';
import FavoriteArtists from '../../../search/helpers/items/FavoriteArtists';
import Keyword from '../../../search/helpers/items/Keyword';

const SearchBar = ({ searchScope, baseParams }) => {
  const urlParams = queryStringToObject(window.location.search);
  const [searching, setSearching] = useState(false);

  const handleChange = ({ params }) => {
    setSearching(true);
    const { sort } = urlParams;
    window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ ...{ sort }, ...params, p: 1 })}`;
  };

  return (
    <>
      <Search
        searchScope={searchScope}
        baseParams={baseParams}
        advancedParams={urlParams}
        onChange={({ searchParams }) => {
          handleChange({ params: searchParams });
          return false; // stop the internal Search processing (we are going to reload the page)
        }}
        horizontalBar={({ drawerId, searchParams }) => (
          <>
            <div className="container-filters lots-horizontal-bar">
              <div className="container">
                <div className="filters-container">
                  <div className="grid-left">
                    <AdvancedButton drawerId={drawerId} />
                  </div>
                  <div className="grid-right">
                    <Sort right options={SALE_LOTS} onChange={sortOption => handleChange({ params: { ...searchParams, sort: sortOption } })} value={urlParams?.sort} />
                    <Preferences withOptionalCurrencies right rsx={{ title: false }} onChange={() => handleChange({ params: searchParams })} hiddenFields={{ freshness: true, artistsViewFormat: true }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <Params searchScope={searchScope} onChange={p => handleChange({ params: p })} />
            </div>
          </>
        )}
        drawer={() => (
          <div>
            <ArtistSelect />
            <FavoriteArtists />
            <IdentificationsSelect />
            <div className="info">{i18next.t('lots.search.filters.others.m2')}</div>
            <hr />
            <Keyword />
            <Item name="limit_keywords_on_title">
              <Checkbox label={<A18n rsx="lots.search.form.keyword.m2" />} />
            </Item>
            <Item name="exact_match">
              <Checkbox label={<A18n rsx="lots.search.form.exactmatch.m1" />} />
            </Item>
            <Reproduction />
            <Signed />
            <hr />
            <CategorySelect />
            <TechniqueSelect />
            <hr />
            <CreationYear />
            <hr />
            <Dimension />
            <hr />
            <Item label={<A18n rsx="artprice.lib.psresultctrl.m7" />} name={{ estimation_from: 'from', estimation_to: 'to', estimation_idcurrency: 'currency' }}>
              <Prices withOptionalCurrencies />
            </Item>
          </div>
        )}
      />
      <LoadingOverlay visible={searching} />
      <Count searchScope={searchScope} />
    </>
  );
};

export default SearchBar;
