import React, { useState } from 'react';
import i18next from 'i18next';
import { SALES_DEFAULT } from '../../../constants/search/sorts';
import { cleanPathname, objectToQueryString, queryStringToObject } from '../../../utils/http';
import CategorySelect from '../../search/helpers/items/CategorySelect';
import CountrySelect from '../../search/helpers/items/CountrySelect';
import ArtistSelect from '../../search/helpers/items/ArtistSelect';
import AdvancedButton from '../../search/helpers/AdvancedButton';
import StateSelect from '../../search/helpers/items/StateSelect';
import CitySelect from '../../search/helpers/items/CitySelect';
import Preferences from '../../search/helpers/Preferences';
import SaleDate from '../../search/helpers/items/SaleDate';
import Count from '../../search/helpers/Count';
import Sort from '../../search/helpers/Sort';
import Search from '../../search/Search';
import Params from '../../search/Params';
import Item from '../../search/Search/Item';
import Select from '../../search/inputs/Select';
import LoadingOverlay from '../../common/LoadingOverlay';
import FavoriteArtists from '../../search/helpers/items/FavoriteArtists';
import IdentificationsSelect from '../../search/helpers/items/IdentificationsSelect';

const SearchBar = ({ searchScope, baseParams }) => {
  const urlParams = queryStringToObject(window.location.search);
  const [searching, setSearching] = useState(false);

  const handleChange = ({ params }) => {
    setSearching(true);

    const { sort } = urlParams;
    window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ ...{ sort }, ...params, p: 1 })}`;
  };

  return (
    <>
      <Search
        searchScope={searchScope}
        baseParams={baseParams}
        advancedParams={urlParams}
        onChange={({ searchParams }) => {
          handleChange({ params: searchParams });
          return false; // stop the internal Search processing (we are going to reload the page)
        }}
        horizontalBar={({ drawerId, searchParams }) => (
          <>
            <div className="container-filters sales-horizontal-bar">
              <div className="container">
                <div className="filters-container">
                  <AdvancedButton drawerId={drawerId} />
                  <div className="filler" />
                  <Sort right options={SALES_DEFAULT} onChange={sortOption => handleChange({ params: { ...searchParams, sort: sortOption } })} value={urlParams?.sort} />
                  <Preferences
                    hiddenFields={{ freshness: true, currencies: true, lotsViewFormat: true, dimension: true, artistsViewFormat: true }}
                    right
                    rsx={{ title: false, perPage: 'sales.search.filters.display.itemsperpage.m1' }}
                    onChange={() => handleChange({ params: searchParams })}
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <Params searchScope={searchScope} onChange={p => handleChange({ params: p })} />
            </div>
          </>
        )}
        drawer={() => (
          <div>
            <ArtistSelect />
            <FavoriteArtists />
            <IdentificationsSelect />
            <div className="info">{i18next.t('lots.search.filters.others.m2')}</div>
            <hr />
            <CategorySelect />
            <hr />
            <CountrySelect />
            <StateSelect />
            <CitySelect />
            <hr />
            <Item label={i18next.t('lots.search.filters.inputs.auctioneer.m1')} name="idauctioneer">
              <Select facet="idauctioneer" placeholder={i18next.t('components.search.inputs.auctioneer_search.m3')} />
            </Item>
            <Item label={i18next.t('components.search.helpers.items.sale_type.m1')} name="idsaletype">
              <Select facet="idsaletype" placeholder={i18next.t('components.search.helpers.items.sale_type.m2')} />
            </Item>
            <SaleDate />
          </div>
        )}
      />
      <LoadingOverlay visible={searching} />
      <Count
        searchScope={searchScope}
        rsx={{
          initial: 'sales.searchbar.count.m4',
          noResult: 'sales.searchbar.count.m1',
          oneResult: 'sales.searchbar.count.m3',
          multipleResults: 'sales.searchbar.count.m2',
        }}
      />
    </>
  );
};

export default SearchBar;
