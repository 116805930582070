import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION, ANALYTICS_FROM_LANDINGPAGES_SERVICES, IMAGE_SOURCE } from '../../../../constants/rails';
import { getCurrency } from '../../../../redux/selectors/preferences';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { PROMO_IDPHYSICPRODUCT, PROMOCODE } from '../constant';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Row5 = () => {
  const intl = useIntl();
  const currency = useSelector(getCurrency);

  return (
    <div className="x-landingpage-x-row5">
      <div className="container">
        <h2>
          <A18n rsx="artprice.landingpages.services.row5.m1" /> <img src={`${IMAGE_SOURCE}subscription/ai.svg`} alt="Intuitive Art Market" />
        </h2>
        <div className="r1">
          <div className="c">
            <h3>
              <A18n rsx="artprice.landingpages.services.row5.m2" />
            </h3>
            <p>
              <A18n rsx="artprice.landingpages.services.row5.m3" />
            </p>

            <img src={`${IMAGE_SOURCE}landing/lp-indexes.png`} alt="logo" />
          </div>
          <div className="c">
            <h3>
              <A18n rsx="artprice.landingpages.services.row5.m4" />
            </h3>
            <p>
              <A18n rsx="artprice.landingpages.services.row5.m5" />
            </p>

            <img src={`${IMAGE_SOURCE}landing/lp-indicator.png`} alt="logo" />
          </div>
        </div>
        <div className="r2">
          <p>
            <a
              onClick={() =>
                aapiBeacon('click', {
                  from: ANALYTICS_FROM_LANDINGPAGES_SERVICES,
                  op: ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION,
                  section: 'row-5',
                })
              }
              href={`/payments/informations?image=true&idphysicproducts[${PROMO_IDPHYSICPRODUCT}]=1&type=new&promocode=${PROMOCODE}`}
              className="artp-btn artp-btn-premium"
            >
              <A18n rsx="artprice.landingpages.services.row5.m6" />
            </a>
          </p>
          <p>
            <A18n
              rsx="artprice.landingpages.services.row8.m5"
              replace={[
                ['%price%', intl.formatNumber(9, { style: 'currency', currency, maximumFractionDigits: 0 })],
                ['%price-per-day%', intl.formatNumber(1, { style: 'currency', currency, maximumFractionDigits: 0 })],
              ]}
            />
          </p>
        </div>
      </div>
    </div>
  );
};
export default Row5;
