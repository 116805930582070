import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDataList,
  fetchLists,
  getList,
  getLists,
  getListDataLoading,
  getListLotsOrder,
  initializeWithKey,
  clear,
  getCurrentSelection,
  addLots,
  removeLot,
  removeLots,
} from '../../../../redux/slices/account/lotslists';
import BackToTopButton from '../../../common/BackToTopButton';
import RenameButton from './RenameButton';
import MoveButton from './MoveButton';
import Lot from '../../../lots/Tile';
import PdfLink from '../pdf/Link';
import Sort from '../Sort';
import { queryStringToObject, updateUrlQuery } from '../../../../utils/http';
import { useViewport } from '../../../../hooks/useViewport';
import { formatFns } from '../../../../utils/dates/format';
import A18n from '../../../common/A18n';
import Preferences from '../../../common/Preferences';
import CopyButton from './CopyButton';
import DeleteButton from './DeleteButton';
import { isSubsetOf } from '../../../../utils/array';

import './stylesheet.scss';
import { getCurrency, getDimension, getItemsPerpage } from '../../../../redux/selectors/preferences';
import Checkbox from '../../../common/ui/Checkbox';
import Pagination from '../../../common/Pagination';

const SORT_OPTIONS = {
  title_asc: i18next.t('artprice.ps.artitems.m11'),
  title_desc: i18next.t('artprice.ps.artitems.m12'),
  datelot_asc: i18next.t('artprice.ps.artitems.m17'),
  datelot_desc: i18next.t('artprice.ps.artitems.m18'),
  estimation_asc: i18next.t('lots.header_search.m8'),
  estimation_desc: i18next.t('lots.header_search.m9'),
  surface_asc: i18next.t('lots.header_search.m14'),
  surface_desc: i18next.t('lots.header_search.m15'),
  datesale_asc: i18next.t('artprice.ps.artitems.m13'),
  datesale_desc: i18next.t('artprice.ps.artitems.m14'),
  number_asc: i18next.t('sales.show.m1'),
  number_desc: i18next.t('sales.show.m2'),
  price_asc: i18next.t('artprice.ps.artitems.m9'),
  price_desc: i18next.t('artprice.ps.artitems.m10'),
};

const PREFERENCES_HIDDEN_FIELDS = { freshness: true, lotsViewFormat: true, artistsViewFormat: true };

/*
 *  J'ai choisi de garder la selection avec redux (plutot que state), au cas ou on repasse sur la pagination (je l'avais fait pour gérer la selection multi page).
 */
const Show = () => {
  const { id } = useParams();
  const queryStringParams = queryStringToObject();
  const dispatch = useDispatch();
  const { viewportWidth } = useViewport();
  const perPage = useSelector(getItemsPerpage);
  const dimensionsUnit = useSelector(getDimension);
  const currency = useSelector(getCurrency);
  const [sort, setSort] = useState(queryStringParams.sort || 'saledate_desc');
  const [page, setPage] = useState(queryStringParams.p);
  const list = useSelector(state => getList(state, { id }));
  const lists = useSelector(getLists);
  const dataListloading = useSelector(getListDataLoading);
  const listLotsOrder = useSelector(getListLotsOrder);
  const currentSelection = useSelector(getCurrentSelection);

  useEffect(() => {
    dispatch(initializeWithKey({ newSelectionKey: `lotslists-${id}` }));
    dispatch(clear());
  }, [id]);

  useEffect(() => {
    if (Object.values(lists).length === 0) {
      dispatch(fetchLists());
    }
  }, []);

  useEffect(() => {
    // Test pour s'eviter un double appel au premier load
    if (sort && page) {
      dispatch(fetchDataList({ id, sort, page, perPage }));
    }
  }, [page]);

  useEffect(() => {
    updateUrlQuery({ p: 1 });
    if (page === 1) {
      dispatch(fetchDataList({ id, sort, page: 1, perPage }));
    } else {
      // on change juste la page ca va retrigger le fetchDataList dans le useEffect de la page
      setPage(1);
    }
  }, [sort, currency, perPage, dimensionsUnit]);

  if (!list) return null;

  const handleSelect = idlotClicked => {
    if (currentSelection?.includes(idlotClicked)) {
      dispatch(removeLot({ id: idlotClicked }));
    } else {
      dispatch(addLots({ ids: [idlotClicked] }));
    }
  };

  const handleSelectAll = () => {
    if (isSubsetOf(listLotsOrder, currentSelection)) {
      dispatch(removeLots({ ids: listLotsOrder }));
    } else {
      dispatch(addLots({ ids: listLotsOrder }));
    }
  };

  const Paginate = () => {
    if (perPage >= list.lotsCount || Number.isNaN(list.lotsCount)) return null;

    return (
      <div className="paginate">
        <Pagination className="pagination" perPage={perPage} pagePadding={1} loading={dataListloading} totalCount={list.lotsCount} activePage={page} onClick={p => setPage(p)} />
      </div>
    );
  };

  return (
    <div className="account-lots-lists-list-show container">
      <div className="header">
        <h1>
          {list.label}
          <RenameButton style={{ marginLeft: 15 }} list={list} />
        </h1>
        <p>
          <A18n rsx="components.lotslists.list.item.m1" replace={[['%date%', formatFns(list.createdAt, 'PPPp')]]} />
          {list.lotsCount >= 1 && (
            <span style={{ marginLeft: 5 }}>({list.lotsCount > 1 ? <A18n rsx="components.lotslists.create.m8" replace={[['%count%', list.lotsCount]]} /> : <A18n rsx="components.lotslists.create.m7" />})</span>
          )}
        </p>
      </div>
      <div className="text-center">
        <PdfLink
          id={list.id}
          sort={sort}
          anchor={i18next.t('components.lotslists.show.m8')}
          style={{
            marginTop: 15,
            marginBottom: 15,
          }}
        />
      </div>
      <div className={`actions ${viewportWidth <= 991 ? 'small-screen first-line' : ''}`.trim()}>
        {viewportWidth > 991 && (
          <div className="select-all">
            <Checkbox checked={isSubsetOf(listLotsOrder, currentSelection)} onChange={handleSelectAll} />
          </div>
        )}
        <CopyButton list={list} idlots={currentSelection} afterCopy={() => dispatch(clear())} />
        <MoveButton list={list} idlots={currentSelection} sort={sort} afterMove={() => dispatch(clear())} />
        {viewportWidth > 991 && <DeleteButton list={list} idlots={currentSelection} />}
        <div style={{ flex: 1 }} />
        <Sort sort={sort} onClick={key => setSort(key)} options={SORT_OPTIONS} />
        <Preferences withOptionalCurrencies right hiddenFields={PREFERENCES_HIDDEN_FIELDS} />
      </div>
      {viewportWidth <= 991 && (
        <div className="actions small-screen second-line">
          <div className="select-all">
            <Checkbox checked={isSubsetOf(listLotsOrder, currentSelection)} onChange={handleSelectAll} />
          </div>
          <A18n rsx="components.lotslists.selectall.m2" />
          <div style={{ flex: 1 }} />
          <DeleteButton list={list} idlots={currentSelection} />
        </div>
      )}
      {dataListloading && (
        <div
          style={{
            marginTop: 30,
            textAlign: 'center',
          }}
        >
          <i className="fa fa-spinner fa-spin fa-4x fa-fw" />
        </div>
      )}
      {!dataListloading &&
        (listLotsOrder || []).map((idlot, idx) => (
          <div key={`lot-${idlot}`} className="lot">
            <div className="col-1">
              <div className="count">{idx + 1 + (page - 1) * perPage}</div>
              <Checkbox checked={currentSelection?.includes(idlot)} onChange={() => handleSelect(idlot)} />
            </div>
            <div className="col-2">
              <Lot format="wide" id={idlot} key={`lot-${idlot}`} position={idx + 1} />
            </div>
          </div>
        ))}
      <Paginate />
      <div className="back-to-top-container">
        <BackToTopButton forceHandleResize={!dataListloading} />
      </div>
    </div>
  );
};

export default Show;
