import i18next from 'i18next';
import ReactSelect from 'react-select';
import { useSelector } from 'react-redux';
import makeAnimated from 'react-select/animated';
import React, { useEffect, useState } from 'react';
import { width as viewportWidthSelector } from '../../../../redux/selectors/ui/viewport';
import customStyles from '../../../search/common/reactSelect/style';
import '../../../search/common/reactSelect/stylesheet.scss';
import './stylesheet.scss';

const options = [
  { value: 1, label: i18next.t('lots.header_search.m12') },
  { value: 5, label: i18next.t('lots.header_search.m13')?.replace('%nb%', '5') },
  { value: 10, label: i18next.t('lots.header_search.m13')?.replace('%nb%', '10') },
];

const Freshness = ({ value, onChange, className }) => {
  const [internalValue, setInternalValue] = useState(parseInt(value, 10));
  const viewportWidth = useSelector(viewportWidthSelector);

  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  const handleClick = freshness => {
    setInternalValue(freshness);
    onChange(freshness);
  };

  return (
    <div className="artp-input-group">
      <span className="artp-input-label">{i18next.t('search.filters.freshness.m1')}</span>
      <ReactSelect
        noOptionsMessage={() => i18next.t('marketplace.searchbars.common.facetsselect.m1')}
        styles={customStyles(viewportWidth)}
        classNamePrefix="react-select"
        className={`search-common-react-select ${className}`}
        components={makeAnimated()}
        defaultValue={options.find(o => o.value === internalValue)}
        onChange={selectedOption => handleClick(selectedOption.value)}
        options={options}
      />
    </div>
  );
};

export default Freshness;
