import React from 'react';
import i18next from 'i18next';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const handleContactClick = () => {
  window.location = '/account/clientmessages?sub_tab=new';
};

const Warning = ({ label, buttonText, buttonAction, title, displayContact = false }) => (
  <div className="subscriptions-list-warnings-warning">
    <h1>{title || <A18n rsx="subscriptions.list.warnings.purchasenotavailable.warning.m1" />}</h1>

    <p>{label}</p>

    <div className="actions">
      {displayContact && (
        <button style={{ marginRight: 5 }} type="button" onClick={handleContactClick}>
          {i18next.t('subscriptions.list.warnings.purchasenotavailable.warning.m2')}
        </button>
      )}
      <button type="button" className="active" onClick={buttonAction}>
        {buttonText}
      </button>
    </div>
  </div>
);

export default Warning;
