import React, { forwardRef } from 'react';
import A18n from '../../../common/A18n';
import Lot from './Lot';
import './stylesheet.scss';

const Lots = forwardRef(({ lots }, ref) => {
  if (!lots) return null;

  return (
    <div className="ails-landingpage-lots bg" ref={ref}>
      <div className="container">
        <h2>
          <A18n rsx="ails.landingpage.lots.m2" />
        </h2>
        <div className="lots-container">
          {lots?.map(lot => (
            <Lot ail={lot} key={lot.id} />
          ))}
        </div>
      </div>
    </div>
  );
});

export default Lots;
