import classNames from 'classnames';
import { Button } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import { ANALYTICS_FROM_AIL_LANDINGPAGE } from '../../../../../constants/rails';
import { useHorizontalScroll } from '../../../../../hooks/useHorizontalScroll';
import { ailIsMultiDates } from '../../../../../utils/ail';
import './stylesheet.scss';
import Lot from './Lot';
import A18n from '../../../../common/A18n';

const Sale = ({ ail, lots }) => {
  const ref = useRef();
  const { leftDisabled, rightDisabled, displayNav, handleScrollClick } = useHorizontalScroll({ ref });
  const [twoPartDate, setTwoPartDate] = useState(false);
  const scroll = direction => {
    handleScrollClick(direction);
  };

  useEffect(() => {
    if (ail) {
      setTwoPartDate(ailIsMultiDates(ail));
    }
  }, [ail]);

  return (
    <div className="ails-landingpage-sales-sale">
      <div className="sale-container">
        <a key={ail.id} href={`/redir?ia=${ail.id}&f=${ANALYTICS_FROM_AIL_LANDINGPAGE}`}>
          <div className="sale">
            <div className="repro">
              <img src={ail.image} alt={ail.title} />
            </div>
            <div className="encart">
              <A18n rsx="ails.landingpage.sales.sale.m3" />{' '}
            </div>
            <div className="legend">
              <div className="info-ail">
                <div className="sale-title">{ail.title}</div>
                <div className="location">
                  {ail.city}, {ail.country}
                </div>
              </div>
              <div className={classNames({ 'two-part': twoPartDate, 'block-date': true })}>
                <div className="block">
                  <span className="day">{ail.dt_start_part1}</span>
                  <span className="other">{ail.dt_start_part2}</span>
                </div>

                {twoPartDate && (
                  <div className="block">
                    <div className="separator" />
                    <div className="day">{ail.dt_end_part1}</div>
                    <div className="other">{ail.dt_end_part2}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </a>
      </div>
      <div ref={ref} className="lots" style={{ gridTemplateColumns: `repeat(${lots?.length}, 260px)` }}>
        {lots?.map(lot => (
          <Lot ail={lot} key={lot.discriminant} />
        ))}
      </div>

      <div className="nav">
        <a key={ail.id} href={`/redir?ia=${ail.id}&f=${ANALYTICS_FROM_AIL_LANDINGPAGE}`}>
          <A18n rsx="ails.landingpage.sales.sale.m4" /> →
        </a>
        {displayNav && (
          <div>
            <Button className="btn-previous btn btn-default" onClick={() => scroll('left')} disabled={leftDisabled}>
              <i className="fa fa-chevron-left" />
              {/* <img src={`${IMAGE_SOURCE}artist/arrow-left.png`} alt="previous" /> */}
            </Button>
            <Button className="btn-next btn btn-default" onClick={() => scroll('right')} disabled={rightDisabled}>
              {/* <img src={`${IMAGE_SOURCE}artist/arrow-right.png`} alt="next" /> */}
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sale;
