import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';

import { getUsersCount } from '../../../redux/slices/userContext/selectors';
import './stylesheet.scss';

const TopMenu = ({ activeTab }) => {
  const userCount = useSelector(getUsersCount);

  return (
    <div className="account-top-menu">
      <div className="container">
        <ul>
          <li className={activeTab === 1 ? 'active' : ''}>
            <a href="/account/show">{i18next.t('customer.top_menu.m4')}</a>
          </li>
          <li className={activeTab === 4 ? 'active' : ''}>
            <a href="/account/bills">{i18next.t('customer._top_menu_level_1.m2')}</a>
          </li>
          <li className={activeTab === 2 ? 'active' : ''}>
            <a href="/account/situation">{i18next.t('customer.top_menu.m1')}</a>
          </li>
          <li className={activeTab === 5 ? 'active' : ''}>
            <Link to="/account/confirm">{[i18next.t('customer.top_menu.m3')]}</Link>
          </li>
          {userCount > 1 && (
            <li className={activeTab === 3 ? 'active' : ''}>
              <a href="/account/multiusers">{i18next.t('artprice.client.default.m2')}</a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TopMenu;
