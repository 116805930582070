import i18next from 'i18next';
import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { getBillAddress, getCart, getCustomerMutationRunning, getParams, getShipAddress, updateBillAddress, updateShipAddress } from '../../../../redux/slices/payments';
import { updateOrCreate as updateOrCreateService } from '../../../../services/wallet/customer';
import { getUserContext } from '../../../../redux/selectors/userContext';
import ActivityDropdown from '../../../common/inputs/ActivityDropdown';
import TitleDropdown from '../../../common/inputs/TitleDropdown';
import { useViewport } from '../../../../hooks/useViewport';
import '../../../common/stylesheets/buttons.scss';
import AddressFields from './AddressFields';
import CartSummary from '../CartSummary';
import A18n from '../../../common/A18n';
import useSchemas from './useSchemas';
import Input from './common/Input';
import Password from './Password';
import Cgv from './Cgv';

function onFormError(e) {
  Sentry.captureMessage('[form_confirm_identity] Validation failed', {
    extra: {
      errors: e,
    },
  });
}

const Form = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const billaddress = useSelector(getBillAddress);
  const shipaddress = useSelector(getShipAddress);
  const { getSchemaForExistingCustomer, getSchemaForNewCustomer } = useSchemas();
  const { initialEmail } = useSelector(getParams);
  const { idcustomer, email, firstname, lastname, idtitle, idactivity } = useSelector(getUserContext);
  const { status, needs_ship_address: needsShipAddress } = useSelector(getCart);
  const { viewportWidth } = useViewport();
  const [waitingForNextPage, setWaitingForNextPage] = useState(false);
  const loading = useSelector(getCustomerMutationRunning);

  const defaultValues = {
    billaddress,
    shipaddress,
    email: email || initialEmail,
    pwd: idcustomer ? '--------------' : null,
    firstname,
    lastname,
    idtitle,
    idactivity,
  };

  const methods = useForm({ resolver: yupResolver(idcustomer ? getSchemaForExistingCustomer() : getSchemaForNewCustomer({ idcustomer })), defaultValues });

  const { setError, setValue, control, handleSubmit, watch } = methods;

  useEffect(() => {
    if (!billaddress.idtitle) setValue('billaddress.idtitle', idtitle);
    if (!billaddress.lastname) setValue('billaddress.lastname', firstname);
    if (!billaddress.firstname) setValue('billaddress.firstname', lastname);

    if (needsShipAddress) {
      if (!shipaddress.idtitle) setValue('billaddress.idtitle', idtitle);
      if (!shipaddress.lastname) setValue('billaddress.lastname', firstname);
      if (!shipaddress.firstname) setValue('billaddress.firstname', lastname);
    }
  }, [idtitle, firstname, lastname, needsShipAddress, shipaddress, billaddress]);

  const onSubmit = async values => {
    setWaitingForNextPage(true);
    if (idcustomer) {
      const internalBillAddress = { ...values.billaddress };
      await dispatch(updateBillAddress(internalBillAddress));

      if (needsShipAddress) {
        const internalShipAddress = { ...values.shipaddress };
        await dispatch(updateShipAddress(internalShipAddress));
      }

      onSuccess();
    } else if (!loading) {
      try {
        const data = await updateOrCreateService({
          ...values,
          login: values.email,
          shipaddress: needsShipAddress ? values.shipaddress : null,
        });

        if (!isEmpty(data?.errors)) {
          if (data?.errors?.login) setError('email', { type: 'custom', message: data.errors.login }, { shouldFocus: true });
          if (data?.errors?.pwd) setError('pwd', { type: 'custom', message: data.errors.pwd }, { shouldFocus: true });
        } else {
          onSuccess();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setWaitingForNextPage(false);
      }
    }
  };

  // UseEffect to change state depending on form values
  useEffect(() => {
    if (!idcustomer) {
      const subscription = watch((formValues, { name }) => {
        if (name === `idtitle`) {
          setValue('billaddress.idtitle', formValues.idtitle);
          if (needsShipAddress) setValue('shipaddress.idtitle', formValues.idtitle);
        }
        if (name === `firstname`) {
          setValue('billaddress.firstname', formValues.firstname);
          if (needsShipAddress) setValue('shipaddress.firstname', formValues.firstname);
        }
        if (name === `lastname`) {
          setValue('billaddress.lastname', formValues.lastname);
          if (needsShipAddress) setValue('shipaddress.lastname', formValues.lastname);
        }
      });

      return () => subscription.unsubscribe();
    }
  }, [watch, idcustomer]);

  return (
    <div className="common-stylesheets-forms">
      <FormProvider {...methods} defaultValues={defaultValues}>
        <form onSubmit={handleSubmit(onSubmit, onFormError)}>
          {!idcustomer && (
            <>
              <h3>
                <A18n rsx="payments.informations.form.m1" />
              </h3>
              <div className="group">
                <Input locked={idcustomer} name="email" placeholder={i18next.t('payments.informations.form.m2')} />
              </div>
              <Password />
              <h3>
                <A18n rsx="payments.informations.form.m8" />
              </h3>
              <div className="group">
                <Input name="idtitle">
                  <TitleDropdown control={control} name="idtitle" />
                </Input>
                <Input name="firstname" placeholder={i18next.t('payments.informations.form.m4')} />
                <Input name="lastname" placeholder={i18next.t('payments.informations.form.m5')} />
              </div>
              <div className="group">
                <Input name="idactivity">
                  <ActivityDropdown isSearchable control={control} name="idactivity" placeholder={i18next.t('payments.informations.form.m6')} />
                </Input>
              </div>
            </>
          )}
          <AddressFields type="billaddress" />
          {needsShipAddress && <AddressFields type="shipaddress" />}
          {viewportWidth && viewportWidth < 768 && <CartSummary withPromocode />}
          <Cgv />
          <div className="group">
            <div className="cell">
              <button type="submit" className="artp-btn artp-btn-purple" id="sln_submit" disabled={status !== 'ok' || waitingForNextPage}>
                <A18n rsx="payments.informations.form.m7" />
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Form;
