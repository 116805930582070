import React from 'react';
import { FormattedNumber } from 'react-intl';
import { ARTISTS_OFFICIAL_COUNT, IMAGE_SOURCE } from '../../../../constants/rails';
import './stylesheet.scss';
import A18n from '../../../common/A18n';

const Header = () => (
  <div className="x-landingpage-header">
    <div className="container">
      <div className="r">
        <div className=" logo">
          <img src={`${IMAGE_SOURCE}logo.svg`} alt="logo" />
        </div>
        <div className="baseline">
          <A18n rsx="artprice.landingpages.services.header.m1" replace={[['%count%', <FormattedNumber key={`header-${ARTISTS_OFFICIAL_COUNT}`} value={ARTISTS_OFFICIAL_COUNT} />]]} />
        </div>
      </div>
    </div>
  </div>
);

export default Header;
