import React from 'react';
import { IMAGE_SOURCE } from '../../../../../constants/rails';
import './stylesheet.scss';
import A18n from '../../../../common/A18n';

const Stamps = () => (
  <div className="x-landingpage-x-row3-stamps">
    <div className="items">
      <div className="item">
        <div className="check">
          <img src={`${IMAGE_SOURCE}landing/lp-check-premium.svg`} alt="Intuitive Art Market" />
        </div>
        <p>
          <A18n rsx="artprice.landingpages.services.row3.stamps.m1" />
        </p>
      </div>
      <div className="item">
        <div className="check">
          <img src={`${IMAGE_SOURCE}landing/lp-check-premium.svg`} alt="Intuitive Art Market" />
        </div>
        <p>
          <A18n rsx="artprice.landingpages.services.row3.stamps.m2" />
        </p>
      </div>
      <div className="item">
        <div className="check">
          <img src={`${IMAGE_SOURCE}landing/lp-check-premium.svg`} alt="Intuitive Art Market" />
        </div>
        <p>
          <A18n rsx="artprice.landingpages.services.row3.stamps.m3" />
        </p>
      </div>
      <div className="item">
        <div className="check">
          <img src={`${IMAGE_SOURCE}landing/lp-check-premium.svg`} alt="Intuitive Art Market" />
        </div>
        <p>
          <A18n rsx="artprice.landingpages.services.row3.stamps.m4" />
        </p>
      </div>
    </div>
  </div>
);

export default Stamps;
