import React from 'react';
import Contact from './Contact';
import Terms from './Terms';

const Header = ({ data }) => {
  const { logo, image, aillandingpage, aillandingpagedetails } = data;

  return (
    <div className="container">
      <div className="ails-landingpage-header">
        <div className="c1">
          <img src={image} alt={aillandingpage.name} />
        </div>

        <div className="logo">
          <img src={logo} alt="logo" />
        </div>

        <Contact aillandingpage={aillandingpage} logo={logo} />

        <div className="c2">
          <Terms aillandingpagedetails={aillandingpagedetails} />
        </div>
      </div>
    </div>
  );
};

export default Header;
