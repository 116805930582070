import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SHORTCUT_LOTS_AUCTIONED_OFF, VERTICAL_BAR_CURRENT_ELEMENT_KEY } from '../../../../../constants/myartprice';
import { ARTIST_LOTS_FUTURE as SEARCH_SCOPE_ARTIST_LOTS_FUTURE, MYARTPRICE_LOTS_FUTURE_ALL, MYARTPRICE_LOTS_FUTURE_NEW } from '../../../../../constants/search/scope';
import { MYARTPRICE_LOTS_FUTURE, MYARTPRICE_LOTS_AUCTIONED_OFF } from '../../../../../constants/search/sorts';
import { getAdvancedParams } from '../../../../../redux/slices/search/scoped';
import { getCurrentPage, getSearchScope } from '../../../../../redux/selectors/myartprice/data';
import SaleIdauctioneerSelect from '../../../../search/helpers/items/SaleIdauctioneerSelect';
import IdentificationsSelect from '../../../../search/helpers/items/IdentificationsSelect';
import { get as getUiDictionary } from '../../../../../redux/selectors/ui/dictionary';
import { queryStringToObject, updateUrlQuery } from '../../../../../utils/http';
import TechniqueSelect from '../../../../search/helpers/items/TechniqueSelect';
import CategorySelect from '../../../../search/helpers/items/CategorySelect';
import SaletypeSelect from '../../../../search/helpers/items/SaletypeSelect';
import AddToListButton from '../../../../account/Lotslists/AddToListButton';
import CountrySelect from '../../../../search/helpers/items/CountrySelect';
import CreationYear from '../../../../search/helpers/items/CreationYear';
import Reproduction from '../../../../search/helpers/items/Reproduction';
import AdvancedButton from '../../../../search/helpers/AdvancedButton';
import StateSelect from '../../../../search/helpers/items/StateSelect';
import CitySelect from '../../../../search/helpers/items/CitySelect';
import Dimension from '../../../../search/helpers/items/Dimension';
import Indicator from '../../../../search/helpers/items/Indicator';
import Preferences from '../../../../search/helpers/Preferences';
import SaleDate from '../../../../search/helpers/items/SaleDate';
import Keyword from '../../../../search/helpers/items/Keyword';
import Signed from '../../../../search/helpers/items/Signed';
import Price from '../../../../search/helpers/items/Price';
import Checkbox from '../../../../search/inputs/Checkbox';
import Sold from '../../../../search/helpers/items/Sold';
import Prices from '../../../../search/inputs/Prices';
import Count from '../../../../search/helpers/Count';
import Sort from '../../../../search/helpers/Sort';
import Item from '../../../../search/Search/Item';
import Search from '../../../../search/Search';
import Params from '../../../../search/Params';
import A18n from '../../../../common/A18n';
import '../stylesheet.scss';
import { SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_FUTURE, SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_MARKETPLACE, SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_PAST } from '../../../../../constants/rails';
import { on as openDrawer } from '../../../../../redux/actions/ui/switches';
import { useViewport } from '../../../../../hooks/useViewport';

const SearchBar = ({ baseParams, lotIds, newSelectionKey, fetch }) => {
  const urlParams = queryStringToObject(window.location.search);
  const searchScope = useSelector(getSearchScope);
  const dispatch = useDispatch();
  const page = useSelector(getCurrentPage);
  const currentElement = useSelector(state => getUiDictionary(state, { id: VERTICAL_BAR_CURRENT_ELEMENT_KEY }), shallowEqual);
  const advancedParams = useSelector(state => getAdvancedParams(state, { searchScope }));
  const [hideFreshness, setHideFreshness] = useState(false);
  const { viewportWidth } = useViewport();

  useEffect(() => {
    setHideFreshness([SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_FUTURE, SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_PAST, SEARCH_SCOPE_MYARTPRICE_LOTS_FOLLOW_MARKETPLACE].includes(searchScope));
  }, [searchScope]);

  if (!searchScope) return null;

  const handleChange = params => {
    updateUrlQuery(params);
    fetch({ ...params, p: 1 });
  };

  const handlePreferencesChange = ({ freshness, itemsPerPage } = {}) => {
    const params = { ...advancedParams, p: page };
    if (itemsPerPage || freshness) {
      params.p = 1;
      updateUrlQuery(params);
    }

    fetch(params);
  };

  const isFutureSearchScope = [SEARCH_SCOPE_ARTIST_LOTS_FUTURE, MYARTPRICE_LOTS_FUTURE_NEW, MYARTPRICE_LOTS_FUTURE_ALL].includes(searchScope);
  return (
    <div className="myartprice-lots-searchbar">
      <Search
        searchScope={searchScope}
        baseParams={baseParams}
        advancedParams={urlParams}
        onChange={({ searchParams }) => handleChange(searchParams)}
        horizontalBar={({ drawerId, searchParams }) => (
          <>
            <div className="container-filters lots-horizontal-bar">
              <div className="filters-container">
                {viewportWidth && viewportWidth < 992 && (
                  <button type="button" onClick={() => dispatch(openDrawer('myartprice-vertical-bar'))} className="search-helpers-advanced-button">
                    <i className="fa fa-filter" /> {viewportWidth >= 768 && i18next.t('component.myartprice.lot.m9')}
                  </button>
                )}

                <AdvancedButton drawerId={drawerId} />
                {currentElement === SHORTCUT_LOTS_AUCTIONED_OFF && <AddToListButton ids={lotIds} newSelectionKey={newSelectionKey} className="grid-center" />}
                <div className="filler" />
                <Sort
                  options={isFutureSearchScope ? MYARTPRICE_LOTS_FUTURE : MYARTPRICE_LOTS_AUCTIONED_OFF}
                  onChange={sortOption => handleChange({ ...searchParams, sort: sortOption })}
                  value={urlParams?.sort || 'datesale_desc'}
                  right
                />

                <Preferences
                  onChange={handlePreferencesChange}
                  hiddenFields={{ lotsViewFormat: true, dimension: true, artistsViewFormat: true, freshness: hideFreshness }}
                  right
                  withOptionalCurrencies
                  rsx={{ title: false }}
                />
              </div>
            </div>
            <Params searchScope={searchScope} onChange={handleChange} />
          </>
        )}
        drawer={() => (
          <div>
            <IdentificationsSelect />
            <div className="info">{i18next.t('lots.search.filters.others.m2')}</div>
            <hr />
            <Keyword />
            <Item name="limit_keywords_on_title">
              <Checkbox label={<A18n rsx="lots.search.form.keyword.m2" />} />
            </Item>
            <Item name="exact_match">
              <Checkbox label={<A18n rsx="lots.search.form.exactmatch.m1" />} />
            </Item>
            <Reproduction />
            <Signed />
            <hr />
            {!baseParams?.idcategory && <CategorySelect />}
            <TechniqueSelect />
            <hr />
            <CreationYear />
            <hr />
            <Dimension />
            <hr />
            <Item label={<A18n rsx="artprice.lib.psresultctrl.m7" />} name={{ estimation_from: 'from', estimation_to: 'to', estimation_idcurrency: 'currency' }}>
              <Prices withOptionalCurrencies />
            </Item>
            {searchScope === MYARTPRICE_LOTS_AUCTIONED_OFF && (
              <>
                <Price withOptionalCurrencies />
                <Sold />
                <Indicator />
              </>
            )}
            <hr />
            <SaleIdauctioneerSelect />
            <SaletypeSelect />
            <SaleDate />
            <hr />
            <CountrySelect />
            <StateSelect />
            <CitySelect />
          </div>
        )}
      />
      {/* <LoadingOverlay visible={searching} /> */}
      <Count searchScope={searchScope} />
    </div>
  );
};

export default SearchBar;
