import React, { useMemo, useState } from 'react';
import i18next from 'i18next';
import { string } from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import { IntlProvider, injectIntl } from 'react-intl';
import ReduxProvider from '../providers/ReduxProvider';
import { queryStringToObject } from '../../utils/http';
import Search from '../search/Search';
import { TOTAL_ACCESS, TOTAL_ACCESS_ARTISTS, TOTAL_ACCESS_AUCTIONEERS, TOTAL_ACCESS_CHRONO, TOTAL_ACCESS_GEO, TOTAL_ACCESS_LOTS } from '../../constants/search/scope';
import List from './List';
import Graphs from './Graphs';
import Item from '../search/Search/Item';
import AdvancedButton from '../search/helpers/AdvancedButton';
import Preferences from '../search/helpers/Preferences';
import Params from '../search/Params';

import CategorySelect from '../search/helpers/items/CategorySelect';
import TechniqueSelect from '../search/helpers/items/TechniqueSelect';
import Price from '../search/helpers/items/Price';
import CountrySelect from '../search/helpers/items/CountrySelect';
import SaleDate from '../search/helpers/items/SaleDate';
import ArtistSearch from '../search/inputs/ArtistSearch';
import ArtistGenderSelect from '../search/helpers/items/ArtistGenderSelect';
import ArtistCountrySelect from '../search/helpers/items/ArtistCountrySelect';
import ArtistBirthYear from '../search/helpers/items/ArtistBirthYear';
import ArtistDeathYear from '../search/helpers/items/ArtistDeathYear';
import CreationYear from '../search/helpers/items/CreationYear';
import Dimension from '../search/helpers/items/Dimension';
import SaletypeSelect from '../search/helpers/items/SaletypeSelect';
import AuctioneerSearch from '../search/inputs/AuctioneerSearch';
import QuickList from '../search/inputs/QuickList';
import UndeterminedSwitch from '../search/inputs/UndeterminedSwitch';
import Years from '../search/inputs/Years';
import { useViewport } from '../../hooks/useViewport';

const TotalAccess = () => {
  const { viewportWidth } = useViewport();
  const [graphScope, setGraphScope] = useState(TOTAL_ACCESS_CHRONO);
  const [listScope, setListScope] = useState(TOTAL_ACCESS_LOTS);
  const [params, setParams] = useState(() => queryStringToObject(window.location.search));

  const quickFilters = useMemo(() => {
    if (!viewportWidth || viewportWidth < 1200) {
      return undefined;
    }
    return function () {
      return (
        <>
          <Item label={i18next.t('indexes._advanced.m6').toUpperCase()} name="idcategory">
            <QuickList facet="idcategory" />
          </Item>
          <Item label={i18next.t('components.search.helpers.items.artist_birth_year.m1').toUpperCase()} name={{ birth_from: 'from', birth_to: 'to' }}>
            <Years />
          </Item>
          <Item label={i18next.t('components.search.helpers.items.artist_country.m1').toUpperCase()} name="artist_idcountry">
            <QuickList facet="artist_idcountry" filterAbove={10} />
          </Item>
        </>
      );
    };
  }, [viewportWidth]);

  return (
    <div className="TotalAccess">
      <Search
        searchScope={TOTAL_ACCESS}
        autoUpdateUrl
        advancedParams={params}
        onChange={({ searchParams: newParams }) => setParams(newParams)}
        horizontalBar={({ drawerId }) => (
          <>
            <div className="total-access-horizontal-bar">
              <div className="container">
                <div className="filters-container">
                  <AdvancedButton drawerId={drawerId} />
                  <div className="flex-filler" />
                  <div style={{ display: 'flex' }}>
                    <button type="button" className="btn" onClick={() => setGraphScope(TOTAL_ACCESS_CHRONO)} disabled={graphScope === TOTAL_ACCESS_CHRONO}>
                      <i className="fa fa-2x fa-bar-chart" />
                    </button>
                    <button type="button" className="btn" onClick={() => setGraphScope(TOTAL_ACCESS_GEO)} disabled={graphScope === TOTAL_ACCESS_GEO}>
                      <i className="fa fa-2x fa-globe" />
                    </button>
                  </div>
                  <div className="flex-filler" />
                  <Preferences hiddenFields={{ freshness: true, artistsViewFormat: true }} />
                </div>
              </div>
            </div>
            <div className="container">
              <Params searchScope={TOTAL_ACCESS} onChange={setParams} />
            </div>
          </>
        )}
        drawer={() => (
          <>
            <section>
              <h3>{i18next.t('components.total_access.m3')}</h3>
              <CategorySelect />
              <TechniqueSelect />
              <CreationYear />
              <Price />
              <Dimension />
              <Item name="with_image" label={i18next.t('components.total_access.m4')}>
                <UndeterminedSwitch />
              </Item>
            </section>
            <section>
              <h3>{i18next.t('sales.show.m4')}</h3>
              <CountrySelect />
              <SaleDate />
              <SaletypeSelect />
              <Item label="Auctioneer" name="sale_idauctioneer">
                <AuctioneerSearch />
              </Item>
            </section>
            <section>
              <h3>{i18next.t('classifieds.classified_header.artist')}</h3>
              <Item label={i18next.t('classifieds.classified_header.artist')} name="idartist">
                <ArtistSearch />
              </Item>
              <ArtistCountrySelect />
              <ArtistGenderSelect />
              <ArtistBirthYear />
              <ArtistDeathYear />
            </section>
          </>
        )}
        quickFilters={quickFilters}
      >
        <Graphs searchScope={graphScope} params={params} onGraphClick={paramsToMerge => setParams(prevParams => ({ ...prevParams, ...paramsToMerge }))} />
        <div className="row view-by">
          {i18next.t('components.total_access.m1')}
          <span className={listScope === TOTAL_ACCESS_LOTS ? 'active' : ''} onClick={() => setListScope(TOTAL_ACCESS_LOTS)}>
            {i18next.t('lots.header_search.m17')} {listScope === TOTAL_ACCESS_LOTS && <i className="fa fa-caret-down" />}
          </span>
          |
          <span className={listScope === TOTAL_ACCESS_ARTISTS ? 'active' : ''} onClick={() => setListScope(TOTAL_ACCESS_ARTISTS)}>
            {i18next.t('lots.search.filters.artists.m1')} {listScope === TOTAL_ACCESS_ARTISTS && <i className="fa fa-caret-down" />}
          </span>
          |
          <span className={listScope === TOTAL_ACCESS_AUCTIONEERS ? 'active' : ''} onClick={() => setListScope(TOTAL_ACCESS_AUCTIONEERS)}>
            {i18next.t('components.total_access.m2')} {listScope === TOTAL_ACCESS_AUCTIONEERS && <i className="fa fa-caret-down" />}
          </span>
        </div>
        <List searchScope={listScope} params={params} onPageClick={page => setParams(prevParams => ({ ...prevParams, p: page }))} />
      </Search>
    </div>
  );
};

const IntlTotalAccess = injectIntl(TotalAccess);

// Provider
const ProvidedTotalAccess = ({ locale = 'en' }) => (
  <CookiesProvider>
    <IntlProvider locale={locale}>
      <ReduxProvider>
        <IntlTotalAccess locale={locale} />
      </ReduxProvider>
    </IntlProvider>
  </CookiesProvider>
);

ProvidedTotalAccess.propTypes = {
  locale: string.isRequired,
};

export default ProvidedTotalAccess;
