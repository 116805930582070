import React from 'react';
import { FormattedNumber } from 'react-intl';
import { ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION, ANALYTICS_FROM_LANDINGPAGES_SERVICES, ARTISTS_OFFICIAL_COUNT, IMAGE_SOURCE } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { PROMO_IDPHYSICPRODUCT, PROMOCODE } from '../constant';
import A18n from '../../../common/A18n';
import Stamps from './Stamps';
import './stylesheet.scss';

const Row1 = () => (
  <div className="x-landingpage-x-row1">
    <div className="container">
      <div className="r">
        <div className="c1">
          <div className="c11">
            <A18n rsx="artprice.landingpages.services.row1.m1" />
          </div>
          <div className="c12">
            <p>
              <A18n rsx="artprice.landingpages.services.row1.m2" />
            </p>
            <p>
              <A18n rsx="artprice.landingpages.services.row1.m3" replace={[['%count%', <FormattedNumber key={`row1-${ARTISTS_OFFICIAL_COUNT}`} value={ARTISTS_OFFICIAL_COUNT} />]]} />
            </p>
          </div>
          <div className="c13">
            <a
              onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_LANDINGPAGES_SERVICES, op: ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION, section: 'row-1' })}
              href={`/payments/informations?image=true&idphysicproducts[${PROMO_IDPHYSICPRODUCT}]=1&type=new&promocode=${PROMOCODE}`}
              className="artp-btn artp-btn-premium"
            >
              <A18n rsx="artprice.landingpages.services.row1.m4" />
            </a>
          </div>
        </div>
        <div className="c2">
          <img src={`${IMAGE_SOURCE}landing/lp-paint.png`} alt="logo" />
        </div>
        <Stamps />
      </div>
    </div>
  </div>
);

export default Row1;
