import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { get } from 'lodash';
import { updateNavigationStep } from '../../../redux/slices/payments';
import { getUserContext } from '../../../redux/selectors/userContext';
import EmailStatus from '../../account/EmailStatus';
import { status } from '../../../services/payment';
import useQuery from '../hooks/useQueryParams';
import A18n from '../../common/A18n';
import Links from './Links';
import './stylesheet.scss';
import Help from '../common/Help';

const FALLBACK_DATA = {
  loading: false,
  status: 'ko',
  validatedAt: false,
  online: true,
  emailValidationNeeded: false,
};

const Ok = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [data, setData] = useState({ loading: true });
  const { logged, idcustomer } = useSelector(getUserContext);

  // Set step for the progressbar
  useEffect(() => {
    dispatch(updateNavigationStep(3));
  }, []);

  useEffect(() => {
    const idpayment = query?.get('idpayment');
    const idphysicsale = query?.get('idphysicsale');
    let intervalId;

    if (idpayment || idphysicsale) {
      let timesRun = 0;
      intervalId = setInterval(() => {
        status({ idpayment, idphysicsale })
          .then(response => {
            setData({
              loading: false,
              ...response.data,
            });

            timesRun += 1;
            if (timesRun > 20 || response.data.validatedAt) {
              clearInterval(intervalId);
              setData({
                loading: false,
                pollingStopped: true,
                ...response.data,
              });
            }
          })
          .catch(e => {
            console.error(e);
            // if the status is explicitly KO : we display the error message
            if (get(e, 'response.data.status') === 'ko') {
              clearInterval(intervalId);
              // fallback data to display "error" page
              setData(FALLBACK_DATA);
            } else {
              // else we do nothing, it could have been a temporary network error for example, or the client lost his sessions
              // the hourglass continues spinning
              Sentry.captureMessage('[payment_ok] status failed', {
                extra: {
                  errors: e,
                },
              });
            }
          });
      }, 2000);
    } else {
      // fallback data to display "error" page
      setData(FALLBACK_DATA);
    }

    return () => clearInterval(intervalId);
  }, [query]);

  if (data.loading) {
    return (
      <div className="payments-ok">
        <div className="container">
          <div className="block">
            <h1 className="payment-title">
              <A18n rsx="payments.ok.m11" />
            </h1>
            <div className="content">
              <i className="loading fa fa-spinner fa-spin payment-icon" />
              <p>
                <A18n rsx="payments.ok.m12" />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (data.status !== 'ok') {
    return (
      <div className="payments-ok">
        <div className="container">
          <div className="block">
            <h1 className="payment-title">
              <A18n rsx="payments.ok.m13" />
            </h1>
            <div className="content">
              <i className="loading fa fa-exclamation-triangle payment-icon" />
              <p>
                <A18n rsx="payments.ok.m14" />
              </p>
            </div>
          </div>
          <div className="infos">
            <Help />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="payments-ok">
      <div className="container">
        <div className="block">
          <h1 className="payment-title">
            {data.validatedAt && <A18n rsx="payments.ok.m1" />}
            {!data.validatedAt && <A18n rsx="payments.ok.m10" />}
          </h1>

          <div className="content">
            {data.validatedAt && (
              <>
                <i className="fa fa-check payment-icon" />
                <p>
                  <A18n rsx="payments.ok.m2" replace={[['%idphysicsale%', data.ref]]} trustHtml />
                </p>
              </>
            )}

            {!data.validatedAt && (
              <>
                <i className={classNames('payment-icon', 'fa', 'fa-hourglass-o', { 'spin-anim': !data.pollingStopped })} />
                <p>
                  <A18n rsx="payments.ok.m3" replace={[['%idphysicsale%', data.ref]]} trustHtml />
                </p>
              </>
            )}
          </div>
        </div>

        <div className="infos">
          {data.hasSubscriptionStore && !data.isStorePersonalized && (
            <p>
              <A18n rsx="payments.ok.m4" replace={[['%url%', data.urlStore]]} trustHtml />
            </p>
          )}

          {data.emailValidationNeeded && (
            <div className="email-validation">
              {(!(logged && idcustomer) && (
                <p>
                  <A18n
                    rsx="payments.ok.m5"
                    replace={[
                      ['%a%', '<a href="/account">'],
                      ['%/a%', '</a>'],
                    ]}
                    trustHtml
                  />
                </p>
              )) ||
                (data.emailValidationRunning && (
                  <>
                    <p>
                      <A18n rsx="payments.ok.m6" trustHtml /> <A18n rsx="payments.ok.m9" trustHtml />
                    </p>
                    <EmailStatus withWarning={false} backTo="home" redirectAfterSending="/account" />
                  </>
                )) || (
                  <>
                    <p>
                      <A18n rsx="payments.ok.m7" trustHtml />
                    </p>
                    <p>
                      <A18n rsx="payments.ok.m8" trustHtml />
                    </p>
                    <EmailStatus withWarning={false} backTo="home" redirectAfterSending="/account" />
                  </>
                )}
            </div>
          )}

          {!data.emailValidationNeeded && (
            <>
              <p>
                <A18n rsx="payments.ok.m8" trustHtml />
              </p>
              <div className="back">
                <Links estimate={data.estimate} hasSubscriptionStore={data.hasSubscriptionStore} lastUrl={data.lastUrl} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ok;
