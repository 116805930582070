import { useState } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { fetchResults } from '../../services/search';

const defaultOptions = {
  cancelPrevious: false,
};

const parseOptions = options => ({
  ...defaultOptions,
  ...options,
});

export function useSearchResults({ searchScope, params, options = undefined }) {
  const allOptions = parseOptions(options);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [meta, setMeta] = useState();
  const [data, setData] = useState();

  useDeepCompareEffect(() => {
    setLoading(true);
    fetchResults(params, searchScope, allOptions.cancelPrevious)
      .then(result => {
        const { results, ...rest } = result;
        unstable_batchedUpdates(() => {
          setMeta(rest);
          setData(results);
        });
      })
      .catch(err => setError(err)) // maybe ignore cancelled error ?
      .finally(() => setLoading(false));
  }, [searchScope, params]);

  return { loading, error, meta, data };
}
