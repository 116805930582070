import React from 'react';
import { ANALYTICS_FROM_AIL_LANDINGPAGE } from '../../../../../../constants/rails';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const Lot = ({ ail }) => (
  <div className="ails-landingpage-sales-sale-lot">
    <a key={ail.id} href={`/redir?ia=${ail.id}&f=${ANALYTICS_FROM_AIL_LANDINGPAGE}`}>
      <div className="repro">
        <img src={ail.images.square} alt={ail.artist_name} />
      </div>
      <div className="legend">
        <div className="info-ail">
          <div className="artist">{ail.artist_name}</div>
          <div className="lot-title" title={ail.title}>
            {ail.title}
          </div>
          <div className="technique">{ail.technique}</div>
          <div className="estimate">
            <span className="title">
              <A18n rsx="ails.landingpage.sales.sale.lot.m1" />
            </span>
            <strong>{ail.estimate}</strong>
          </div>
        </div>
      </div>
    </a>
  </div>
);

export default Lot;
