import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { DEFAULT_CURRENCIES } from '../../../../../constants/preferences';
import { dispatchCurrencyPreference as dispatchCurrencyPreferenceAction } from '../../../../../redux/actions/preferences';
import { getCurrency } from '../../../../../redux/selectors/preferences';
import './stylesheet.scss';

const Currencies = () => {
  const currency = useSelector(getCurrency);
  const dispatch = useDispatch();

  return (
    <div className="marketplace-classified-actions-currencies">
      <ButtonGroup>
        {Object.values(DEFAULT_CURRENCIES).map(c => (
          <Button
            className="btn btn-default"
            key={c.id}
            active={currency === c.iso3}
            onClick={() => {
              dispatch(dispatchCurrencyPreferenceAction(c.iso3));
            }}
            title={i18next.t(c.iso3ForIntl)}
          >
            {c.iso1}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default Currencies;
