import React, { useEffect, useState } from 'react';
import { getLandingPageForAuctioneer } from '../../../services/wallet/ail';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const LandingpageLinkForAuctioneer = ({ idauctioneer, from, className, title, fallbackTitle }) => {
  const [idail, setIdail] = useState();
  useEffect(() => {
    getLandingPageForAuctioneer({ id: idauctioneer }).then(data => setIdail(data));
  }, []);

  if (!idail) {
    if (fallbackTitle) return title;

    return null;
  }

  return (
    <a href={`/redir?ia=${idail}&f=${from}`} className={`ails-landingpage-link ${className}`}>
      {title || <A18n rsx="ails.landingpagelinkforauctioneer.m1" />}
    </a>
  );
};

export default LandingpageLinkForAuctioneer;
