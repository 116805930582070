import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SHORTCUTS_LOTS_FOLLOW, VERTICAL_BAR_CURRENT_ELEMENT_KEY } from '../../../../../constants/myartprice';
import { get as getUiDictionary } from '../../../../../redux/selectors/ui/dictionary';
import { set as setUiDictionary } from '../../../../../redux/actions/ui/dictionary';
import { delayedAapi } from '../../../../../services/analytics/aapi';
import SelectLot from '../../../../account/Lotslists/SelectLot';
import { formatFns } from '../../../../../utils/dates/format';
import Follow from '../../../Follow';
import Links from '../../Links';
import NoPict from '../NoPict';
import './stylesheet.scss';

const Square = ({ isFavoritedLot, lot, urlTarget, title, withSelectLot }) => {
  const dispatch = useDispatch();
  const [imgLoading, setLoading] = useState(true);
  const currentElement = useSelector(state => getUiDictionary(state, { id: VERTICAL_BAR_CURRENT_ELEMENT_KEY }), shallowEqual);

  useEffect(() => {
    if (lot.imageUrl) {
      delayedAapi('lotimage', { idlot: lot.id, index: 0, op: 'list' });
    }
  }, [lot.imageUrl]);

  const handleClick = () => {
    dispatch(setUiDictionary('returnToIdlot', lot.id));
  };

  return (
    <div className={`lots-tile-access-square ${imgLoading ? 'load' : ''}`}>
      {withSelectLot && <SelectLot id={lot.id} />}
      <Link to={urlTarget} onClick={() => handleClick()} id={`lot-${lot.id}`} title={`${title}`}>
        <div id={`lot-${lot.id}`} className={`${SHORTCUTS_LOTS_FOLLOW.includes(currentElement) && !isFavoritedLot ? 'unfollowed' : ''}`}>
          <div className="lot-img" style={{ cursor: 'cursor' }}>
            {lot.imageUrl && <img alt="" src={lot.imageUrl} onLoad={() => setLoading(false)} />}
            {!lot.imageUrl && <NoPict />}
          </div>

          <div className="lot-title">{title}</div>

          <div className="lot-artist">{lot.artistOrAssociation}</div>

          <div className="technique">{lot.technique}</div>

          {/* eslint-disable-next-line react/no-danger */}
          {lot.dimension && (
            <div className="size">
              <span dangerouslySetInnerHTML={{ __html: lot.dimension }} />
            </div>
          )}

          {lot.estimation && (lot.estimation.high || lot.estimation.low) && (
            <div className="estimation">
              {i18next.t('lots.show.m12')}:{' '}
              <span className={!lot.isPast ? 'strong' : ''}>
                {lot.estimation.low && <span dangerouslySetInnerHTML={{ __html: lot.estimation.low }} />}
                {lot.estimation.high && lot.estimation.low && ' - '}
                {lot.estimation.high && <span dangerouslySetInnerHTML={{ __html: lot.estimation.high }} />}
              </span>
            </div>
          )}

          {lot.startingPrice && !lot.estimation && <div dangerouslySetInnerHTML={{ __html: lot.startingPrice }} />}

          {lot.isPast && lot.price && (
            <div className="prices" title={lot.lotstatus === 1 && lot.priceWithTaxes ? `${i18next.t('lots.small.m1')}: ${lot.priceWithTaxes}` : null}>
              {lot.lotstatus === 1 && (
                <>
                  {i18next.t('myartprice.common.lot.m2')}: <span className="strong" dangerouslySetInnerHTML={{ __html: lot.price }} />
                </>
              )}
              {lot.lotstatus !== 1 && <span dangerouslySetInnerHTML={{ __html: lot.price }} />}
            </div>
          )}

          <div className="lot-auctioneer">
            <span style={{ marginLeft: 5 }}>
              {(!lot.saledatestatus || lot.saledatestatus === 1) && (
                <>
                  {lot.saleDtStart} {lot.saleDtStart !== lot.saleDtEnd && `- ${lot.saleDtEnd}`}
                  {!lot.isPast && lot.saledatestatus === 1 && (
                    <i className="display-block">
                      <i className="fa fa-info-circle marg marg-r-5" />
                      {i18next.t('sale_helper.sale_date_status.m1')}
                    </i>
                  )}
                </>
              )}

              {!lot.isPast && lot.saledatestatus === 2 && (
                <>
                  {formatFns(new Date(lot.saleDtStart), 'MMMM yyyy')} {lot.saleDtStart !== lot.saleDtEnd && `- ${formatFns(new Date(lot.saleDtEnd), 'MMMM yyyy')}`}
                  <i className="display-block">
                    <i className="fa fa-info-circle marg marg-r-5" />
                    {i18next.t('sales.tile.sale.m4')}
                  </i>
                </>
              )}

              {!lot.isPast && lot.saledatestatus === 3 && (
                <i className="display-block">
                  <i className="fa fa-info-circle marg marg-r-5" />
                  {i18next.t('sale_helper.sale_date_status.m2')}
                </i>
              )}
            </span>
          </div>

          <div className="lot-auctioneer" title={lot.auctioneerName}>
            {lot.auctioneerName}
          </div>
        </div>
      </Link>
      {/* Follow lot button */}
      <Follow idlot={lot.id} size="lg" />
      <Links lotUrl={urlTarget} />
    </div>
  );
};

export default Square;
