import React from 'react';
import { useSelector } from 'react-redux';
import { MYARTPRICE_LOTS_AUCTIONED_OFF, MYARTPRICE_LOTS_FOLLOW_PAST } from '../../../../../constants/search/scope';
import { getSearchScope } from '../../../../../redux/selectors/myartprice/data';
import { ANALYTICS_FROM_MY_ARTPRICE } from '../../../../../constants/rails';
import Tile from '../../../../lots/Tile';

const Lot = ({ id, crypto }) => {
  const searchScope = useSelector(getSearchScope);

  return (
    <Tile
      id={id}
      analyticClickFrom={ANALYTICS_FROM_MY_ARTPRICE}
      withSelectLot={[MYARTPRICE_LOTS_FOLLOW_PAST, MYARTPRICE_LOTS_AUCTIONED_OFF].includes(searchScope)}
      urlTarget={`${id}/${crypto}${window.location.search}`}
    />
  );
};

export default Lot;
