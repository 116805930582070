import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import ValidableDropDown from '../../common/DropDownToValidate';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import SearchContext from '../../Search/Context';
import './stylesheet.scss';

// the first option will be checked by default unless a value is set
function isDefaultChecked(optionKey, value, index) {
  if (value === undefined) {
    return index === 0;
  }
  return optionKey === value;
}

const Sort = ({ className, title = i18next.t('marketplace.searchbars.classifieds.bar.m6'), icon = 'sort-amount-asc', value, options, onChange, right }) => {
  const { searchScope } = useContext(SearchContext);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  return (
    <ValidableDropDown
      right={right}
      title={title}
      className={className}
      onCancel={() => setInternalValue(value)}
      onSubmit={() => {
        aapiBeacon('search_sort', { searchscope: searchScope, value: internalValue });
        onChange(internalValue);
      }}
      icon={icon}
      displayIconDesktop
      render={() => (
        <div className="search-common-sort">
          <div className="radios-list marg marg-b-15">
            {Object.entries(options).map(([key, label], index) => (
              <label htmlFor={`sort-radio-${key}`} key={key} className="option">
                <input
                  id={`sort-radio-${key}`}
                  onClick={() => setInternalValue(key)}
                  defaultChecked={isDefaultChecked(key, internalValue, index)}
                  className="marg marg-r-5"
                  type="radio"
                  name="search-sort-radios"
                  value={key}
                />
                {i18next.t(label)}
              </label>
            ))}
          </div>
        </div>
      )}
    />
  );
};

export default Sort;
