import React from 'react';
import { IMAGE_SOURCE } from '../../../../../constants/rails';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const Testimonial = () => (
  <div className="x-landingpage-x-row6-testimonial">
    <img src={`${IMAGE_SOURCE}landing/lp-quote-purple.svg`} alt="quote" />
    <p>
      <A18n rsx="artprice.landingpages.services.row6.testimonial.m1" />
    </p>
    <p className="signature">
      <A18n rsx="artprice.landingpages.services.row6.testimonial.m2" />
    </p>
    <p className="signature">
      <A18n rsx="artprice.landingpages.services.row6.testimonial.m3" />
    </p>
  </div>
);

export default Testimonial;
