import React from 'react';
import { EMPTY_OBJECT } from '../../../../../../../constants/utils';
import A18n from '../../../../../../common/A18n';

const Duration = ({ product = EMPTY_OBJECT }) => {
  const { duration = 0, original_nbseconds: originalNbseconds = 0, daysAdded = 0 } = product;

  if (originalNbseconds <= 0) {
    return null;
  }

  if (duration === 0) {
    return (
      <li>
        &#x2714; <A18n rsx="payments.common.cartsummary.m13" /> {daysAdded > 1 && <A18n rsx="payments.common.cartsummary.m3" replace={[['%nbdays%', daysAdded]]} />}
      </li>
    );
  }

  return (
    <>
      <li>
        &#x2714; {duration === 1 && <A18n rsx="payments.common.cartsummary.m14" />}
        {duration === 12 && <A18n rsx="payments.common.cartsummary.m15" />}
        {duration === 24 && <A18n rsx="payments.common.cartsummary.m16" />} {daysAdded > 1 && <A18n rsx="payments.common.cartsummary.m3" replace={[['%nbdays%', daysAdded]]} />}
        {daysAdded === 1 && <A18n rsx="payments.common.cartsummary.m4" />}
      </li>
      {product.duration === 1 && (
        <>
          <li>
            &#x2714; <A18n rsx="payments.common.cartsummary.m9" />
          </li>
          <li>
            &#x2714; <A18n rsx="payments.common.cartsummary.m10" />
          </li>
        </>
      )}
    </>
  );
};

export default Duration;
