import React from 'react';
import i18next from 'i18next';
import { cleanPathname, objectToQueryString, queryStringToObject } from '../../../../../utils/http';
import AdvancedButton from './AdvancedSearchButton';
import { SEARCH_SCOPE_STORES } from '../../../../../constants/rails';
import { forceIdToArray } from '../../../../../utils/search/format';
import Bar from '../../../../search/inputs/SearchBar';
import Search from '../../../../search/Search';
import Params from '../../../../search/Params';
import Drawer from './Drawer';
import './stylesheet.scss';

const SearchBar = ({ idartist }) => {
  const urlParams = queryStringToObject(window.location.search);

  const handleChange = params => {
    window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ ...params, p: 1 })}`;
  };

  return (
    <div className="marketplace-stores-list-search-bar">
      <Search
        searchScope={SEARCH_SCOPE_STORES}
        baseParams={idartist ? { idartist: forceIdToArray(idartist) } : undefined}
        advancedParams={urlParams}
        onChange={({ searchParams }) => {
          handleChange(searchParams);
          return false; // stop the internal Search processing (we are going to reload the page)
        }}
        horizontalBar={({ drawerId }) => (
          <div className="marketplace-stores-list-search-bar-container">
            <Bar placeholder={i18next.t('marketplace.stores.list.m8')} onChange={terms => handleChange({ terms })} />
            <AdvancedButton drawerId={drawerId} />
          </div>
        )}
        drawer={() => <Drawer idartist={idartist} />}
      />
      <Params searchScope={SEARCH_SCOPE_STORES} onChange={handleChange} />
    </div>
  );
};

export default SearchBar;
