import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MYARTPRICE_LOTS_FOLLOW_MARKETPLACE, MYARTPRICE_LOTS_MARKETPLACE } from '../../../constants/search/scope';
import { useInitializeActiveShorcut } from '../../../hooks/myartprice/useInitializeActiveShorcut';
import { usePaginatedSearch } from '../../../hooks/myartprice/usePaginatedSearch';
import { updateSearchScope } from '../../../redux/actions/myartprice/data';
import { useBreadcrumb } from '../../../hooks/myartprice/useBreadcrumb';
import { getItemsPerpage } from '../../../redux/selectors/preferences';
import { isInViewport, scrollToElement } from '../../../utils/ui';
import { getCurrentQueryObject } from '../../../utils/http';
import BackToTopButton from '../../common/BackToTopButton';
import Spinner from '../../common/spinners/Spinner';
import Pagination from '../../common/Pagination';
import VerticalBar from '../common/VerticalBar';
import Classified from '../common/Classified';
import MainLayout from '../common/MainLayout';
import '../common/styles/myartpriceBar.scss';
import NoResults from '../common/NoResults';
import '../common/styles/myartprice.scss';
import SearchBar from './SearchBar';
import './stylesheet.scss';

const Marketplace = ({ searchScope }) => {
  const dispatch = useDispatch();
  const perPage = useSelector(getItemsPerpage);
  const refTitle = useRef();
  const { advancedParams, fetch, ids, totalCount, loading, page } = usePaginatedSearch({ scope: searchScope });

  useBreadcrumb();
  let rsxNoData;
  if (searchScope === MYARTPRICE_LOTS_MARKETPLACE) {
    rsxNoData = 'component.myartprice.marketplace.m7';
  } else if (searchScope === MYARTPRICE_LOTS_FOLLOW_MARKETPLACE) {
    rsxNoData = 'component.myartprice.marketplace.m6';
  }

  useInitializeActiveShorcut();

  const handlePaginationClick = p => {
    fetch({ ...advancedParams, p });

    if (!isInViewport('#top-pagination')) {
      scrollToElement(refTitle);
    }
  };

  useEffect(() => {
    dispatch(updateSearchScope(searchScope));
    fetch(getCurrentQueryObject());
  }, [searchScope]);

  return (
    <MainLayout ref={refTitle}>
      <VerticalBar />
      <div className="col-container">
        <SearchBar searchScope={searchScope} />
        <div className="data-container">
          {ids.map((idclassified, idx) => (
            <Classified position={idx + 1 + (page - 1) * perPage} key={`marketplace-${idclassified}`} linkToArtist={false} id={idclassified} displayPrice displayTitle={false} />
          ))}
        </div>

        {(loading && <Spinner />) || (
          <>
            {/* display a 'no results' message when results are empty */}
            {ids.length === 0 && <NoResults messageRsxKey={rsxNoData} linkRsxKey="component.myartprice.marketplace.m3" href="/marketplace" />}

            {/* Pagination */}
            {totalCount / perPage > 1 && totalCount > 0 && (
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
                <Pagination className="pagination" perPage={perPage} pagePadding={1} loading={loading} totalCount={totalCount} activePage={page} onClick={p => handlePaginationClick(p)} />
              </div>
            )}

            {/* Go back to top */}
            <div style={{ position: 'sticky', bottom: '25px', float: 'right', transform: 'translateX(10px)' }}>
              <BackToTopButton top={refTitle.current} />
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Marketplace;
