import i18next from 'i18next';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToDraftParams, areDraftFacetsLoading, getBaseParams, getDraftCount, getDraftParams, resetDraftParams } from '../../../../redux/slices/search/scoped';
import { off as closeDrawer } from '../../../../redux/actions/ui/switches';
import CommonDrawer from '../../../common/Drawer';
import SearchContext from '../Context';
import './stylesheet.scss';

const Drawer = ({ id, render, searchScope, onChange }) => {
  const dispatch = useDispatch();

  const baseParams = useSelector(state => getBaseParams(state, { searchScope }));
  const draftParams = useSelector(state => getDraftParams(state, { searchScope }));
  const draftCount = useSelector(state => getDraftCount(state, { searchScope }));
  const loading = useSelector(state => areDraftFacetsLoading(state, { searchScope }));

  const searchContextValue = useMemo(
    () => ({
      values: { ...baseParams, ...draftParams },
      onChange: changes => {
        const changed = Object.keys(changes);
        dispatch(addToDraftParams({ searchScope, params: changes, lockedFacets: changed }));
      },
      searchScope,
    }),
    [searchScope, dispatch, baseParams, draftParams],
  );

  return (
    <CommonDrawer
      id={id}
      onClose={() => dispatch(resetDraftParams({ searchScope }))}
      render={() => (
        <div className="search-search-drawer">
          <div className="header">
            <span>{i18next.t('marketplace.searchbars.classifieds.bar.m1')}</span>
            <i
              role="presentation"
              className="fa fa-times text-right"
              onClick={() => {
                dispatch(resetDraftParams({ searchScope }));
                dispatch(closeDrawer(id));
              }}
            />
          </div>
          <div className="body">
            <SearchContext.Provider value={searchContextValue}>{render()}</SearchContext.Provider>
          </div>

          <div className="footer">
            {loading && (
              <div className="info">
                <i className="fa fa-spinner fa-spin" />
              </div>
            )}

            {!loading && (
              <div className="info">
                {draftCount === 0 && i18next.t('marketplace.searchbars.common.facetsselect.m1')}
                {draftCount === 1 && i18next.t('components.search.helpers.count.m3')}
                {draftCount > 1 && i18next.t('components.search.helpers.count.m4').replace('%count%', draftCount)}
              </div>
            )}
            <button
              type="button"
              className="search-drawer-submit"
              onClick={async () => {
                onChange(draftParams);
                dispatch(closeDrawer(id));
              }}
            >
              {i18next.t('marketplace.searchbars.classifieds.bar.m2')}
            </button>
          </div>
        </div>
      )}
    />
  );
};

export default Drawer;
