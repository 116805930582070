/* eslint-disable react/style-prop-object */

import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { FormattedNumber, useIntl } from 'react-intl';
import { ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION, ANALYTICS_FROM_LANDINGPAGES_SERVICES } from '../../../../constants/rails';
import AvailablePayments from '../../../Payments/common/AvailablePayments';
import { getCurrency } from '../../../../redux/selectors/preferences';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Row2 = ({ prices }) => {
  const intl = useIntl();
  const currency = useSelector(getCurrency);

  return (
    <div className="x-landingpage-x-row2">
      <div className="container">
        <div className="row2">
          <h1>
            <A18n rsx="artprice.landingpages.services.row2.m1" />
          </h1>
          <h2>
            <A18n rsx="artprice.landingpages.services.row2.m8" />
          </h2>

          <div className="infos">
            <div className="prices">
              <div className="left">
                <div className="price">
                  <div>
                    <span className="number">
                      <FormattedNumber notation="compact" value={9} style="currency" currency={currency} />
                    </span>
                  </div>
                  <div>
                    <A18n rsx="artprice.landingpages.services.row2.m4" />
                  </div>
                  <div>
                    <A18n rsx="artprice.landingpages.services.row2.m3" replace={[['%price%', intl.formatNumber(1, { style: 'currency', currency, maximumFractionDigits: 0 })]]} />
                  </div>
                </div>
              </div>
              <div className="right">
                <p>
                  <A18n
                    rsx="artprice.landingpages.services.row2.m5"
                    trustHtml
                    replace={[
                      ['%price%', intl.formatNumber(9, { style: 'currency', currency, maximumFractionDigits: 0 })],
                      ['%price-per-day%', intl.formatNumber(1, { style: 'currency', currency, maximumFractionDigits: 0 })],
                    ]}
                  />
                </p>
                <p>
                  <A18n
                    rsx="artprice.landingpages.services.row2.m6"
                    trustHtml
                    replace={[['%price%', intl.formatNumber(get(prices, 'originalPrice.eur'), { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })]]}
                  />
                </p>
              </div>
            </div>
            <div className="cta">
              <div className="left">
                <a
                  href="/payments/informations?image=true&idphysicproducts[251]=1&type=new&promocode=PREMIUM9"
                  className="artp-btn artp-btn-premium"
                  onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_LANDINGPAGES_SERVICES, op: ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION, section: 'row-2' })}
                >
                  <A18n rsx="artprice.landingpages.services.row2.m7" trustHtml />
                </a>
              </div>
              <AvailablePayments hideOfflinePayments />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Row2;
