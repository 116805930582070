import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getSearchScope } from '../../../../../../../redux/selectors/myartprice/data';
import { getBaseFacets } from '../../../../../../../redux/slices/search/scoped';
import FacetOption from './Facet';
import AllOption from './All';
import './stylesheet.scss';

const QuickFilter = ({ facetName, onFilterClick }) => {
  const searchScope = useSelector(getSearchScope);
  const defaultFacets = useSelector(state => getBaseFacets(state, { searchScope }));

  const [sortedFacets, setSortedFacets] = useState([]);

  useEffect(() => {
    setSortedFacets(Object.values(defaultFacets[facetName] || {}).sort((a, b) => a?.sort?.localeCompare(b?.sort)));
  }, [defaultFacets]);

  return (
    <div className="facets">
      {defaultFacets[facetName] && <AllOption facetName={facetName} />}
      {sortedFacets.map(facet => (
        <FacetOption key={`${facetName}-${facet.id}`} facet={facet} facetName={facetName} onFilterClick={onFilterClick} />
      ))}
    </div>
  );
};

export default QuickFilter;
