import React from 'react';
import i18next from 'i18next';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ARTISTS_OFFICIAL_COUNT, MODULE_EXPORT_PDF, MODULE_IMAGE, MODULE_INDEXES, MODULE_INDICATOR } from '../../../../constants/rails';
import { getActiveSubscription, getIdstatutetype, getIsAuctioneer, getIsNonPartnerAuctioneer } from '../../../../redux/slices/userContext/selectors';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const managedIdmodules = [MODULE_EXPORT_PDF, MODULE_IMAGE, MODULE_INDEXES, MODULE_INDICATOR];

const Header = () => {
  const intl = useIntl();
  const { view, requiredIdmodule, filter } = useSelector(getSettings);
  const activeSubcription = useSelector(getActiveSubscription);
  const isAuctioneer = useSelector(getIsAuctioneer);
  const isNonPartnerAuctioneer = useSelector(getIsNonPartnerAuctioneer);
  const idstatutetype = useSelector(getIdstatutetype);
  const replacements = [
    ['%curabo%', activeSubcription?.name],
    ['%nbuser%', activeSubcription?.usersCount],
    ['%dtend%', activeSubcription ? intl.formatDate(new Date(activeSubcription.dates.dtExpire)) : ''],
  ];

  if (view === 'upgrade') {
    return (
      <div className="subscriptions-list-header">
        <h1>
          <A18n rsx="subscriptions.list.header.m3" trustHtml />
        </h1>
        <p>
          {requiredIdmodule === MODULE_INDEXES && <A18n rsx="subscriptions.list.header.m4" replace={replacements} trustHtml />}
          {requiredIdmodule === MODULE_IMAGE && <A18n rsx="subscriptions.list.header.m5" replace={replacements} trustHtml />}
          {requiredIdmodule === MODULE_EXPORT_PDF && <A18n rsx="subscriptions.list.header.m6" replace={replacements} trustHtml />}
          {requiredIdmodule === MODULE_INDICATOR && <A18n rsx="subscriptions.list.header.m7" replace={replacements} trustHtml />}
          {idstatutetype === 5 && <A18n rsx="subscriptions.list.header.m9" replace={replacements} trustHtml />}
          {idstatutetype !== 5 && !managedIdmodules.includes(requiredIdmodule) && <A18n rsx="subscriptions.list.header.m8" replace={replacements} trustHtml />}{' '}
          <A18n rsx="subscriptions.list.header.m15" replace={replacements} trustHtml />
        </p>
      </div>
    );
  }

  if (isAuctioneer || isNonPartnerAuctioneer)
    return (
      <div className="subscriptions-list-header">
        {isAuctioneer && (
          <>
            <h1>
              <A18n rsx="subscriptions.list.header.m10" />
            </h1>
            <p>
              <A18n rsx="subscriptions.list.header.m11" trustHtml />
            </p>
          </>
        )}
        {isNonPartnerAuctioneer && (
          <>
            <h1>{i18next.t('subscriptions.list.header.m12')}</h1>
            <p>{i18next.t('subscriptions.list.header.m13')}</p>
            <p>
              <A18n
                rsx="subscriptions.list.header.m14"
                trustHtml
                replace={[
                  ['%a%', '<a target="_blank" href="/auctioneer">'],
                  ['%/a%', '</a>'],
                ]}
              />
            </p>
          </>
        )}
      </div>
    );

  return (
    <div className="subscriptions-list-header">
      <h1>
        <A18n rsx="subscriptions.list.header.m17" />
      </h1>
      <p>
        {filter === 'store' && <A18n rsx="subscriptions.list.header.m16" trustHtml />}
        {filter === 'subscription' && <A18n rsx="subscriptions.list.header.m18" replace={[['%ARTISTS_OFFICIAL_COUNT%', intl.formatNumber(ARTISTS_OFFICIAL_COUNT)]]} trustHtml />}
      </p>
    </div>
  );
};

export default Header;
