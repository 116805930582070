import React from 'react';
import { Link } from 'react-router';

export const BUTTON_BACK = 'fa-chevron-circle-left';
export const BUTTON_TO = 'fa-chevron-circle-right';
const labels = { [BUTTON_TO]: 'Tout voir', [BUTTON_BACK]: 'Retour' };

const ButtonLink = ({ totalCount, direction, to }) => (
  <Link to={to} className="btn btn-default btn-xs" style={{ marginLeft: 5 }}>
    <i className={`fa ${direction}`} /> {labels[direction]} {totalCount && `(${totalCount})`}
  </Link>
);

export default ButtonLink;
