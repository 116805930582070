import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import i18next from 'i18next';
import { getSearchScope } from '../../../redux/selectors/myartprice/data';
import {
  MYARTPRICE_LOTS,
  MYARTPRICE_LOTS_AUCTIONED_OFF,
  MYARTPRICE_LOTS_FOLLOW,
  MYARTPRICE_LOTS_FOLLOW_FUTURE,
  MYARTPRICE_LOTS_FOLLOW_PAST,
  MYARTPRICE_LOTS_FUTURE_ALL,
  MYARTPRICE_LOTS_FUTURE_NEW,
} from '../../../constants/search/scope';

// Breakcrumb
const mapScopeToTitleRsx = searchScope => {
  if (MYARTPRICE_LOTS.includes(searchScope)) return 'layouts.header.m16';
  if (MYARTPRICE_LOTS_FOLLOW.includes(searchScope)) return 'layouts.header.m43';
};

const mapScopeToSubTitleRsx = searchScope => {
  switch (searchScope) {
    case MYARTPRICE_LOTS_FUTURE_ALL:
    case MYARTPRICE_LOTS_FUTURE_NEW:
    case MYARTPRICE_LOTS_FOLLOW_FUTURE:
      return 'components.myartprice.common.verticalbar.content.m3';
    case MYARTPRICE_LOTS_FOLLOW_PAST:
    case MYARTPRICE_LOTS_AUCTIONED_OFF:
      return 'components.myartprice.common.verticalbar.content.m4';
    default:
      return null;
  }
};

export function useBreadcrumb() {
  const searchscope = useSelector(getSearchScope);

  useEffect(() => {
    if (searchscope) {
      const breadcrumb = document.querySelector('.breadcrumb');

      if (breadcrumb) {
        breadcrumb.classList.add('hidden-xs', 'hidden-sm');
        breadcrumb.innerHTML = `<li><a href="/">${i18next.t('common._marketplace_menu.html.m39')}</a></li> 
                            <li>${i18next.t('artprice.lib.myartprice.artists.m13')}</li> 
                            <li>${i18next.t(mapScopeToTitleRsx(searchscope))}</li> 
                            <li>${i18next.t(mapScopeToSubTitleRsx(searchscope))}</li>`;
      }
    }
  }, [searchscope]);
}
