/* eslint-disable react/style-prop-object */

import React from 'react';
import './stylesheet.scss';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import { ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION, ANALYTICS_FROM_LANDINGPAGES_SERVICES, IMAGE_SOURCE } from '../../../../constants/rails';
import { getCurrency } from '../../../../redux/selectors/preferences';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { PROMO_IDPHYSICPRODUCT, PROMOCODE } from '../constant';
import A18n from '../../../common/A18n';

const Row4 = () => {
  const currency = useSelector(getCurrency);

  return (
    <div className="x-landingpage-x-row4">
      <div className="container">
        <div className="r">
          <div className="c1">
            <h2>
              <A18n rsx="artprice.landingpages.services.row4.m1" />
            </h2>
            <p>
              <A18n rsx="artprice.landingpages.services.row4.m2" />
            </p>
            <p>
              <A18n rsx="artprice.landingpages.services.row4.m3" trustHtml />
            </p>
          </div>
          <div className="c2">
            <img src={`${IMAGE_SOURCE}landing/lp-chart.png`} alt="chart" />
          </div>
        </div>
        <div className="r">
          <div className="c1">
            <p>
              <a
                href={`/payments/informations?image=true&idphysicproducts[${PROMO_IDPHYSICPRODUCT}]=1&type=new&promocode=${PROMOCODE}`}
                onClick={() =>
                  aapiBeacon('click', {
                    from: ANALYTICS_FROM_LANDINGPAGES_SERVICES,
                    op: ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION,
                    section: 'row-4',
                  })
                }
              >
                <A18n rsx="artprice.landingpages.services.row4.m4" replace={[['%price%', <FormattedNumber key="row4-price" notation="compact" value={1} style="currency" currency={currency} />]]} />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Row4;
