/* eslint-disable global-require */
module.exports = [
  'artprice.landingpages.services.row7.faq.m1',
  'artprice.landingpages.services.row7.faq.m2',
  'artprice.landingpages.services.row7.faq.m3',
  'artprice.landingpages.services.row7.faq.m4',
  'artprice.landingpages.services.row7.faq.m5',
  'artprice.landingpages.services.row7.faq.m6',
  'artprice.landingpages.services.row7.faq.m7',
  'artprice.landingpages.services.row7.faq.m8',
  'artprice.landingpages.services.row7.faq.m9',
  'artprice.landingpages.services.row7.faq.m10',
  'artprice.landingpages.services.row7.faq.m11',
  'artprice.landingpages.services.row7.faq.m12',
  'artprice.landingpages.services.row7.faq.m13',
  'artprice.landingpages.services.row7.faq.m14',
  'artprice.landingpages.services.row7.faq.m15',
  'artprice.landingpages.services.row7.faq.m16',
  'artprice.landingpages.services.row7.faq.m17',
  'artprice.landingpages.services.row7.faq.m18',
  'artprice.landingpages.services.row7.faq.m19',
  'artprice.landingpages.services.row7.faq.m20',
  'artprice.landingpages.services.row7.faq.m21',
  'artprice.landingpages.services.row7.faq.m22',
  'artprice.landingpages.services.row7.faq.m23',
];
