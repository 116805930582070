import React, { useEffect, useMemo, useState } from 'react';
import { debounce as debounceFunction } from 'lodash';
import { getTextValue } from '../../../../utils/search/inputs';
import './stylesheet.scss';

const DebouncedInput = ({ className, value, onChange, placeholder, debounce = 100, type = 'text' }) => {
  const [internalValue, setInternalValue] = useState(value);
  const debouncedOnChange = useMemo(() => debounceFunction(onChange, debounce), [onChange, debounce]);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <input
      className={`form-control ${className ?? ''}`}
      type={type}
      placeholder={placeholder}
      value={internalValue ?? ''}
      onChange={e => {
        const newValue = getTextValue(e.target.value);
        setInternalValue(newValue);
        debouncedOnChange(newValue);
      }}
    />
  );
};

export default DebouncedInput;
