import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Folder from './folder/Folder';
import FolderMobile from './folder/FolderMobile';
import Item from './folder/Item';
import { getMarketplaceHasClassifieds, get as getUserContext } from '../../../../../redux/slices/userContext/selectors';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import {
  ANALYTICS_FROM_HEADER_MENU,
  ANALYTICS_CLICK_OPERATION_TO_AMI_HOME,
  ANALYTICS_CLICK_OPERATION_TO_DEMO,
  ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOME,
  ANALYTICS_CLICK_OPERATION_TO_EVENTS_HOME,
  ANALYTICS_CLICK_OPERATION_TO_FUTURE_SALES,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_CLASSIFIEDS_ALL,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_FORM,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_SELL,
  ANALYTICS_CLICK_OPERATION_TO_NFT_HOME,
  ANALYTICS_CLICK_OPERATION_TO_REPORTS_HOME,
  ANALYTICS_CLICK_OPERATION_TO_SEARCH_ARTISTS_PAGE,
  ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOWTO,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_STORES,
  ANALYTICS_CLICK_OPERATION_TO_HOWTO_SEARCH,
  ANALYTICS_CLICK_OPERATION_TO_SUCCESSION,
} from '../../../../../constants/rails';
import { a18n } from '../../../../../utils/a18n';
import { get } from '../../../../../redux/selectors/ui/dictionary';
import { DICTIONARY_KEY, AUCTION_FOLDER, MARKETPLACE_FOLDER, BEHIND_THE_SCENES_FOLDER, NEWS_FOLDER, SEARCH_FOLDER } from '../../../../../constants/layout';
import A18n from '../../../../common/A18n';
import { getAuctioneerLink } from '../../../../../utils/links/auctioneer';

import './stylesheet.scss';

// overlay over actual aapi to set type and from
function aapiWrapper(op) {
  aapiBeacon('click', {
    from: ANALYTICS_FROM_HEADER_MENU,
    op,
  });
}

const Menu = ({ mobile = false }) => {
  const activeMenu = useSelector(state => get(state, { id: DICTIONARY_KEY }));
  const hasClassifieds = useSelector(getMarketplaceHasClassifieds);
  const { isAuctioneer, isNonPartnerAuctioneer, auctioneerMapped, auctioneerAgreement: { signedAt } = {} } = useSelector(getUserContext);
  const [openedFolder, setOpenedFolder] = useState('');
  const closeAllFolders = useCallback(() => setOpenedFolder(''), [setOpenedFolder]);
  const FolderComponent = mobile ? FolderMobile : Folder;

  return (
    <div className={classNames('artp-Header-Menu', { 'menu-mobile': mobile, 'menu-desktop': !mobile, selected: openedFolder })}>
      {/* Rechercher un prix */}
      <FolderComponent
        className={`sln-folder-auction-artists ${activeMenu === SEARCH_FOLDER ? 'active' : ''}`}
        title={a18n('layouts.header.m50')}
        selected={openedFolder === SEARCH_FOLDER}
        onOpen={() => setOpenedFolder(SEARCH_FOLDER)}
        onClose={closeAllFolders}
      >
        <Item link="/search" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_SEARCH_ARTISTS_PAGE)}>
          <A18n rsx="layouts.header.m51" />
        </Item>
        <Item link={`/${a18n('routes.estimate')}`} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOME)}>
          <A18n rsx="layouts.header.m53" />
        </Item>
        <Item link="/how-to-search" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_HOWTO_SEARCH)}>
          <A18n rsx="layouts.header.m52" />
        </Item>
        <Item link="/estimate-with-artprice" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOWTO)}>
          <A18n rsx="layouts.header.m54" />
        </Item>
        <Item link={`/${a18n('routes.succession')}`} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_SUCCESSION)}>
          <A18n rsx="layouts.header.m71" />
        </Item>
        <Item link="/demo" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_DEMO)}>
          <A18n rsx="layouts.menu.m28" />
        </Item>
      </FolderComponent>

      {/* Consulter les ventes */}
      <FolderComponent
        className={classNames('sln-folder-auction-databases', { active: activeMenu === AUCTION_FOLDER })}
        title={a18n('layouts.header.m55')}
        selected={openedFolder === AUCTION_FOLDER}
        onOpen={() => setOpenedFolder(AUCTION_FOLDER)}
        onClose={closeAllFolders}
      >
        <Item link={`/${a18n('routes.sales_futures')}`} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_FUTURE_SALES)}>
          <A18n rsx="layouts.header.m56" />
        </Item>
        <Item link="/search" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_SEARCH_ARTISTS_PAGE)}>
          <A18n rsx="layouts.header.m57" />
        </Item>
        <Item link="/how-to-search" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_HOWTO_SEARCH)}>
          <A18n rsx="layouts.header.m52" />
        </Item>
        <Item link="/demo" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_DEMO)}>
          <A18n rsx="layouts.menu.m28" />
        </Item>
      </FolderComponent>

      {/* Suivre l'actualité */}
      <FolderComponent
        className={classNames('sln-folder-market-news', 'border-right', { active: activeMenu === NEWS_FOLDER })}
        title={a18n('layouts.header.m59')}
        selected={openedFolder === NEWS_FOLDER}
        onOpen={() => setOpenedFolder(NEWS_FOLDER)}
        onClose={closeAllFolders}
      >
        <Item link="/artmarketinsight" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_AMI_HOME)}>
          <A18n rsx="layouts.menu.m13" />
        </Item>
        <Item link="/artmarketinsight/reports" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_REPORTS_HOME)}>
          <A18n rsx="layouts.menu.m14" />
        </Item>
        <Item link="/events" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_EVENTS_HOME)}>
          <A18n rsx="layouts.header.m60" />
        </Item>
        {/* <Item link="/todo_header" onClick={() => aapiWrapper('todo_header')}> */}
        {/*  <A18n rsx="layouts.header.m61" /> */}
        {/* </Item> */}
        <Item link="/nft" onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_NFT_HOME)}>
          <A18n rsx="layouts.menu.nft" />
        </Item>
      </FolderComponent>

      {/* Marketplace */}
      <FolderComponent
        className={classNames('sln-folder-marketplace', 'border-right', { active: activeMenu === MARKETPLACE_FOLDER })}
        title={a18n('layouts.menu.m6')}
        selected={openedFolder === MARKETPLACE_FOLDER}
        onOpen={() => setOpenedFolder(MARKETPLACE_FOLDER)}
        onClose={closeAllFolders}
      >
        <Item link={`/${a18n('routes.marketplace')}`} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_CLASSIFIEDS_ALL)}>
          <A18n rsx="layouts.menu.m7" />
        </Item>
        {hasClassifieds && (
          <Item className="sln-menu-marketplace-sell" link={`/${a18n('routes.marketplace_info')}`} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_FORM)}>
            <A18n rsx="layouts.menu.m8" />
          </Item>
        )}
        {!hasClassifieds && (
          <Item className="sln-menu-marketplace-sell" link={`/${a18n('routes.marketplace')}/how-to-sell`} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_SELL)}>
            <A18n rsx="layouts.menu.m8" />
          </Item>
        )}
        <Item link={`/${a18n('routes.marketplace')}/store`} onClick={() => aapiWrapper(ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_STORES)}>
          <A18n rsx="layouts.header.m62" />
        </Item>
      </FolderComponent>

      {/* En coulisses */}
      <FolderComponent
        className={classNames('sln-folder-team', { active: activeMenu === BEHIND_THE_SCENES_FOLDER })}
        title={a18n('layouts.header.m63')}
        selected={openedFolder === BEHIND_THE_SCENES_FOLDER}
        onOpen={() => setOpenedFolder(BEHIND_THE_SCENES_FOLDER)}
        onClose={closeAllFolders}
      >
        <Item link="/todo_header" onClick={() => aapiWrapper('todo_header')}>
          <A18n rsx="layouts.header.m64" />
        </Item>
        <Item link="/todo_header" onClick={() => aapiWrapper('todo_header')}>
          <A18n rsx="layouts.header.m65" />
        </Item>
        <Item link="/todo_header" onClick={() => aapiWrapper('todo_header')}>
          <A18n rsx="layouts.header.m66" />
        </Item>
        <Item link="/todo_header" onClick={() => aapiWrapper('todo_header')}>
          <A18n rsx="layouts.header.m67" />
        </Item>
      </FolderComponent>

      {(isAuctioneer || isNonPartnerAuctioneer) && <FolderComponent link={getAuctioneerLink({ isAuctioneer, isNonPartnerAuctioneer, auctioneerMapped, signedAt })} title={a18n('layouts.header.m42')} />}
    </div>
  );
};

export default Menu;
