import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { MARKETPLACE_AUCTIONS, MARKETPLACE_DEFAULT, MARKETPLACE_WITH_KEYWORD } from '../../../../constants/search/sorts';
import { cleanPathname, objectToQueryString, queryStringToObject, updateUrlQuery } from '../../../../utils/http';
import TechniqueSelect from '../../../search/helpers/items/TechniqueSelect';
import CategorySelect from '../../../search/helpers/items/CategorySelect';
import { on as openDrawer } from '../../../../redux/actions/ui/switches';
import CountrySelect from '../../../search/helpers/items/CountrySelect';
import ShapeSelect from '../../../search/helpers/items/ShapeSelect';
import AdvancedButton from '../../../search/helpers/AdvancedButton';
import DebouncedInput from '../../../search/inputs/DebouncedInput';
import SizeSelect from '../../../search/helpers/items/SizeSelect';
import { forceIdToArray } from '../../../../utils/search/format';
import ArtistSearch from '../../../search/inputs/ArtistSearch';
import Preferences from '../../../search/helpers/Preferences';
import LoadingOverlay from '../../../common/LoadingOverlay';
import { useViewport } from '../../../../hooks/useViewport';
import Checkbox from '../../../search/inputs/Checkbox';
import Select from '../../../search/inputs/Select';
import Prices from '../../../search/inputs/Prices';
import Count from '../../../search/helpers/Count';
import Sort from '../../../search/helpers/Sort';
import Item from '../../../search/Search/Item';
import Search from '../../../search/Search';
import Params from '../../../search/Params';
import './stylesheet.scss';

function formatBaseParams(idartist, sitenameformated) {
  if (!idartist && !sitenameformated) {
    return undefined;
  }

  const baseParams = {};
  if (idartist) {
    baseParams.idartist = forceIdToArray(idartist);
  }
  if (sitenameformated) {
    baseParams.sitenameformated = sitenameformated;
  }
  return baseParams;
}

const Bar = ({ searchScope, idartist, sitenameformated, hiddenFields, sortInRailsParam }) => {
  const urlParams = queryStringToObject(window.location.search);
  const [sortOptions, setSortOptions] = useState(MARKETPLACE_DEFAULT);
  const [searching, setSearching] = useState(false);
  const { viewportWidth } = useViewport();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sortInRailsParam) {
      updateUrlQuery({ ...urlParams, sort: sortInRailsParam });
    }
  }, [sortInRailsParam]);

  useEffect(() => {
    setSortOptions({ ...sortOptions, ...(urlParams?.idclassifiedauctionmode === '1' ? MARKETPLACE_AUCTIONS : {}), ...(urlParams?.terms ? MARKETPLACE_WITH_KEYWORD : {}) });
  }, [urlParams?.idclassifiedauctionmode, urlParams?.terms]);

  const handleChange = params => {
    setSearching(true);
    const { sort } = urlParams;
    window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ sort, ...params, p: 1 })}`;
  };

  return (
    <div className="myartprice-marketplace-search-bar">
      <Search
        searchScope={searchScope}
        baseParams={formatBaseParams(idartist, sitenameformated)}
        advancedParams={urlParams}
        onChange={({ searchParams }) => {
          handleChange(searchParams);
          return false; // stop the internal Search processing (we are going to reload the page)
        }}
        horizontalBar={({ drawerId, searchParams }) => (
          <div className="marketplace-search-bar">
            <div className="filters-container">
              {viewportWidth && viewportWidth < 992 && (
                <button type="button" onClick={() => dispatch(openDrawer('myartprice-vertical-bar'))} className="search-helpers-advanced-button">
                  <i className="fa fa-filter" /> {viewportWidth >= 768 && i18next.t('component.myartprice.lot.m9')}
                </button>
              )}

              <AdvancedButton drawerId={drawerId} />
              <div className="flex-filler" />
              <Sort options={sortOptions} onChange={sortOption => handleChange({ ...searchParams, sort: sortOption })} value={urlParams?.sort} />
              <Preferences right rsx={{ title: false }} onChange={() => handleChange(searchParams)} hiddenFields={{ lotsViewFormat: true, dimension: true, artistsViewFormat: true, freshness: true }} />
            </div>
            <Params searchScope={searchScope} onChange={handleChange} />
          </div>
        )}
        drawer={() => (
          <div>
            <Item label={i18next.t('marketplace.searchbars.stores.bar.m5')} name="terms">
              <DebouncedInput debounce={250} placeholder={i18next.t('layouts.menu.m2')} />
            </Item>
            <hr />
            <Item label={i18next.t('marketplace.searchbars.common.artist.m1')} name="idartist">
              <ArtistSearch disabled={!!idartist} />
            </Item>
            <Item label={i18next.t('marketplace.searchbars.classifieds.bar.m13')} name="in_fav">
              <Checkbox />
            </Item>
            <hr />
            <CategorySelect />
            <TechniqueSelect />
            <ShapeSelect />
            <SizeSelect />
            <Item label={i18next.t('marketplace.searchbars.classifieds.bar.m9')} name={{ price_min: 'from', price_max: 'to', price_idcurrency: 'currency' }}>
              <Prices />
            </Item>
            <hr />
            {!hiddenFields?.idcountry && <CountrySelect />}
            {!hiddenFields?.idclassifiedauctionmode && (
              <Item label={i18next.t('marketplace.searchbars.classifieds.bar.classifiedauctionmodes.m1')} name="idclassifiedauctionmode">
                <Select facet="idclassifiedauctionmode" placeholder={i18next.t('marketplace.searchbars.classifieds.bar.classifiedauctionmodes.m2')} hideCount />
              </Item>
            )}
            {!hiddenFields?.idprofessionaltype && (
              <Item label={i18next.t('marketplace.searchbars.common.professionaltypes.m2')} name="idprofessionaltype">
                <Select facet="idprofessionaltype" placeholder={i18next.t('marketplace.searchbars.common.professionaltypes.m1')} />
              </Item>
            )}
            {!hiddenFields?.idstoretype && (
              <Item label={i18next.t('marketplace.searchbars.common.storetypes.m1')} name="idstoretype">
                <Select facet="idstoretype" placeholder={i18next.t('marketplace.searchbars.common.storetypes.m2')} />
              </Item>
            )}
          </div>
        )}
      />
      <LoadingOverlay visible={searching} />
      <Count searchScope={searchScope} />
    </div>
  );
};

export default Bar;
