import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router';
import { getSale } from '../../../../redux/selectors/dbEntities';
import './stylesheet.scss';
import { set } from '../../../../redux/actions/ui/dictionary';
import { ACTIVE_AAPI_BEACON_FFROM, ACTIVE_MENU } from '../../../../constants/auctioneers';
import { push } from '../../../../redux/selectors/auctioneers/sales';
import Cover from '../../common/Cover';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { ANALYTICS_OPERATION_AUCTIONEERS_TO_SALE, ANALYTICS_OPERATION_AUCTIONEERS_TO_SEND_DATA } from '../../../../constants/rails';
import { get } from '../../../../redux/selectors/ui/dictionary';

const Sale = ({ idsale, intl }) => {
  const from = useSelector(state => get(state, { id: ACTIVE_AAPI_BEACON_FFROM }));
  const sale = useSelector(state => getSale(state, { id: idsale }));
  const pushCount = useSelector(state => push(state, { id: idsale }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set(ACTIVE_MENU, 1));
  }, []);

  if (!sale) {
    return null;
  }

  return (
    <div className="auctioneers-past-sales-sale">
      <Cover sale={sale} />
      <div className="infos">
        <div className="title">
          {sale.fineart && (
            <Link onClick={() => aapiBeacon('click', { from, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_SALE, idsale })} to={`/auctioneer/control-center/sale?id=${sale.id}`}>
              {sale.title}
            </Link>
          )}
          {!sale.fineart && sale.title}
        </div>
        <div className="date">
          {sale.dtStart}
          {sale.dtStart !== sale.dtEnd && <span> - {sale.dtEnd} </span>}
        </div>
        <div className="location">
          {sale.city}
          {sale.country && sale.city && ', '}
          {sale.country}
        </div>
        <div className="filler" />
        {!sale.fineart && <div className="no-fineart">{i18next.t('auctioneers.pastsales.sale.m1')}</div>}
        {pushCount > 0 && <div className="stats">{i18next.t('auctioneers.pastsales.sale.m2').replace('%pushCount%', intl.formatNumber(pushCount))}</div>}
        {!sale.resultsProcessed && (
          <div className="no-result">
            <Link to="/auctioneer/control-center/docs" onClick={() => aapiBeacon('click', { from, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_SEND_DATA, idsale })}>
              <i className="fa fa-exclamation-triangle marg marg-r-5" />
              {i18next.t('auctioneers.sale.header.m4')}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(Sale);
