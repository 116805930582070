import React from 'react';
import i18next from 'i18next';
import { useParams } from 'react-router';
import ClosingDropDown from '../../../../search/common/ClosingDropDown';
import CommonHorizontalBar from '../../../../common/HorizontalBar';
import LotDetail from '../../../../lots/Show';

import './stylesheet.scss';

const Lot = () => {
  const { idlot, crypto } = useParams();

  return (
    <div className="auctioneers-sale-lots-lot">
      <CommonHorizontalBar className="bar">
        <div className="container">
          <div className="filters-container">
            <span className="back" onClick={() => window.history.back()}>
              {i18next.t('auctioneers.sale.lots.lot.m1')}
            </span>
            <div className="flex-filler" />
            <ClosingDropDown title={i18next.t('auctioneers.sale.lots.lot.m2')} />
          </div>
        </div>
      </CommonHorizontalBar>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <LotDetail id={idlot} crypto={crypto} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lot;
