import React from 'react';
import './stylesheet.scss';
import Stamps from './Stamps';
import A18n from '../../../common/A18n';

const Row3 = () => (
  <div className="x-landingpage-x-row3">
    <div className="container">
      <h2>
        <A18n rsx="artprice.landingpages.services.row3.m1" />
      </h2>
      <p>
        <A18n rsx="artprice.landingpages.services.row3.m2" trustHtml />
      </p>
      <p>
        <A18n rsx="artprice.landingpages.services.row3.m3" trustHtml />
      </p>
      <Stamps />
    </div>
  </div>
);

export default Row3;
