import React from 'react';
import { number } from 'prop-types';
import { useSelector } from 'react-redux';
import { getLot } from '../../../redux/selectors/dbEntities';
import NoAccess from './NoAccess';
import Access from './Access';

const Lot = ({ id, position, analyticClickFrom, format = 'square', withSelectLot, urlTarget }) => {
  const lot = useSelector(state => getLot(state, { id }));

  if (!lot) return null;

  if (lot.hasAccess) return <Access format={format} lot={lot} position={position} analyticClickFrom={analyticClickFrom} withSelectLot={withSelectLot} urlTarget={urlTarget} />;
  return <NoAccess lot={lot} position={position} />;
};

Lot.propTypes = {
  id: number.isRequired,
};

export default Lot;
