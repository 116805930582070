import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MYARTPRICE_SUMMARY_FILTER, MYARTPRICE_SUMMARY_NEWS_ONLY, SHORTCUT_SUMMARY } from '../../../constants/myartprice';
import { useInitializeActiveShorcut } from '../../../hooks/myartprice/useInitializeActiveShorcut';
import { areDataLoading, getSortedArtists } from '../../../redux/selectors/myartprice/data';
import SummaryArtistsFilter from '../common/VerticalBar/Content/SummaryArtistsFilter';
import { get as getUiDictionary } from '../../../redux/selectors/ui/dictionary';
import { on as openDrawer } from '../../../redux/actions/ui/switches';
import ArtistAsyncSelect from '../Settings/Artists/ArtistAsyncSelect';
import { fetchSummary } from '../../../redux/actions/myartprice/data';
import { getSettings } from '../../../redux/selectors/myartprice';
import { useViewport } from '../../../hooks/useViewport';
import Spinner from '../../common/spinners/Spinner';
import VerticalBar from '../common/VerticalBar';
import MainLayout from '../common/MainLayout';
import '../common/styles/myartpriceBar.scss';
import NoResults from '../common/NoResults';
import useGtm from '../../../hooks/useGtm';
import '../common/styles/myartprice.scss';
import ArtistData from './ArtistData';
import './stylesheet.scss';

const Home = () => {
  const [sort, setSort] = useState('name_for_sorting');
  const [direction, setDirection] = useState('asc');
  const dispatch = useDispatch();
  const artistsList = useSelector(state => getSortedArtists(state, { sort, direction }));
  const { ended, futures, auctions, fixedPrice } = useSelector(getSettings);
  const filter = useSelector(state => getUiDictionary(state, { id: MYARTPRICE_SUMMARY_FILTER })) || '';
  const newDataOnly = useSelector(state => getUiDictionary(state, { id: MYARTPRICE_SUMMARY_NEWS_ONLY })) || false;
  const { viewportWidth } = useViewport();
  const loading = useSelector(areDataLoading);

  useInitializeActiveShorcut({ forcedShortcut: SHORTCUT_SUMMARY });

  useEffect(() => {
    dispatch(fetchSummary());
  }, []);

  const artistsListFiltered = () => {
    let artists;

    if (newDataOnly) {
      artists = artistsList.filter(artist => artist.new_past + artist.new_future + artist.new_marketplace + artist.future_all > 0);
    } else {
      artists = artistsList;
    }

    if (filter === '') {
      return artists;
    }

    const regex = new RegExp(`.*${filter}.*`, 'i');
    return artists.filter(artist => artist.search.match(regex));
  };

  const handleSort = sortOn => {
    if (sort === sortOn) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setSort(sortOn);
      if (sortOn === 'name_for_sorting') {
        setDirection('asc');
      } else {
        setDirection('desc');
      }
    }
  };

  // Breakcrumb
  useEffect(() => {
    const breadcrumb = document.querySelector('.breadcrumb');

    if (breadcrumb) {
      breadcrumb.classList.add('hidden-xs', 'hidden-sm');
      breadcrumb.innerHTML = `<li><a href="/">${i18next.t('common._marketplace_menu.html.m39')}</a></li> 
                              <li>${i18next.t('artprice.lib.myartprice.artists.m13')}</li> 
                              <li>${i18next.t('layouts.header.m16')}</li> 
                              <li>${i18next.t('components.myartprice.common.verticalbar.content.m2')}</li>`;
    }
  }, []);

  useGtm();

  const horizontalBarChildren = () => (
    <div className="container">
      <div className="filters-container">
        <button id="filter" type="button" onClick={() => dispatch(openDrawer('myartprice-vertical-bar'))}>
          {i18next.t('component.myartprice.lot.m9')} <i className="fa fa-caret-down" />
        </button>
      </div>
    </div>
  );

  return (
    <MainLayout horizontalBarChildren={horizontalBarChildren()}>
      <VerticalBar>
        <SummaryArtistsFilter />
      </VerticalBar>
      <div className="col-container">
        <div className="data-container">
          {(loading && <Spinner />) ||
            (artistsListFiltered().length > 0 && (
              <div className="table-responsive">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th className="sort-item header" style={{ borderBottom: 0, verticalAlign: 'middle' }} rowSpan={2}>
                        {i18next.t('components.myartprice.home.m10')}
                        <div className={`sortings ${sort === 'name_for_sorting' ? 'selected' : ''}`} onClick={() => handleSort('name_for_sorting')}>
                          {sort === 'name_for_sorting' && <i className={`fa fa-sort-amount-${direction}`} />}
                          {sort !== 'name_for_sorting' && <i className="fa fa-sort" />}
                        </div>
                        <ArtistAsyncSelect />
                      </th>
                      {futures && <th colSpan={viewportWidth >= 768 ? 2 : 1}>{i18next.t('components.myartprice.home.m11')}</th>}
                      {ended && <th colSpan={viewportWidth >= 768 ? 2 : 1}>{i18next.t('components.myartprice.home.m9')}</th>}
                      {(auctions || fixedPrice) && <th colSpan={viewportWidth >= 768 ? 2 : 1}>{i18next.t('components.myartprice.home.m12')}</th>}
                    </tr>
                    <tr>
                      {futures && (
                        <>
                          <th className="sort-item" onClick={() => handleSort('new_future')}>
                            {i18next.t('components.myartprice.home.m13')}
                            <div className={`sortings ${sort === 'new_future' ? 'selected' : ''}`}>
                              {sort === 'new_future' && <i className={`fa fa-sort-amount-${direction}`} />}
                              {sort !== 'new_future' && <i className="fa fa-sort" />}
                            </div>
                          </th>
                          <th className="sort-item hidden-xs" onClick={() => handleSort('future_all')}>
                            {i18next.t('components.myartprice.home.m14')}
                            <div className={`sortings ${sort === 'future_all' ? 'selected' : ''}`}>
                              {sort === 'future_all' && <i className={`fa fa-sort-amount-${direction}`} />}
                              {sort !== 'future_all' && <i className="fa fa-sort" />}
                            </div>
                          </th>
                        </>
                      )}
                      {ended && (
                        <>
                          <th className="sort-item" onClick={() => handleSort('new_past')}>
                            {i18next.t('components.myartprice.home.m13')}
                            <div className={`sortings ${sort === 'new_past' ? 'selected' : ''}`}>
                              {sort === 'new_past' && <i className={`fa fa-sort-amount-${direction}`} />}
                              {sort !== 'new_past' && <i className="fa fa-sort" />}
                            </div>
                          </th>
                          <th className="hidden-xs sort-item" onClick={() => handleSort('past_all')}>
                            {i18next.t('components.myartprice.home.m14')}
                            <div className={`sortings ${sort === 'past_all' ? 'selected' : ''}`}>
                              {sort === 'past_all' && <i className={`fa fa-sort-amount-${direction}`} />}
                              {sort !== 'past_all' && <i className="fa fa-sort" />}
                            </div>
                          </th>
                        </>
                      )}
                      {(auctions || fixedPrice) && (
                        <>
                          <th className="sort-item" onClick={() => handleSort('new_marketplace')}>
                            {i18next.t('components.myartprice.home.m13')}
                            <div className={`sortings ${sort === 'new_marketplace' ? 'selected' : ''}`}>
                              {sort === 'new_marketplace' && <i className={`fa fa-sort-amount-${direction}`} />}
                              {sort !== 'new_marketplace' && <i className="fa fa-sort" />}
                            </div>
                          </th>
                          <th className="sort-item hidden-xs" onClick={() => handleSort('marketplace_all')}>
                            {i18next.t('components.myartprice.home.m14')}
                            <div className={`sortings ${sort === 'marketplace_all' ? 'selected' : ''}`}>
                              {sort === 'marketplace_all' && <i className={`fa fa-sort-amount-${direction}`} />}
                              {sort !== 'marketplace_all' && <i className="fa fa-sort" />}
                            </div>
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {artistsListFiltered().map(artist => (
                      <ArtistData artistData={artist} key={`key-${artist.id}`} ended={ended} futures={futures} auctions={auctions} fixedPrice={fixedPrice} />
                    ))}
                  </tbody>
                </table>
              </div>
            )) || <NoResults messageRsxKey="home.artists.m3" linkRsxKey="component.myartprice.lot.m4" href="/search" />}
        </div>
      </div>
    </MainLayout>
  );
};

export default Home;
