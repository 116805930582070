import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';
import { getSale } from '../../../../redux/selectors/dbEntities';
import ProgressBar from './ProgessBar';
import './stylesheet.scss';
import { progress, push } from '../../../../redux/selectors/auctioneers/sales';
import Cover from '../../common/Cover';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { get } from '../../../../redux/selectors/ui/dictionary';
import { ACTIVE_AAPI_BEACON_FFROM } from '../../../../constants/auctioneers';
import { ANALYTICS_OPERATION_AUCTIONEERS_TO_CONTACT, ANALYTICS_OPERATION_AUCTIONEERS_TO_SALE, ANALYTICS_OPERATION_AUCTIONEERS_TO_SEND_DATA } from '../../../../constants/rails';

const Sale = ({ idsale }) => {
  const sale = useSelector(state => getSale(state, { id: idsale }));
  const pushCount = useSelector(state => push(state, { id: idsale }));
  const { datas, progressBar = false, img } = useSelector(state => progress(state, { id: idsale }));
  const from = useSelector(state => get(state, { id: ACTIVE_AAPI_BEACON_FFROM }));

  if (!sale) {
    return null;
  }

  return (
    <div className={`auctioneers-future-sales-sale ${progressBar ? '' : 'no-progress-bar'}`}>
      <div className="sale">
        <Cover sale={sale} />
        <div className="infos">
          <div className="title">
            {sale.fineart && (
              <Link onClick={() => aapiBeacon('click', { from, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_SALE, idsale })} to={`/auctioneer/control-center/sale?id=${sale.id}`}>
                {sale.title}
              </Link>
            )}
            {!sale.fineart && sale.title}
          </div>
          <div className="date">
            {sale.dtStart}
            {sale.dtStart !== sale.dtEnd && <span> - {sale.dtEnd} </span>}
          </div>
          <div className="location">
            {sale.city}
            {sale.country && sale.city && ', '}
            {sale.country}
          </div>
          <div className="fill-flex" />
          {pushCount > 0 && <div className="push">{i18next.t('auctioneers.futuresales.sale.m1').replace('%pushCount%', pushCount)}</div>}
          {!sale.fineart && <div className="no-fineart">{i18next.t('auctioneers.futuresales.sale.m2')}</div>}
        </div>
        <div className="stats-and-modify">
          {datas && (
            <div className="modify">
              <p>
                <Link to={`/auctioneer/control-center/contact/${sale.id}`} onClick={() => aapiBeacon('click', { from, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_CONTACT, idsale: sale.id })}>
                  <i className="fa fa-envelope-o marg marg-r-5" />
                  {i18next.t('auctioneers.futuresales.sale.m3')}
                </Link>
              </p>
            </div>
          )}
          {sale.fineart && progressBar && (
            <div className="stats">
              {!datas && (
                <p>
                  <Link to="/auctioneer/control-center/docs" onClick={() => aapiBeacon('click', { from, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_SEND_DATA, idsale: sale.id })}>
                    <i className="fa fa-exclamation-triangle marg marg-r-5" />
                    {i18next.t('auctioneers.futuresales.sale.m4')}
                  </Link>
                </p>
              )}

              {datas && (
                <p>
                  {!img && (
                    <Link to="/auctioneer/control-center/docs" onClick={() => aapiBeacon('click', { from, op: ANALYTICS_OPERATION_AUCTIONEERS_TO_SEND_DATA, idsale: sale.id })}>
                      <i className="fa fa-exclamation-triangle marg marg-r-5" />
                      {i18next.t('auctioneers.futuresales.sale.m5')}
                    </Link>
                  )}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      {sale.fineart && <ProgressBar idsale={idsale} />}
    </div>
  );
};

export default Sale;
