/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { updateDimensionPreference } from '../../../../../redux/actions/preferences';
import { getDimension } from '../../../../../redux/selectors/preferences';
import { SEARCH_DIMENSIONS } from '../../../../../constants/search';
import './stylesheet.scss';

const DisplayedDimensions = () => {
  const dispatch = useDispatch();
  const currentDimensionUnit = useSelector(getDimension);

  const handleClick = dimensionUnit => {
    dispatch(updateDimensionPreference({ dimension: dimensionUnit }));
  };

  return (
    <div className="display artp-input-group">
      <span className="artp-input-label">{i18next.t('marketplace.searchbars.classifieds.bar.display.m2')}</span>
      <div className="search-bar-input btn-group btn-group-sm">
        {SEARCH_DIMENSIONS.map(dimensionUnit => (
          <a key={dimensionUnit} className={`btn btn-default ${currentDimensionUnit === dimensionUnit ? 'active' : ''}`} onClick={() => handleClick(dimensionUnit)}>
            {dimensionUnit}
          </a>
        ))}
      </div>
    </div>
  );
};

export default DisplayedDimensions;
