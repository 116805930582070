import React, { memo } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { getAdvancedCount, getAdvancedParams } from '../../../../redux/slices/search/scoped';
import './stylesheet.scss';

const DEFAULT_RSX = {
  initial: 'components.search.helpers.count.m1',
  noResult: 'components.search.helpers.count.m2',
  oneResult: 'components.search.helpers.count.m3',
  multipleResults: 'components.search.helpers.count.m4',
};

const Count = ({ searchScope, rsx = DEFAULT_RSX }) => {
  const count = useSelector(state => getAdvancedCount(state, { searchScope }));
  const params = useSelector(state => getAdvancedParams(state, { searchScope }));

  return (
    <div className="searchbar-count">
      {count !== undefined && (
        <>
          {isEmpty(params) && i18next.t(rsx.initial || DEFAULT_RSX.initial).replace('%count%', count)}
          {!isEmpty(params) && (
            <>
              {count > 1 && i18next.t(rsx.multipleResults || DEFAULT_RSX.multipleResults).replace('%count%', count)}
              {count === 1 && i18next.t(rsx.oneResult || DEFAULT_RSX.oneResult).replace('%count%', 1)}
              {count === 0 && i18next.t(rsx.noResult || DEFAULT_RSX.noResult).replace('%count%', 0)}
            </>
          )}
        </>
      )}
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.searchScope === nextProps.searchScope && isEqual(prevProps.rsx, nextProps.rsx);
}

const MemoizedCount = memo(Count, areEqual);

export default MemoizedCount;
