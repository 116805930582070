import i18next from 'i18next';

export const MARKETPLACE_DEFAULT = {
  'sort_dt-desc': i18next.t('constants.marketplace.searchbars.m3'),
  'price_sorted_eur-asc': `${i18next.t('marketplace.home.index.m7')} (${i18next.t('javascript.constants.m3')})`,
  'price_sorted_eur-desc': `${i18next.t('marketplace.home.index.m7')} (${i18next.t('javascript.constants.m4')})`,
};

export const SALES_DEFAULT = {
  title_asc: i18next.t('artprice.ps.artitems.m11'),
  title_desc: i18next.t('artprice.ps.artitems.m12'),
  datesale_asc: i18next.t('artprice.ps.artitems.m13'),
  datesale_desc: i18next.t('artprice.ps.artitems.m14'),
};

export const MARKETPLACE_WITH_KEYWORD = {
  best_match: `${i18next.t('artprice.artistsearch.m21')}`,
};

export const MARKETPLACE_AUCTIONS = {
  'end_auction_at-asc': `${i18next.t('constants.marketplace.searchbars.m2')}`,
};

export const ARTISTS = {
  score_desc: i18next.t('artprice.artistsearch.m21'),
  lastname_asc: i18next.t('artists.header_search.m3'),
  lastname_desc: i18next.t('artists.header_search.m4'),
  firstname_asc: i18next.t('artists.header_search.m5'),
  firstname_desc: i18next.t('artists.header_search.m6'),
};

export const LOTS = {
  score_desc: i18next.t('artprice.artistsearch.m21'),
  title_asc: i18next.t('artprice.ps.artitems.m11'),
  title_desc: i18next.t('artprice.ps.artitems.m12'),
  datelot_asc: i18next.t('artprice.ps.artitems.m17'),
  datelot_desc: i18next.t('artprice.ps.artitems.m18'),
  estimation_asc: i18next.t('lots.header_search.m8'),
  estimation_desc: i18next.t('lots.header_search.m9'),
  surface_asc: i18next.t('lots.header_search.m14'),
  surface_desc: i18next.t('lots.header_search.m15'),
  datesale_asc: i18next.t('artprice.ps.artitems.m13'),
  datesale_desc: i18next.t('artprice.ps.artitems.m14'),
  number_asc: i18next.t('sales.show.m1'),
  number_desc: i18next.t('sales.show.m2'),
  price_asc: i18next.t('artprice.ps.artitems.m9'),
  price_desc: i18next.t('artprice.ps.artitems.m10'),
};

export const LOTS_HISTORY = {
  ...LOTS,
  ...{ score_desc: i18next.t('myartprice.history.html.m1') },
};

export const LOTS_FUTURE = {
  score_desc: i18next.t('artprice.artistsearch.m21'),
  title_asc: i18next.t('artprice.ps.artitems.m11'),
  title_desc: i18next.t('artprice.ps.artitems.m12'),
  datelot_asc: i18next.t('artprice.ps.artitems.m17'),
  datelot_desc: i18next.t('artprice.ps.artitems.m18'),
  estimation_asc: i18next.t('lots.header_search.m8'),
  estimation_desc: i18next.t('lots.header_search.m9'),
  surface_asc: i18next.t('lots.header_search.m14'),
  surface_desc: i18next.t('lots.header_search.m15'),
  datesale_asc: i18next.t('artprice.ps.artitems.m13'),
  datesale_desc: i18next.t('artprice.ps.artitems.m14'),
};

export const SALE_LOTS = {
  score_desc: i18next.t('artprice.artistsearch.m21'),
  title_asc: i18next.t('artprice.ps.artitems.m11'),
  title_desc: i18next.t('artprice.ps.artitems.m12'),
  datelot_asc: i18next.t('artprice.ps.artitems.m17'),
  datelot_desc: i18next.t('artprice.ps.artitems.m18'),
  estimation_asc: i18next.t('lots.header_search.m8'),
  estimation_desc: i18next.t('lots.header_search.m9'),
  surface_asc: i18next.t('lots.header_search.m14'),
  surface_desc: i18next.t('lots.header_search.m15'),
  number_asc: i18next.t('sales.show.m1'),
  number_desc: i18next.t('sales.show.m2'),
};

export const AUCTIONEER_SALE_LOTS = {
  score_desc: i18next.t('artprice.artistsearch.m21'),
  estimation_asc: i18next.t('lots.header_search.m8'),
  estimation_desc: i18next.t('lots.header_search.m9'),
  number_asc: i18next.t('sales.show.m1'),
  number_desc: i18next.t('sales.show.m2'),
  price_asc: i18next.t('artprice.ps.artitems.m9'),
  price_desc: i18next.t('artprice.ps.artitems.m10'),
};

export const MYARTPRICE_LOTS_FUTURE = {
  estimation_asc: i18next.t('lots.header_search.m8'),
  estimation_desc: i18next.t('lots.header_search.m9'),
  datesale_asc: i18next.t('artprice.ps.artitems.m13'),
  datesale_desc: i18next.t('artprice.ps.artitems.m14'),
};

export const MYARTPRICE_LOTS_AUCTIONED_OFF = {
  price_asc: i18next.t('artprice.ps.artitems.m9'),
  price_desc: i18next.t('artprice.ps.artitems.m10'),
  datesale_asc: i18next.t('artprice.ps.artitems.m13'),
  datesale_desc: i18next.t('artprice.ps.artitems.m14'),
};

export const MYARTPRICE_LOTS_FAVORITE = {
  score_desc: i18next.t('artprice.artistsearch.m21'),
  price_asc: i18next.t('artprice.ps.artitems.m9'),
  price_desc: i18next.t('artprice.ps.artitems.m10'),
  datesale_asc: i18next.t('artprice.ps.artitems.m13'),
  datesale_desc: i18next.t('artprice.ps.artitems.m14'),
};
