import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPhysicproducts } from '../../../redux/actions/subscriptions/physicproduct';
import { getTheme } from '../../../redux/selectors/subscriptions/physicproducts';
import DOMView from '../../common/observers/DOMView';
import {
  ANALYTICS_FROM_LANDINGPAGES_SERVICES,
  ANALYTICS_SECTION_SCROLL_0,
  ANALYTICS_SECTION_SCROLL_100,
  ANALYTICS_SECTION_SCROLL_25,
  ANALYTICS_SECTION_SCROLL_50,
  ANALYTICS_SECTION_SCROLL_75,
  THEME_PREMIUM,
} from '../../../constants/rails';
import Header from './Header';
import Legals from './Legals';
import './stylesheet.scss';
import Row1 from './Row1';
import Row2 from './Row2';
import Row3 from './Row3';
import Row4 from './Row4';
import Row5 from './Row5';
import Row6 from './Row6';
import Row7 from './Row7';
import Row8 from './Row8';

const Services = () => {
  const dispatch = useDispatch();
  const physicproducts = useSelector(state => getTheme(state, { idtheme: THEME_PREMIUM }));
  const [prices, setPrices] = useState({
    originalPrice: { eur: 53, usd: 53 },
    price: { eur: 27, usd: 27 },
  });

  useEffect(() => {
    dispatch(fetchPhysicproducts({ image: true, promocode: 'apweb' }));
  }, []);

  useEffect(() => {
    if (physicproducts) {
      const physicproduct = physicproducts.physicproducts.filter(p => p.original_shortened_nbdays === 30)[0];

      if (physicproduct) {
        setPrices({ price: physicproduct.price, originalPrice: physicproduct.original_prices });
      }
    }
  }, [physicproducts]);

  return (
    <>
      <div id="x-0">
        <DOMView from={ANALYTICS_FROM_LANDINGPAGES_SERVICES} section={ANALYTICS_SECTION_SCROLL_0} selector="#x-0" />
      </div>
      <Header />
      <Row1 />
      <Row2 prices={prices} />
      <div id="x-25">
        <DOMView from={ANALYTICS_FROM_LANDINGPAGES_SERVICES} section={ANALYTICS_SECTION_SCROLL_25} selector="#x-25" />
      </div>
      <Row3 />
      <Row4 />
      <div id="x-50">
        <DOMView from={ANALYTICS_FROM_LANDINGPAGES_SERVICES} section={ANALYTICS_SECTION_SCROLL_50} selector="#x-50" />
      </div>
      <Row5 />
      <Row6 />
      <div id="x-75">
        <DOMView from={ANALYTICS_FROM_LANDINGPAGES_SERVICES} section={ANALYTICS_SECTION_SCROLL_75} selector="#x-75" />
      </div>
      <Row7 />
      <Row8 />
      <div id="x-100">
        <DOMView from={ANALYTICS_FROM_LANDINGPAGES_SERVICES} section={ANALYTICS_SECTION_SCROLL_100} selector="#x-100" />
      </div>
      <Legals prices={prices} />
    </>
  );
};

export default Services;
