import React, { Component } from 'react';
import { string, object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Col, Card, CardImg, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { fetchCountryLabelsAction, fetchThumbnailAction } from '../../../../redux/actions/events';
import { getCountryLabel, areCountryLabelsLoading as areCountryLabelsLoadingSelector, getFormatMediumLink } from '../../../../redux/selectors/events';
import Spinner from '../../../common/spinners/Spinner';
import { formatFns } from '../../../../utils/dates/format';
import './stylesheet.scss';

class EventItem extends Component {
  componentDidMount() {
    const { fetchCountryLabels, fetchWPThumbnail, event } = this.props;
    const { slug, idcountry } = event;

    fetchCountryLabels(idcountry);
    fetchWPThumbnail(slug);
  }

  render() {
    const { event, thumbnailLink, country, areCountryLabelsLoading } = this.props;

    if (!event) return null;
    const { id, title, slug, dt_event_start: dtEventStart, dt_event_expire: dtEventExpire } = event;
    const countryLabel = (areCountryLabelsLoading ? <Spinner /> : country) || '';

    return (
      <Col sm={6} md={4} className="exposition-block col-xs-12 marg marg-b-15">
        <Link to={`/events/${id}/${title.replace(/[/#.\s]/gi, '-')}/`}>
          <Card>
            <div className="image-container">
              <div className="justify-content-center marg marg-b-15">{slug && thumbnailLink && <CardImg className="event-thumbnail" top src={thumbnailLink} alt="" />}</div>
            </div>
            <CardBody>
              <CardTitle>
                <h2 className="marg marg-t-0 marg-b-0">
                  <small>
                    <span>{title}</span>
                  </small>
                </h2>
              </CardTitle>
              <CardSubtitle>
                <h3 className="marg marg-t-0 marg-b-0">
                  <small>
                    <div className="nowrap">
                      <span>{countryLabel}</span>
                    </div>
                    <div className="nowrap">
                      <span>
                        ({formatFns(dtEventStart, 'P', { ignoreTZ: true })}
                        {' - '}
                        {formatFns(dtEventExpire, 'P', { ignoreTZ: true })})
                      </span>
                    </div>
                  </small>
                </h3>
              </CardSubtitle>
            </CardBody>
          </Card>
        </Link>
      </Col>
    );
  }
}

EventItem.defaultProps = {
  thumbnailLink: '',
  country: '',
  areCountryLabelsLoading: false,
};

EventItem.propTypes = {
  thumbnailLink: string,
  country: string,
  areCountryLabelsLoading: bool,
  // eslint-disable-next-line
  event: object.isRequired,

  fetchCountryLabels: func.isRequired,
  fetchWPThumbnail: func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { event: { idcountry, slug } = {} } = ownProps;
  return {
    thumbnailLink: getFormatMediumLink(state, { slug }),
    country: getCountryLabel(state, { idcountry }),
    areCountryLabelsLoading: areCountryLabelsLoadingSelector(state, { idcountry }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCountryLabels: idcountry => dispatch(fetchCountryLabelsAction({ idcountry })),
    fetchWPThumbnail: slug => dispatch(fetchThumbnailAction({ slug })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventItem);
