import React from 'react';
import './stylesheet.scss';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION, ANALYTICS_FROM_LANDINGPAGES_SERVICES } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { PROMO_IDPHYSICPRODUCT, PROMOCODE } from '../constant';
import A18n from '../../../common/A18n';
import { getCurrency } from '../../../../redux/selectors/preferences';

const Row8 = () => {
  const intl = useIntl();
  const currency = useSelector(getCurrency);

  return (
    <div className="x-landingpage-x-row8">
      <div className="container">
        <h2>
          <A18n rsx="artprice.landingpages.services.row8.m1" />
        </h2>
        <h3>
          <A18n rsx="artprice.landingpages.services.row8.m2" />
        </h3>
        <p>
          <A18n rsx="artprice.landingpages.services.row8.m3" trustHtml />
        </p>
        <p>
          <a
            href={`/payments/informations?image=true&idphysicproducts[${PROMO_IDPHYSICPRODUCT}]=1&type=new&promocode=${PROMOCODE}`}
            className="artp-btn artp-btn-premium"
            onClick={() =>
              aapiBeacon('click', {
                from: ANALYTICS_FROM_LANDINGPAGES_SERVICES,
                op: ANALYTICS_CLICK_OPERATION_TO_SUBSCRIPTION,
                section: 'row-8',
              })
            }
          >
            <A18n rsx="artprice.landingpages.services.row8.m4" />
          </a>
        </p>
        <p>
          <A18n
            rsx="artprice.landingpages.services.row8.m5"
            trustHtml
            replace={[
              ['%price%', intl.formatNumber(9, { style: 'currency', currency, maximumFractionDigits: 0 })],
              ['%price-per-day%', intl.formatNumber(1, { style: 'currency', currency, maximumFractionDigits: 0 })],
            ]}
          />
        </p>
      </div>
    </div>
  );
};
export default Row8;
