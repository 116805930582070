import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getCart, updateNavigationStep } from '../../../redux/slices/payments';
import { getUserContext } from '../../../redux/selectors/userContext';
import AvailablePayments from '../common/AvailablePayments';
import Spinner from '../../common/spinners/Spinner';
import CartSummary from '../common/CartSummary';
import A18n from '../../common/A18n';
import Help from '../common/Help';
import Form from '../common/Form';
import '../common/layout.scss';
import './stylesheet.scss';

const Proforma = () => {
  const dispatch = useDispatch();
  const { idphysicsale } = useParams();
  const { logged, idcustomer } = useSelector(getUserContext);
  const { status } = useSelector(getCart);

  useEffect(() => {
    dispatch(updateNavigationStep(null));
    if (!idcustomer || !logged) {
      // window.location = '/identity';
    }
  }, [idcustomer, logged]);

  useEffect(() => {
    if (status === 'ok') {
      dispatch(updateNavigationStep(1));
    }
  }, [status]);

  const handleSuccess = () => {
    window.location = `/payments/platforms?idphysicsale=${idphysicsale}`;
  };

  // not loaded yet
  if (status === undefined) {
    return (
      <div className="payments-informations payments-grid-layout">
        <div className="container">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="payments-informations payments-grid-layout">
      <div className="container">
        {status === 'ok' ? (
          <div className="container-grid">
            <div className="left">
              <h1>
                {!idcustomer && <A18n rsx="payments.informations.m1" />}
                {idcustomer && <A18n rsx="payments.informations.m2" />}
              </h1>
              <Form onSuccess={handleSuccess} />
              <div className="help-on-mobile">
                <Help />
                <AvailablePayments />
              </div>
            </div>
            <div className="right">
              <CartSummary idphysicsale={idphysicsale} />
            </div>
          </div>
        ) : (
          <div className="bad-proforma">
            <div className="message">
              {status === 'obsolete' && (
                <div>
                  <i className="fa fa-hourglass-end" /> <A18n rsx="payments.proforma.m1" />
                </div>
              )}
              {status === 'offline' && (
                <div>
                  <i className="fa fa-times" /> <A18n rsx="payments.proforma.m3" />
                </div>
              )}
              {!['obsolete', 'offline'].includes(status) && (
                <div>
                  <i className="fa fa-times" /> <A18n rsx="payments.proforma.m2" />
                </div>
              )}
            </div>
            <div className="help">
              <Help location={`/account/clientmessages?privatemessage%5Bidprivatemessagetype%5D=15&privatemessage%5Bsubject%5D=Paiement+de+la+commande+%23${idphysicsale}+&sub_tab=new`} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Proforma;
