import React from 'react';
import { string } from 'prop-types';
import { IntlProvider } from 'react-intl';
import { CookiesProvider } from 'react-cookie';
import { SHORTCUT_SETTINGS_SECTIONS, SHORTCUT_SETTINGS_CATEGORIES, SHORTCUT_SETTINGS_ARTISTS } from '../../constants/myartprice';
import ResolveListToDisplay from './ResolveLots';
import RouterProvider from '../providers/RouterProvider';
import ReduxProvider from '../providers/ReduxProvider';
import { GTM_MYARTPRICE } from '../../constants/rails';
import ResolveArtists from './ResolveArtists';
import initializeGtm from '../../utils/gtm';
import Viewport from '../common/Viewport';
import Marketplace from './Marketplace';
import {
  MYARTPRICE_LOTS_AUCTIONED_OFF,
  MYARTPRICE_LOTS_FUTURE_ALL,
  MYARTPRICE_LOTS_FUTURE_NEW,
  MYARTPRICE_LOTS_FOLLOW_PAST,
  MYARTPRICE_LOTS_FOLLOW_FUTURE,
  MYARTPRICE_LOTS_MARKETPLACE,
  MYARTPRICE_MARKETPLACE,
  MYARTPRICE_LOTS_FOLLOW_MARKETPLACE,
} from '../../constants/search/scope';
import Settings from './Settings';
import Summary from './Summary';
import Lots from './lots/List';
import Lot from './lots/Show';
import Store from './Store';
import './stylesheet.scss';

initializeGtm({ pageCategory: GTM_MYARTPRICE });

const ProvidedMyartprice = ({ locale = 'en' }) => (
  <div className="myartprice">
    <CookiesProvider>
      <IntlProvider locale={locale}>
        <ReduxProvider>
          <Viewport />
          <RouterProvider
            routes={[
              {
                path: '/myartprice',
                children: [
                  {
                    path: 'store',
                    element: <Store />,
                  },
                  {
                    path: 'marketplace',
                    element: <Marketplace searchScope={MYARTPRICE_MARKETPLACE} />,
                  },
                  {
                    path: 'settings',
                    children: [
                      {
                        path: 'sections',
                        element: <Settings activeTab={SHORTCUT_SETTINGS_SECTIONS} />,
                      },
                      {
                        path: 'categories',
                        element: <Settings activeTab={SHORTCUT_SETTINGS_CATEGORIES} />,
                      },
                      {
                        path: 'artists',
                        element: <Settings activeTab={SHORTCUT_SETTINGS_ARTISTS} />,
                      },
                    ],
                  },
                  {
                    path: 'artists',
                    children: [
                      {
                        path: 'auto',
                        element: <ResolveArtists />,
                      },
                      {
                        path: 'lots-futures',
                        children: [
                          {
                            path: 'new',
                            element: <Lots searchScope={MYARTPRICE_LOTS_FUTURE_NEW} />,
                          },
                          {
                            path: 'new/:idlot/:crypto',
                            element: <Lot searchScope={MYARTPRICE_LOTS_FUTURE_NEW} />,
                          },
                          {
                            path: 'all',
                            element: <Lots searchScope={MYARTPRICE_LOTS_FUTURE_ALL} />,
                          },
                          {
                            path: 'all/:idlot/:crypto',
                            element: <Lot searchScope={MYARTPRICE_LOTS_FUTURE_ALL} />,
                          },
                          {
                            path: ':idlot/:crypto',
                            element: <Lot />,
                          },
                          {
                            path: '',
                            element: <Lots searchScope={MYARTPRICE_LOTS_FUTURE_ALL} />,
                          },
                        ],
                      },
                      {
                        path: 'lots-auctioned-off',
                        children: [
                          {
                            path: ':idlot/:crypto',
                            element: <Lot past searchScope={MYARTPRICE_LOTS_AUCTIONED_OFF} />,
                          },
                          {
                            path: '',
                            element: <Lots searchScope={MYARTPRICE_LOTS_AUCTIONED_OFF} />,
                          },
                        ],
                      },
                      {
                        path: 'marketplace',
                        element: <Marketplace searchScope={MYARTPRICE_LOTS_MARKETPLACE} />,
                      },
                      {
                        path: ':idlot/:crypto',
                        element: <Lot />,
                      },
                      {
                        path: '',
                        element: <Summary />,
                      },
                    ],
                  },
                  {
                    path: 'lots',
                    children: [
                      {
                        path: 'futures',
                        children: [
                          {
                            path: ':idlot/:crypto',
                            element: <Lot searchScope={MYARTPRICE_LOTS_FOLLOW_FUTURE} />,
                          },
                          {
                            path: '',
                            element: <Lots searchScope={MYARTPRICE_LOTS_FOLLOW_FUTURE} />,
                          },
                        ],
                      },
                      {
                        path: 'auctioned-off',
                        children: [
                          {
                            path: ':idlot/:crypto',
                            element: <Lot past searchScope={MYARTPRICE_LOTS_FOLLOW_PAST} />,
                          },
                          {
                            path: '',
                            element: <Lots searchScope={MYARTPRICE_LOTS_FOLLOW_PAST} />,
                          },
                        ],
                      },
                      {
                        path: 'marketplace',
                        element: <Marketplace searchScope={MYARTPRICE_LOTS_FOLLOW_MARKETPLACE} />,
                      },
                      {
                        path: ':idlot/:crypto',
                        element: <Lot />,
                      },
                      {
                        path: '',
                        element: <ResolveListToDisplay />,
                      },
                    ],
                  },
                  {
                    path: 'lots-auctioned-off',
                    element: <Lots searchScope={MYARTPRICE_LOTS_AUCTIONED_OFF} />,
                  },
                  {
                    path: '',
                    element: <Summary />,
                  },
                ],
              },
            ]}
          />
        </ReduxProvider>
      </IntlProvider>
    </CookiesProvider>
  </div>
);

ProvidedMyartprice.propTypes = {
  locale: string.isRequired,
};

export default ProvidedMyartprice;
