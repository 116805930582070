import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get as getUiDictionary } from '../../../redux/selectors/ui/dictionary';
import {
  SHORTCUT_LOTS_AUCTIONED_OFF,
  SHORTCUT_LOTS_FOLLOW_FUTURE,
  SHORTCUT_LOTS_FOLLOW_MARKETPLACE,
  SHORTCUT_LOTS_FOLLOW_PAST,
  SHORTCUT_LOTS_FUTURE_ALL,
  SHORTCUT_LOTS_FUTURE_NEW,
  SHORTCUT_LOTS_MARKETPLACE,
  VERTICAL_BAR_CURRENT_ELEMENT_KEY,
} from '../../../constants/myartprice';
import { set } from '../../../redux/actions/ui/dictionary';
import {
  MYARTPRICE_LOTS_AUCTIONED_OFF,
  MYARTPRICE_LOTS_FOLLOW_FUTURE,
  MYARTPRICE_LOTS_FOLLOW_MARKETPLACE,
  MYARTPRICE_LOTS_FOLLOW_PAST,
  MYARTPRICE_LOTS_FUTURE_ALL,
  MYARTPRICE_LOTS_FUTURE_NEW,
  MYARTPRICE_LOTS_MARKETPLACE,
} from '../../../constants/search/scope';
import { getSearchScope } from '../../../redux/selectors/myartprice/data';

const mapScopeToSchortcut = {
  [MYARTPRICE_LOTS_FUTURE_ALL]: SHORTCUT_LOTS_FUTURE_ALL,
  [MYARTPRICE_LOTS_FUTURE_NEW]: SHORTCUT_LOTS_FUTURE_NEW,
  [MYARTPRICE_LOTS_FOLLOW_FUTURE]: SHORTCUT_LOTS_FOLLOW_FUTURE,
  [MYARTPRICE_LOTS_FOLLOW_PAST]: SHORTCUT_LOTS_FOLLOW_PAST,
  [MYARTPRICE_LOTS_AUCTIONED_OFF]: SHORTCUT_LOTS_AUCTIONED_OFF,
  [MYARTPRICE_LOTS_MARKETPLACE]: SHORTCUT_LOTS_MARKETPLACE,
  [MYARTPRICE_LOTS_FOLLOW_MARKETPLACE]: SHORTCUT_LOTS_FOLLOW_MARKETPLACE,
};

export function useInitializeActiveShorcut({ forcedShortcut } = {}) {
  const dispatch = useDispatch();
  const currentElement = useSelector(state => getUiDictionary(state, { id: VERTICAL_BAR_CURRENT_ELEMENT_KEY }), shallowEqual);
  const searchscope = useSelector(getSearchScope);

  useEffect(() => {
    if (forcedShortcut) {
      dispatch(set(VERTICAL_BAR_CURRENT_ELEMENT_KEY, forcedShortcut));
    } else {
      dispatch(set(VERTICAL_BAR_CURRENT_ELEMENT_KEY, mapScopeToSchortcut[searchscope]));
    }
  }, [searchscope, forcedShortcut]);

  return { currentElement };
}
