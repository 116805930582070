import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import { getArtist, getCategoriesPast, getLotsPast } from '../../../../../redux/slices/artist';
import { hasModuleAccess, isLogged } from '../../../../../redux/slices/userContext/selectors';
import {
  ANALYTICS_SECTION_ARTIST_PAST_LOTS,
  ID_FREE_ARTIST,
  MODULE_INDEXES,
  MODULE_PASTSALE,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST_BY_CATEGORY,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST,
  ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST_NOT_SIGNED,
} from '../../../../../constants/rails';
import { useViewport } from '../../../../../hooks/useViewport';
import '../../../../common/stylesheets/buttons.scss';
import { UiContext } from '../../common/UiProvider';
import A18n from '../../../../common/A18n';
import Scroll from '../../common/Scroll';
import Categories from '../Categories';
import Flip from '../../common/Flip';
import Fake from './Fake';
import Lot from './Lot';

const Past = ({ onOpenDrawer, style = {} }) => {
  const { viewportWidth } = useViewport();
  const lots = useSelector(getLotsPast);
  const intl = useIntl();
  const artist = useSelector(getArtist);
  const logged = useSelector(isLogged);
  const categories = useSelector(getCategoriesPast);
  const [urlSeeAll, setUrlSeeAll] = useState();
  const hasPastSaleAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_PASTSALE }));
  const hasIndexesAccess = useSelector(state => hasModuleAccess(state, { idmodule: MODULE_INDEXES }));
  const [noIndexesAccess, setNoIndexesAccess] = useState(false);
  const [noAccess, setNoAccess] = useState(false);
  const [lotCount, setLotCount] = useState(0);
  const [displayLock, setDisplayLock] = useState(false);
  const { lotsPastRef } = useContext(UiContext);

  useEffect(() => {
    if (categories) {
      setLotCount(categories.reduce((nb, c) => nb + c.count, 0));
    }
  }, [categories]);

  useEffect(() => {
    if (artist.id === ID_FREE_ARTIST) {
      setDisplayLock(false);
    } else {
      setDisplayLock(!logged || !hasPastSaleAccess);
    }
  }, [artist?.id, logged, hasPastSaleAccess]);

  useEffect(() => {
    setNoIndexesAccess(!hasIndexesAccess);
    setNoAccess(!hasPastSaleAccess);
  }, [hasPastSaleAccess, hasIndexesAccess]);

  useEffect(() => {
    if (lotCount === 0 && lots.notSignedCount > 0) {
      setUrlSeeAll(lots.notSignedUrl);
    } else {
      setUrlSeeAll(lots.url);
    }
  }, [lotCount, lots.notSignedCount]);

  const handleOpenDrawer = ({ section, op, e }) => {
    onOpenDrawer({ section, op, period: 'past', e });
  };

  return (
    <div className="step-past" style={{ position: 'relative', ...style }} ref={lotsPastRef}>
      <div className="container">
        <h2>
          <A18n rsx="artists.artist.lots.past.m1" />
        </h2>
        <p className="sub-h2">
          {isEmpty(artist.groupMembers) && (
            <>
              {lots.notSignedCount === 0 && (
                <>
                  {lotCount > 1 && (
                    <A18n
                      rsx="artists.artist.lots.past.m2"
                      replace={[
                        ['%artist%', artist.name],
                        ['%nb%', intl.formatNumber(lotCount)],
                      ]}
                    />
                  )}
                  {lotCount === 1 && <A18n rsx="artists.artist.lots.past.m3" replace={[['%artist%', artist.name]]} />}
                </>
              )}
              {lots.notSignedCount > 0 && (
                <A18n
                  rsx={lots.notSignedCount + lotCount === 1 ? 'artists.artist.lots.past.m5' : 'artists.artist.lots.past.m4'}
                  replace={[
                    ['%nb%', intl.formatNumber(lotCount + lots.notSignedCount)],
                    ['%artist%', artist.name],
                    ['%a%', lotCount === 0 ? `<a class="styled" href="${lots.notSignedUrl}">` : ''],
                    ['%/a%', lotCount === 0 ? `</a>` : ''],
                  ]}
                  trustHtml
                />
              )}
            </>
          )}
          {!isEmpty(artist.groupMembers) && (
            <>
              {lotCount > 1 && (
                <A18n
                  rsx="artists.artist.lots.past.m13"
                  replace={[
                    ['%artist%', artist.name],
                    ['%nb%', intl.formatNumber(lotCount)],
                  ]}
                />
              )}
              {lotCount === 1 && <A18n rsx="artists.artist.lots.past.m14" replace={[['%artist%', artist.name]]} />}
            </>
          )}
        </p>

        {!isEmpty(categories) && (
          <Categories
            period="past"
            categories={categories}
            lots={lots}
            clickOnCategories={e => handleOpenDrawer({ op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST_BY_CATEGORY, e })}
            clickOnNotSigned={e => handleOpenDrawer({ op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST_NOT_SIGNED, e })}
          />
        )}

        {isEmpty(categories) && (
          <p className="sub-h2">
            <A18n rsx={isEmpty(artist.groupMembers) ? 'artists.artist.lots.past.m11' : 'artists.artist.lots.past.m15'} replace={[['%artist%', artist.name]]} />
          </p>
        )}
      </div>

      {!isEmpty(lots.items) && (
        <Scroll
          aapiBeaconSection={ANALYTICS_SECTION_ARTIST_PAST_LOTS}
          containerClassname="lots"
          renderComplement={() => {
            if (lotCount === 1) return null;

            return (
              <a
                id="sln_ps"
                href={urlSeeAll}
                className="artp-btn artp-btn-artist"
                onClick={e => handleOpenDrawer({ section: ANALYTICS_SECTION_ARTIST_PAST_LOTS, op: ANALYTICS_CLICK_OPERATION_TO_ARTIST_PAST_LOTS_LIST, e })}
              >
                {isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.lots.past.m6" />}
                {!isEmpty(artist.groupMembers) && <A18n rsx="artists.artist.lots.past.m12" />}
              </a>
            );
          }}
        >
          {lots.items.map(lot => {
            if (displayLock) {
              return (
                <Flip key={`flip-lot-${lot.id}`} style={{ width: '100%', height: 390 }} rotate={viewportWidth >= 768 && ((artist.macro && !noAccess && noIndexesAccess) || noAccess)}>
                  <div>
                    <Lot lot={lot} handleOpenDrawer={handleOpenDrawer} displayLock />
                  </div>
                  <Fake noIndexesAccess={noIndexesAccess} noAccess={noAccess} lotCount={lotCount} />
                </Flip>
              );
            }

            return <Lot key={`flip-lot-${lot.id}`} lot={lot} handleOpenDrawer={handleOpenDrawer} />;
          })}
          {(viewportWidth < 768 || isEmpty(lots.items)) && <Fake noIndexesAccess={noIndexesAccess} noAccess={noAccess} lotCount={lotCount} />}
        </Scroll>
      )}
    </div>
  );
};

export default Past;
