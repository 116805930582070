import React from 'react';
import TechniqueSmallView from '../../../common/dbEntitiesSmallViews/Technique';

const ClassifiedTile = ({ classified, url, img, location }) => (
  <div className="lot-similar">
    <div className="description">
      <div className="header">Marketplace</div>
      <div className="img">
        <a className="link-to" target="_blank" rel="noopener noreferrer" href={url} title="">
          <img alt="" src={img} />
        </a>
      </div>

      <div className="link">
        <a className="link-to" target="_blank" rel="noopener noreferrer" href={url}>
          {classified.title}
        </a>
      </div>
      <div className="technique">
        <TechniqueSmallView id={classified.idtechnique} />
      </div>
      <div className="location">{location}</div>
    </div>
  </div>
);

export default ClassifiedTile;
