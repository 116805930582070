/* eslint-disable global-require */
module.exports = [
  ...require('./Header/i18n'),
  ...require('./Legals/i18n'),
  ...require('./Row1/i18n'),
  ...require('./Row2/i18n'),
  ...require('./Row3/i18n'),
  ...require('./Row4/i18n'),
  ...require('./Row5/i18n'),
  ...require('./Row6/i18n'),
  ...require('./Row7/i18n'),
  ...require('./Row8/i18n'),
];
