import { get, isEmpty } from 'lodash';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areBaseFacetsLoading, getAdvancedFacets, getAdvancedParams, getBaseFacets, updateAdvancedParams } from '../../../../../../../../redux/slices/search/scoped';
import { getSearchScope } from '../../../../../../../../redux/selectors/myartprice/data';
import { usePaginatedSearch } from '../../../../../../../../hooks/myartprice/usePaginatedSearch';
import { updateUrlQuery } from '../../../../../../../../utils/http';

const FilterOption = ({ facet, facetName, onFilterClick }) => {
  const [selected, setSelected] = useState(false);
  const searchScope = useSelector(getSearchScope);
  const loading = useSelector(state => areBaseFacetsLoading(state, { searchScope }));
  const advancedFacets = useSelector(state => getAdvancedFacets(state, { searchScope }));
  const advancedParams = useSelector(state => getAdvancedParams(state, { searchScope }));
  const baseFacets = useSelector(state => getBaseFacets(state, { searchScope }));
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [displayBaseCount, setdisplayBaseCount] = useState(false);

  const { fetch } = usePaginatedSearch({ scope: searchScope });

  useEffect(() => {
    setSelected(parseInt(get(advancedParams, facetName, 0), 10) === facet?.id);

    if (get(advancedFacets, `${facetName}.${facet.id}.count`)) {
      setCount(get(advancedFacets, `${facetName}.${facet.id}.count`));
      setdisplayBaseCount(false);
    } else if (get(baseFacets, `${facetName}.${facet.id}.count`)) {
      setdisplayBaseCount(true);

      const isAdvancedParams = !isEmpty(
        Object.keys(advancedParams)
          .filter(key => facetName !== key)
          .reduce((obj, key) => ({ ...obj, [key]: advancedParams[key] }), {}),
      );

      if (isAdvancedParams) {
        setCount(0);
      } else {
        setCount(get(baseFacets, `${facetName}.${facet.id}.count`));
      }
    } else {
      setdisplayBaseCount(false);
      setCount(0);
    }
  }, [advancedFacets, facet, facetName, advancedParams]);

  const handleFacetClick = () => {
    if (loading) return;

    let params = { [facetName]: facet.id !== 0 ? [facet.id] : null };
    if (!displayBaseCount) {
      params = { ...advancedParams, ...params };
    }

    dispatch(updateAdvancedParams({ searchScope, params }));
    fetch({ ...params, p: 1 });
    updateUrlQuery(params);

    if (onFilterClick) onFilterClick(facetName);
  };

  return (
    <div role="presentation" className={classNames({ facet: true, loading, bc: displayBaseCount })} onClick={() => handleFacetClick()}>
      <div className="check">
        <i className={classNames({ fa: true, 'fa-dot-circle-o': selected, 'fa-circle-o': !selected, loading })} aria-hidden="true" />
      </div>
      <div className={classNames({ name: true, selected })}>{facet.label}</div>
      <div className={classNames({ count: true, selected })}>{count > 0 ? <span>{count}</span> : <span>-</span>}</div>
    </div>
  );
};

export default FilterOption;
