/* eslint-disable react/style-prop-object */

import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import { getCurrency } from '../../../../redux/selectors/preferences';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Legals = ({ prices }) => {
  const currency = useSelector(getCurrency);

  return (
    <div className="x-landingpage-legals">
      <div className="container">
        <p className="title">
          <A18n rsx="artprice.landingpages.services.legals.m1" />
        </p>
        <p>
          <A18n rsx="artprice.landingpages.services.legals.m2" />
        </p>
        <p>
          <A18n
            rsx="artprice.landingpages.services.legals.m3"
            trustHtml
            replace={[['%price%', <FormattedNumber key="originalPrice" notation="compact" value={get(prices, 'originalPrice.eur')} style="currency" currency={currency} />]]}
          />
        </p>
      </div>
    </div>
  );
};

export default Legals;
