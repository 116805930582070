/* eslint-disable global-require */
module.exports = [
  ...require('./sales/i18n'),
  ...require('./howto/i18n'),
  ...require('./common/i18n'),
  ...require('./Indexes/i18n'),
  ...require('./marketplace/i18n'),
  ...require('./wallet/i18n'),
  ...require('./Homepage/i18n'),
  ...require('./Events/i18n'),
  ...require('./MyArtprice/i18n'),
  ...require('./search/i18n'),
  ...require('./lots/i18n'),
  ...require('./account/i18n'),
  ...require('./subscriptions/i18n'),
  ...require('./Auctioneers/i18n'),
  ...require('./cookieConsent/i18n'),
  ...require('./TotalAccess/i18n'),
  ...require('./artists/i18n'),
  ...require('./Indicator/i18n'),
  ...require('./layout/i18n'),
  ...require('./Contact/i18n'),
  ...require('./Reports/i18n'),
  ...require('./amci/i18n'),
  ...require('./Legals/i18n'),
  ...require('./ails/i18n'),
  ...require('./Payments/i18n'),
  ...require('./estimates/i18n'),
  ...require('./artpriceLandingPages/i18n'),
];
