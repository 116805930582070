import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getMessage } from '../../../../redux/selectors/dbEntities';
import { fetchMessage } from '../../../../redux/actions/dbEntities/messages';
import { moveToTrash } from '../../../../redux/actions/account/messages';
import { set } from '../../../../redux/actions/ui/dictionary';
import { KEY_SHOW_MESSAGE_TOOLS } from '../../../../constants/account/messages';
import TopBar from './TopBar';
import './stylesheet.scss';

const Message = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const message = useSelector(state => getMessage(state, { id }));

  useEffect(() => {
    dispatch(set(KEY_SHOW_MESSAGE_TOOLS, true));
    if (!message) {
      dispatch(fetchMessage({ id: [id] }));
    }
  }, []);

  const handleReply = () => {
    navigate('reply');
  };

  const handleDelete = () => {
    dispatch(moveToTrash({ id }));
    window.history.back();
  };

  if (!message) {
    return null;
  }

  return (
    <div className="account-messages-message">
      <TopBar onDelete={handleDelete} onReply={message.isSentByArtprice ? handleReply : null} />
      <div className="title">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: message.subject }} />
      </div>
      <div className="body">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: message.message }} />
      </div>
    </div>
  );
};

export default Message;
