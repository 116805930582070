import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useId, useState } from 'react';
import { isLogged as isLoggedSelector, getIdcustomer, getFavoriteCount, getPushEmail } from '../../../redux/slices/userContext/selectors';
import { fetchArtists, followArtist, unfollowArtist, updateArtists } from '../../../redux/actions/myartprice/data';
import { getArtists, getError, getLvl } from '../../../redux/selectors/myartprice/data';
import { update as updateUserContext } from '../../../redux/slices/userContext';
import { updateFollowRecomendationDisplay } from '../../../redux/slices/artist';
import { MODULE_EXPORT_PDF } from '../../../constants/rails';
import RecommendationsModale from './RecommendationsModale';
import ButtonFollow from '../../common/ButtonFollow';
import '../../common/stylesheets/buttons.scss';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const Follow = ({ idartist, size, rsxFavorited = 'components.myartprice.followartist.m3', rsxNotFavorited = 'components.myartprice.followartist.m2', noLabel, render }) => {
  const recommendationModaleUUID = useId();
  const dispatch = useDispatch();
  const isLogged = useSelector(isLoggedSelector);
  const favoriteArtists = useSelector(getArtists);
  const favoriteCount = useSelector(getFavoriteCount);
  const idcustomer = useSelector(getIdcustomer);
  const isPushEmail = useSelector(getPushEmail);
  const [isFavorite, setIsFavorite] = useState(false);
  const error = useSelector(getError);
  const errorLevel = useSelector(getLvl);

  useEffect(() => {
    if (idcustomer && isEmpty(favoriteArtists)) {
      dispatch(fetchArtists());
    }
  }, []);

  useEffect(() => {
    setIsFavorite(favoriteArtists.some(fav => fav.id === idartist));
  }, [favoriteArtists, idartist]);

  const handleFollowClick = () => {
    // Pas de client cooké on recommande de créer un compte
    if (!idcustomer) {
      dispatch(updateFollowRecomendationDisplay(recommendationModaleUUID));
      return;
    }

    // client non loggé on recommande de se logguer
    if (!isLogged) {
      window.location = '/identity';
      return;
    }

    // artiste non favoris et client loggé mais pas de push email,
    // on recommande d'activer le push email mais on ajoute l'artiste quand même, pas de return
    if (isLogged && !isPushEmail && !isFavorite) {
      dispatch(updateFollowRecomendationDisplay(recommendationModaleUUID));
    }

    if (idcustomer && favoriteCount === 0) {
      dispatch(updateFollowRecomendationDisplay(recommendationModaleUUID));
    }

    if (!isFavorite) {
      dispatch(updateUserContext({ favoriteCount: favoriteCount + 1 }));
      dispatch(followArtist({ idartist }));
    } else {
      dispatch(unfollowArtist({ idartists: [idartist] }));
      dispatch(updateUserContext({ favoriteCount: favoriteCount - 1 }));
    }
  };

  return (
    <div style={{ position: 'relative', display: 'inline', paddingTop: 7 }}>
      <RecommendationsModale uuid={recommendationModaleUUID} />

      {render && render({ isFavorite, handleFollowClick, error })}
      {!render && (
        <>
          <ButtonFollow isFavorite={isFavorite} size={size} handleFollowClick={handleFollowClick} rsxFavorited={rsxFavorited} rsxNotFavorited={rsxNotFavorited} noLabel={noLabel} />
          {error && (
            <div className="artists-follow">
              <p style={{ fontWeight: 'normal' }}>
                <i className="fa fa-exclamation-triangle marg marg-r-5" /> {error}
              </p>
              <p style={{ fontWeight: 'normal' }}>
                <a href="/myartprice/settings/artists">
                  <A18n rsx="components.myartprice.followartist.m6" />
                </a>
              </p>
              <div className="buttons">
                {errorLevel && (
                  <a href={`/subscription${errorLevel !== 1 ? `/upgrade?idmodule=${MODULE_EXPORT_PDF}` : ''}`} className="artp-btn artp-btn-blue">
                    {errorLevel === 1 && <A18n rsx="components.myartprice.followartist.m8" />}
                    {errorLevel === 2 && <A18n rsx="components.myartprice.followartist.m7" />}
                  </a>
                )}

                <button type="button" className={classNames({ 'artp-btn': true })} onClick={() => dispatch(updateArtists({ error: null }))}>
                  <A18n rsx="components.myartprice.followartist.m5" />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Follow;
