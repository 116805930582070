import React from 'react';

const Loading = () => (
  <div className="encart">
    <div className="row header skeleton">
      <h3>&nbsp;</h3>
    </div>
    <div className="row cart skeleton" style={{ height: 100 }} />
  </div>
);

export default Loading;
