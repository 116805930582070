import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLogged as isLoggedSelector, getIdcustomer } from '../../../../redux/slices/userContext/selectors';
import { fetchStores, followStore, unfollowStore } from '../../../../redux/actions/myartprice/data';
import { getFavoriteStore } from '../../../../redux/selectors/myartprice/data';
import ButtonFollow from '../../../common/ButtonFollow';

const Follow = ({ idstore, size, rsxFavorited = 'components.myartprice.followartist.m3', rsxNotFavorited = 'components.myartprice.followartist.m2', noLabel }) => {
  const dispatch = useDispatch();
  const isLogged = useSelector(isLoggedSelector);
  const favoriteStores = useSelector(getFavoriteStore);
  const idcustomer = useSelector(getIdcustomer);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (idcustomer && isEmpty(favoriteStores)) {
      dispatch(fetchStores());
    }
  }, []);

  useEffect(() => {
    setIsFavorite(favoriteStores.some(fav => fav === idstore));
  }, [favoriteStores, idstore]);

  const handleFollowClick = () => {
    if (!isLogged) {
      window.location = '/identity';
      return;
    }

    if (!isFavorite) {
      dispatch(followStore(idstore));
    } else {
      dispatch(unfollowStore(idstore));
    }
  };

  return <ButtonFollow isFavorite={isFavorite} size={size} handleFollowClick={handleFollowClick} rsxFavorited={rsxFavorited} rsxNotFavorited={rsxNotFavorited} noLabel={noLabel} />;
};

export default Follow;
