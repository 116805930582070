import i18next from 'i18next';
import React, { useEffect } from 'react';
import { ANALYTICS_FROM_LOT_DETAIL } from '../../../../constants/rails';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import './stylesheet.scss';

const Record = ({ aillandingpagerecord }) => {
  useEffect(() => {
    if (aillandingpagerecord) {
      aapiBeacon('ail_view', { idail: aillandingpagerecord.id, from: ANALYTICS_FROM_LOT_DETAIL });
    }
  }, [aillandingpagerecord]);

  if (!aillandingpagerecord) return null;

  const dateLabel = i18next.t('lots.similar.lot.m2').replace('%dt%', aillandingpagerecord.dt);
  return (
    <div className="lot-similar lot-record">
      <div className="card-header">Résultat exceptionnel</div>
      <div className="description">
        <div className="header">{dateLabel}</div>
        <div className="img">
          <a className="link-to" target="_blank" rel="noopener noreferrer" href={`${aillandingpagerecord.landingpageUrl}&f=${ANALYTICS_FROM_LOT_DETAIL}`} title="">
            <img alt="" src={aillandingpagerecord.images.square} />
          </a>
        </div>
        <div className="link">
          <a className="link-to" target="_blank" rel="noopener noreferrer" href={`${aillandingpagerecord.landingpageUrl}&f=${ANALYTICS_FROM_LOT_DETAIL}`}>
            {aillandingpagerecord.title}
          </a>
        </div>
        <div>{aillandingpagerecord.technique}</div>
        <div>{aillandingpagerecord.auctioneer}</div>
        <div>
          {aillandingpagerecord.city} {aillandingpagerecord.country}
        </div>
        <div>
          <strong>{aillandingpagerecord.price}</strong>
        </div>
      </div>
    </div>
  );
};

export default Record;
