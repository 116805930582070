import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { isEmpty, get, last, first } from 'lodash';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { getIds } from '../../../../../redux/selectors/myartprice/data';
import { getLot } from '../../../../../redux/selectors/dbEntities';
import './stylesheet.scss';
import { getAdvancedParams } from '../../../../../redux/slices/search/scoped';
import { usePaginatedSearch } from '../../../../../hooks/myartprice/usePaginatedSearch';

const ButtonsNavigation = ({ idlot, page, searchScope }) => {
  const navigate = useNavigate();
  const ids = useSelector(getIds);
  const { fetch } = usePaginatedSearch({ scope: searchScope });
  const [currentIndex, setCurrentIndex] = useState(-1);
  const advancedParams = useSelector(state => getAdvancedParams(state, { searchScope }));

  const startOfPage = currentIndex - 1 < 0;
  const endOfPage = currentIndex + 1 === ids.length;
  const startOfResults = page === 1 && startOfPage;
  const endOfResults = page === 'totalPages' && endOfPage;
  const prevLot = useSelector(state => getLot(state, { id: ids[currentIndex - 1] }));
  const nextLot = useSelector(state => getLot(state, { id: ids[currentIndex + 1] }));

  useEffect(() => {
    if (!isEmpty(ids)) {
      const idx = ids.findIndex(id => parseInt(id, 10) === parseInt(idlot, 10));
      setCurrentIndex(idx);
    }
  }, [ids, idlot]);

  const onPrevClick = async () => {
    const navigatePath = [];
    if (startOfPage) {
      const fetchResult = await fetch({ ...advancedParams, p: parseInt(page, 10) - 1 });
      const lot = get(fetchResult.results.lots, last(fetchResult.results.idlot));
      navigatePath.push(`./../../${lot.id}/${lot.crypto}`);
    } else {
      navigatePath.push(`./../../${prevLot.id}/${prevLot.crypto}`);
    }

    navigatePath.push(window.location.search);
    navigate(navigatePath.join(''));
  };

  const onNextClick = async () => {
    const navigatePath = [];

    if (endOfPage) {
      const fetchResult = await fetch({ ...advancedParams, p: parseInt(page, 10) + 1 });
      const lot = get(fetchResult.results.lots, first(fetchResult.results.idlot));
      navigatePath.push(`./../../${lot.id}/${lot.crypto}`);
    } else {
      navigatePath.push(`./../../${nextLot.id}/${nextLot.crypto}`);
    }

    navigatePath.push(window.location.search);
    navigate(navigatePath.join(''));
  };

  return (
    <div className="buttons-navigation">
      <button disabled={startOfResults} type="button" className="prev" onClick={() => onPrevClick()}>
        <i className="fa fa-angle-left marg marg-r-5" />
        {/* Previous Lot */}
        <span className="button-label">{i18next.t('components.myartprice.common.navigation.m1')}</span>
      </button>
      &nbsp;
      <button disabled={endOfResults} type="button" className="next" onClick={() => onNextClick()}>
        {/* Next Lot */}
        <span className="button-label">{i18next.t('components.myartprice.common.navigation.m2')}</span>
        <i className="fa fa-angle-right marg marg-l-5" />
      </button>
    </div>
  );
};

export default ButtonsNavigation;
