import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { ErrorBoundary } from '@sentry/react';
import customStyles from '../../../../utils/styles/reactSelectStyle';
import { useViewport } from '../../../../hooks/useViewport';

const BasicDropdown = ({ name, control, rules = {}, placeholder, loadingMessage, isSearchable, options, isLoading, readOnly, width }) => {
  const { viewportWidth } = useViewport();
  const handleChange = (value, onChange) => {
    onChange(value.value);
  };

  return (
    <ErrorBoundary fallback={<p style={{ color: '#999' }}>⚠ Dropdown error</p>}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Select
            id={name}
            styles={customStyles({ width, viewportWidth })}
            classNamePrefix="react-select"
            options={options}
            isLoading={isLoading}
            readOnly={readOnly}
            isSearchable={isSearchable}
            isDisabled={readOnly}
            placeholder={placeholder}
            loadingMessage={loadingMessage}
            value={options.find(option => option.value === value)}
            onChange={v => handleChange(v, onChange)}
          />
        )}
      />
    </ErrorBoundary>
  );
};

export default BasicDropdown;
