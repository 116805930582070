import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

export const ShortcutLink = ({ text, onClick, to, className, style }) => (
  <Link onClick={onClick} to={to}>
    <div className={classNames(`shortcut`, className)} style={style}>
      <i className="fa fa-circle-o" aria-hidden="true" />
      {text}
    </div>
  </Link>
);

export const Shortcut = ({ text, className, style }) => (
  <div className={classNames(`shortcut`, className)} style={style}>
    <b>
      <i className="fa fa-dot-circle-o" aria-hidden="true" />
      {text}
    </b>
  </div>
);
