/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { MYARTPRICE_LOTS_FOLLOW_MARKETPLACE, MYARTPRICE_LOTS_MARKETPLACE, MYARTPRICE_MARKETPLACE } from '../../../../../constants/search/scope';
import { updateCurrencyPreference } from '../../../../../redux/actions/preferences';
import { getCurrency } from '../../../../../redux/selectors/preferences';
import { CURRENCIES, DEFAULT_CURRENCIES } from '../../../../../constants/preferences';
import './stylesheet.scss';
import { getSearchScope } from '../../../../../redux/selectors/myartprice/data';

const DisplayedCurrencies = () => {
  const dispatch = useDispatch();
  const currency = useSelector(getCurrency);
  const currentScope = useSelector(getSearchScope);

  const [currencies, setCurrencies] = useState(CURRENCIES);

  useEffect(() => {
    if ([MYARTPRICE_MARKETPLACE, MYARTPRICE_LOTS_FOLLOW_MARKETPLACE, MYARTPRICE_LOTS_MARKETPLACE].includes(currentScope)) {
      setCurrencies(DEFAULT_CURRENCIES);
    }
  }, [currentScope]);

  const handleClick = curr => {
    dispatch(updateCurrencyPreference({ currency: curr.iso3 }));
  };

  return (
    <div className="display artp-input-group">
      <span className="artp-input-label">{i18next.t('marketplace.searchbars.classifieds.bar.display.m1')}</span>
      <div className="btn-group">
        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          {!currencies[currency].src && currencies[currency].iso1}
          {currencies[currency].src && <img style={{ height: 15, marginTop: -4 }} src={currencies[currency].src} alt={currencies[currency].iso1} />}
          <span className="caret" />
        </button>
        <ul className="dropdown-menu">
          {Object.values(currencies).map(c => (
            <li key={c.iso1}>
              <a onClick={() => handleClick(c)}>
                {!c.src && c.iso1}
                {c.src && <img style={{ height: 15, marginTop: -4 }} src={c.src} alt={c.iso1} />}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DisplayedCurrencies;
