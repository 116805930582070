import React, { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { debounce as debounceFunction } from 'lodash';
import { getIntegerValue } from '../../../../utils/search/inputs';
import './stylesheet.scss';

/*
value is an object : { from:, to: }
 */
const Years = ({ className, value = {}, onChange, debounce = 150 }) => {
  const [internalValue, setInternalValue] = useState(value);
  const debouncedOnChange = useMemo(() => debounceFunction(onChange, debounce), [onChange, debounce]);
  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  return (
    <div className={`search-years ${className ?? ''}`.trim()}>
      <div className="cell first" style={{ width: '50%' }}>
        <input
          type="number"
          className="form-control"
          value={internalValue?.from ?? ''}
          onChange={e => {
            const from = getIntegerValue(e.target.value);
            setInternalValue(prevValue => ({ ...prevValue, from }));
            if (!internalValue.to || from <= internalValue.to) {
              debouncedOnChange({ from });
            }
          }}
          placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m10')}
        />
      </div>
      <div className="cell" style={{ width: '50%' }}>
        <input
          type="number"
          className="form-control"
          value={internalValue?.to ?? ''}
          onChange={e => {
            const to = getIntegerValue(e.target.value);
            setInternalValue(prevValue => ({ ...prevValue, to }));
            if (!internalValue.from || internalValue.from <= to) {
              debouncedOnChange({ to });
            }
          }}
          placeholder={i18next.t('marketplace.searchbars.classifieds.bar.m11')}
        />
      </div>
    </div>
  );
};

export default Years;
