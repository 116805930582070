import React, { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

const Display = ({ children, ...props }) => {
  const [height, setHeight] = useState('auto');
  const contentDiv = useRef(null);

  useEffect(() => {
    const element = contentDiv.current;

    const resizeObserver = new ResizeObserver(() => {
      setHeight(element.clientHeight);
    });

    resizeObserver.observe(element);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <AnimateHeight height={height} contentClassName="auto-content" contentRef={contentDiv} disableDisplayNone {...props}>
      {children}
    </AnimateHeight>
  );
};
export default Display;
