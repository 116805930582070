import React from 'react';
import { FormattedNumber } from 'react-intl';
import { ANALYTICS_CLICK_OPERATION_FAQ, ANALYTICS_FROM_LANDINGPAGES_SERVICES, ARTISTS_OFFICIAL_COUNT } from '../../../../../constants/rails';
import { FOLD_ICON_CHEVRON, FOLD_ICON_POSITION_RIGHT } from '../../../../common/Accordion/constants';
import { aapiBeacon } from '../../../../../services/analytics/aapi';
import '../../../../common/stylesheets/buttons.scss';
import Accordion from '../../../../common/Accordion';
import A18n from '../../../../common/A18n';
import './stylesheet.scss';

const items = [
  {
    key: 'faq1',
    title: <A18n rsx="artprice.landingpages.services.row7.faq.m1" trustHtml />,
    onUnfold: () => aapiBeacon('click', { from: ANALYTICS_FROM_LANDINGPAGES_SERVICES, op: ANALYTICS_CLICK_OPERATION_FAQ, section: 'faq-1' }),
    children: (
      <div>
        <p>
          <A18n rsx="artprice.landingpages.services.row7.faq.m2" trustHtml />
        </p>
        <p>
          <A18n rsx="artprice.landingpages.services.row7.faq.m3" trustHtml />
        </p>
        <p>
          <A18n rsx="artprice.landingpages.services.row7.faq.m4" trustHtml />
        </p>
      </div>
    ),
  },
  {
    key: 'faq2',
    title: <A18n rsx="artprice.landingpages.services.row7.faq.m5" trustHtml />,
    onUnfold: () => aapiBeacon('click', { from: ANALYTICS_FROM_LANDINGPAGES_SERVICES, op: ANALYTICS_CLICK_OPERATION_FAQ, section: 'faq-2' }),
    children: (
      <div>
        <p>
          <strong>
            <A18n rsx="artprice.landingpages.services.row7.faq.m6" trustHtml />
          </strong>
        </p>
        <p>
          <A18n rsx="artprice.landingpages.services.row7.faq.m7" trustHtml />
        </p>
        <p>
          <A18n rsx="artprice.landingpages.services.row7.faq.m8" trustHtml />
        </p>
        <p>
          <A18n rsx="artprice.landingpages.services.row7.faq.m9" trustHtml />
        </p>
      </div>
    ),
  },
  {
    key: 'faq3',
    title: <A18n rsx="artprice.landingpages.services.row7.faq.m11" trustHtml />,
    onUnfold: () => aapiBeacon('click', { from: ANALYTICS_FROM_LANDINGPAGES_SERVICES, op: ANALYTICS_CLICK_OPERATION_FAQ, section: 'faq-3' }),
    children: (
      <div>
        <p>
          <A18n rsx="artprice.landingpages.services.row7.faq.m10" trustHtml />
        </p>
        <p>
          <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m12" />
        </p>
        <p>
          <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m13" />
        </p>
      </div>
    ),
  },
  {
    key: 'faq4',
    title: <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m14" />,
    onUnfold: () => aapiBeacon('click', { from: ANALYTICS_FROM_LANDINGPAGES_SERVICES, op: ANALYTICS_CLICK_OPERATION_FAQ, section: 'faq-4' }),
    children: (
      <div>
        <p>
          <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m15" />
        </p>
        <p>
          <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m16" replace={[['%count-artists%', <FormattedNumber key={`row7-${ARTISTS_OFFICIAL_COUNT}`} value={ARTISTS_OFFICIAL_COUNT} />]]} />
        </p>
      </div>
    ),
  },
  {
    key: 'faq5',
    title: <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m17" />,
    onUnfold: () => aapiBeacon('click', { from: ANALYTICS_FROM_LANDINGPAGES_SERVICES, op: ANALYTICS_CLICK_OPERATION_FAQ, section: 'faq-5' }),
    children: (
      <div>
        <p>
          <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m18" />
        </p>
        <p>
          <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m19" />
        </p>
      </div>
    ),
  },
  {
    key: 'faq6',
    title: <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m20" />,
    onUnfold: () => aapiBeacon('click', { from: ANALYTICS_FROM_LANDINGPAGES_SERVICES, op: ANALYTICS_CLICK_OPERATION_FAQ, section: 'faq-6' }),
    children: (
      <div>
        <p>
          <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m21" />
        </p>
        <p>
          <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m22" />
        </p>
        <p>
          <A18n trustHtml rsx="artprice.landingpages.services.row7.faq.m23" />
        </p>
      </div>
    ),
  },
];

const Faq = () => <Accordion items={items} className="x-landingpage-x-row7-faq" defaultActiveIndex={-1} foldIconPosition={FOLD_ICON_POSITION_RIGHT} foldIcon={FOLD_ICON_CHEVRON} />;

export default Faq;
