import { isEmpty } from 'lodash';
import React, { forwardRef } from 'react';
import A18n from '../../../common/A18n';
import Record from './Record';
import './stylesheet.scss';

const Records = forwardRef(({ records = [] }, ref) => {
  if (isEmpty(records)) return null;

  return (
    <div className="ails-landingpage-records bg" ref={ref}>
      <div className="container">
        <h2>
          <A18n rsx="ails.landingpage.records.m2" />
        </h2>
        <div className="records">
          {records.map(record => (
            <Record ail={record} key={record.id} />
          ))}
        </div>
      </div>
    </div>
  );
});

export default Records;
