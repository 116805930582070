import React from 'react';
import Stamps from './Stamps';
import Testimonial from './Testimonial';
import A18n from '../../../common/A18n';

const Row6 = () => (
  <div className="x-landingpage-x-row6">
    <div className="bg bg-left" />
    <div className="bg bg-right" />

    <div className="container">
      <h2>
        <A18n rsx="artprice.landingpages.services.row6.m1" />
      </h2>
      <Stamps />
      <Testimonial />
    </div>
  </div>
);

export default Row6;
