import React from 'react';
import { IntlProvider } from 'react-intl';
import Services from '..';
import ReduxProvider from '../../../providers/ReduxProvider';

const WithReduxProvider = ({ lang }) => (
  <IntlProvider locale={lang}>
    <ReduxProvider>
      <Services />
    </ReduxProvider>
  </IntlProvider>
);

export default WithReduxProvider;
