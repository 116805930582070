import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SearchContext from '../Context';
import { getAdvancedParams, getBaseParams } from '../../../../redux/slices/search/scoped';
import { EMPTY_OBJECT } from '../../../../constants/utils';

import './stylesheet.scss';

const HorizontalBar = ({ className, render, searchScope, onChange }) => {
  const baseParams = useSelector(state => getBaseParams(state, { searchScope }));
  const advancedParams = useSelector(state => getAdvancedParams(state, { searchScope }));
  const [horizontalParams, setHorizontalParams] = useState(EMPTY_OBJECT);

  const searchContextValue = useMemo(
    () => ({
      values: { ...baseParams, ...advancedParams, ...horizontalParams },
      onChange: changes => {
        setHorizontalParams(prevHorizontalParams => ({ ...prevHorizontalParams, ...changes }));
      },
      searchScope,
    }),
    [searchScope, baseParams, advancedParams, horizontalParams, setHorizontalParams],
  );

  // trigger onChange
  useEffect(() => {
    onChange(horizontalParams);
  }, [horizontalParams]);

  // reload Horizontal params if advanced params changed
  useEffect(() => {
    setHorizontalParams(EMPTY_OBJECT);
  }, [advancedParams, setHorizontalParams]);

  return (
    <div className={`Search-HorizontalBar ${className ?? ''}`.trim()}>
      <SearchContext.Provider value={searchContextValue}>{render()}</SearchContext.Provider>
    </div>
  );
};

export default HorizontalBar;
