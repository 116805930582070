import { first } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MYARTPRICE_LOTS_FOLLOW_FUTURE, MYARTPRICE_LOTS_FOLLOW_MARKETPLACE, MYARTPRICE_LOTS_FOLLOW_PAST } from '../../../constants/search/scope';
import { usePaginatedSearch } from '../../../hooks/myartprice/usePaginatedSearch';
import Spinner from '../../common/spinners/Spinner';

const scopes = [
  { scope: MYARTPRICE_LOTS_FOLLOW_FUTURE, url: '/myartprice/lots/futures' },
  { scope: MYARTPRICE_LOTS_FOLLOW_PAST, url: '/myartprice/lots/auctioned-off' },
  { scope: MYARTPRICE_LOTS_FOLLOW_MARKETPLACE, url: '/myartprice/lots/marketplace' },
];

const ResolveLots = () => {
  const [scopeIndex, setScopeIndex] = useState(0);
  const { fetch } = usePaginatedSearch({ scope: scopes[scopeIndex].scope });

  useEffect(() => {
    fetch().then(data => {
      if (data.totalCount > 0) {
        window.location = scopes[scopeIndex].url;
      } else if (scopeIndex >= scopes.length - 1) {
        window.location = first(scopes).url;
      } else {
        setScopeIndex(scopeIndex + 1);
      }
    });
  }, [scopeIndex, fetch]);

  return <Spinner />;
};

export default ResolveLots;
