import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchArticles,
  fetchCollaborations,
  fetchCa,
  fetchKnowledge,
  fetchTop10,
  getArticles,
  getArtist,
  getCollaborations,
  getCa,
  getKnowledge,
  getTop10,
  fetchFree,
  fetchClassifieds,
  fetchStores,
} from '../../../redux/slices/artist';
import { ANALYTICS_SECTION_ARTIST_0, ANALYTICS_SECTION_ARTIST_50, ANALYTICS_SECTION_ARTIST_100, ANALYTICS_FROM_ARTIST } from '../../../constants/rails';
import { fetchPhysicproducts } from '../../../redux/actions/subscriptions/physicproduct';
import { getSessionStorage, setSessionStorage } from '../../../utils/storage';
import BackToTopButton from '../../common/BackToTopButton';
import DOMView from '../../common/observers/DOMView';
import Recommendations from './Recommendations';
import { onClient } from '../../../utils/ssr';
import UiProvider from './common/UiProvider';
import Collaborations from './Collaboration';
import Marketplace from './Marketplace';
import Articles from './Articles';
import Indexes from './Indexes';
import Schemas from './Schemas';
import './Lots/stylesheet.scss';
import Header from './Header';
import Adagp from './Adagp';
import './stylesheet.scss';
import Menu from './Menu';
import Lots from './Lots';
import Filters from './Filters';
import Landingpage from './Landingpage';

const sessionStorageKey = 'hideLeftMenu';
function hideLeftMenuStatus() {
  if (onClient()) {
    return getSessionStorage(sessionStorageKey, false);
  }
  return false;
}

const Artist = () => {
  const [hideLeftMenu, setHideLeftMenu] = React.useState(hideLeftMenuStatus());
  const dispatch = useDispatch();
  const artist = useSelector(getArtist);
  const [forceResize, setForceResize] = useState(0);
  const indexes = useSelector(getCa);
  const collaborations = useSelector(getCollaborations);
  const top = useSelector(getTop10);
  const articles = useSelector(getArticles);
  const knownledge = useSelector(getKnowledge);

  const handleHideMenu = () => {
    setSessionStorage(sessionStorageKey, !hideLeftMenu);
    setHideLeftMenu(!hideLeftMenu);
  };

  const contextValue = {
    hideLeftMenu,
    setHideLeftMenu: handleHideMenu,
    menuRef: useRef(),
    lotsFutureRef: useRef(),
    lotsPastRef: useRef(),
    marketplaceRef: useRef(),
    indexesRef: useRef(),
    articlesRef: useRef(),
    collaborationsRef: useRef(),
    recommendationsRef: useRef(),
  };

  useEffect(() => {
    setForceResize((collaborations?.items?.length || 0) + (articles?.items?.length || 0) + (knownledge?.length || 0) + (top?.length || 0) + (indexes?.length || 0));
  }, [indexes, collaborations, top, articles, knownledge]);

  useEffect(() => {
    dispatch(fetchFree());
    dispatch(fetchPhysicproducts({ image: true, quantity: 1 }));
  }, []);

  useEffect(() => {
    if (artist) {
      // ATTENTION Ces data sont load async car elles ne sont pas visible immediatement dans l'écran. Le reste est loadé par le back
      dispatch(fetchArticles({ id: artist.id }));
      dispatch(fetchCollaborations({ id: artist.id }));
      dispatch(fetchTop10());
      dispatch(fetchKnowledge({ id: artist.id }));
      dispatch(fetchCa({ id: artist.id }));
      dispatch(fetchClassifieds({ id: artist.id }));
      dispatch(fetchStores({ id: artist.id }));
    }
  }, [artist?.id]);

  if (!artist) return <div />;

  return (
    <UiProvider value={contextValue}>
      <div className="artists-artist" itemProp="creator" itemScope itemType="http://schema.org/Person">
        <div id="artist-0">
          <DOMView from={ANALYTICS_FROM_ARTIST} section={ANALYTICS_SECTION_ARTIST_0} selector="#artist-0" idartist={artist.id} />
        </div>
        <Schemas />
        <Header />
        <Filters />
        {!hideLeftMenu && <Menu />}
        <Lots />
        <Landingpage />
        <Indexes />
        <div id="artist-50">
          <DOMView from={ANALYTICS_FROM_ARTIST} section={ANALYTICS_SECTION_ARTIST_50} selector="#artist-50" idartist={artist.id} />
        </div>
        <Marketplace />
        <Articles />
        <Collaborations />
        <Recommendations />
        <div className="back-to-top-container">
          <BackToTopButton switchDirectionAt={1200} top=".artists-artist" forceHandleResize={forceResize} />
        </div>
        <div id="artist-100">
          <DOMView from={ANALYTICS_FROM_ARTIST} section={ANALYTICS_SECTION_ARTIST_100} selector="#artist-100" idartist={artist.id} />
        </div>
        <Adagp />
      </div>
    </UiProvider>
  );
};

export default Artist;
