import i18next from 'i18next';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateArtistsViewFormatPreference, updateCurrencyPreference, updateDimensionPreference, updateFreshnessPreference, updateItemsPerPage, updateLotsViewFormatPreference } from '../../../redux/actions/preferences';
import { getArtistsViewFormat, getCurrency, getDimension, getFreshness, getItemsPerpage, getLotsViewFormat } from '../../../redux/selectors/preferences';
import ValidableDropDown from '../../search/common/DropDownToValidate';
import ViewFormat from './ViewFormat';
import Dimensions from './Dimensions';
import Currencies from './Currencies';
import Freshness from './Freshness';
import PerPage from './PerPage';
import './stylesheet.scss';

const DEFAULT_RSX = {
  title: 'marketplace.searchbars.classifieds.bar.m7',
  perPage: 'lots.search.filters.display.itemsperpage.m1',
};

const Preferences = ({ hiddenFields, className, icon = 'cogs', onChange, right, rsx = DEFAULT_RSX, withOptionalCurrencies }) => {
  const dispatch = useDispatch();
  const currency = useSelector(getCurrency);
  const itemsPerPage = useSelector(getItemsPerpage);
  const dimension = useSelector(getDimension);
  const freshness = useSelector(getFreshness);
  const lotsViewFormat = useSelector(getLotsViewFormat);
  const artistsViewFormat = useSelector(getArtistsViewFormat);

  const defaultInternalState = {
    currency,
    freshness,
    itemsPerPage,
    dimension,
    lotsViewFormat,
    artistsViewFormat,
  };

  const [internalState, setInternalState] = useState(defaultInternalState);

  return (
    <ValidableDropDown
      right={right}
      title={rsx.title === false ? '' : i18next.t(rsx.title || DEFAULT_RSX.title)}
      onCancel={() => setInternalState(defaultInternalState)}
      onSubmit={() => {
        const modifs = {};
        if (internalState.itemsPerPage !== itemsPerPage) {
          modifs.itemsPerPage = internalState.itemsPerPage;
          dispatch(updateItemsPerPage({ itemsPerPage: internalState.itemsPerPage }));
        }
        if (internalState.currency !== currency) {
          modifs.currency = internalState.currency;
          dispatch(updateCurrencyPreference({ currency: internalState.currency }));
        }
        if (internalState.dimension !== dimension) {
          modifs.dimension = internalState.dimension;
          dispatch(updateDimensionPreference({ dimension: internalState.dimension }));
        }
        if (internalState.freshness !== freshness) {
          modifs.freshness = internalState.freshness;
          dispatch(updateFreshnessPreference({ freshness: internalState.freshness }));
        }
        if (internalState.lotsViewFormat !== lotsViewFormat) {
          modifs.lotsViewFormat = internalState.lotsViewFormat;
          dispatch(updateLotsViewFormatPreference({ lotsViewFormat: internalState.lotsViewFormat }));
        }
        if (internalState.artistsViewFormat !== artistsViewFormat) {
          modifs.artistsViewFormat = internalState.artistsViewFormat;
          dispatch(updateArtistsViewFormatPreference({ artistsViewFormat: internalState.artistsViewFormat }));
        }

        if (onChange) {
          onChange(modifs);
        }
      }}
      className={`common-preferences ${className ?? ''}`}
      icon={icon}
      displayIconDesktop
      render={() => (
        <>
          {!hiddenFields?.currencies && <Currencies withOptionalCurrencies={withOptionalCurrencies} value={currency} onChange={value => setInternalState(prevState => ({ ...prevState, currency: value }))} />}
          {!hiddenFields?.dimension && <Dimensions value={dimension} onChange={value => setInternalState(prevState => ({ ...prevState, dimension: value }))} />}
          {!hiddenFields?.perPage && <PerPage value={itemsPerPage} onChange={value => setInternalState(prevState => ({ ...prevState, itemsPerPage: value }))} label={i18next.t(rsx.perPage || DEFAULT_RSX.perPage)} />}
          {!hiddenFields?.lotsViewFormat && <ViewFormat value={lotsViewFormat} onChange={value => setInternalState(prevState => ({ ...prevState, lotsViewFormat: value }))} />}
          {!hiddenFields?.artistsViewFormat && <ViewFormat value={artistsViewFormat} onChange={value => setInternalState(prevState => ({ ...prevState, artistsViewFormat: value }))} />}
          {!hiddenFields?.freshness && <Freshness value={freshness} onChange={value => setInternalState(prevState => ({ ...prevState, freshness: value }))} />}
        </>
      )}
    />
  );
};

export default Preferences;
