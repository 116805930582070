import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { useInitializeActiveShorcut } from '../../../hooks/myartprice/useInitializeActiveShorcut';
import { usePaginatedSearch } from '../../../hooks/myartprice/usePaginatedSearch';
import { getItemsPerpage } from '../../../redux/selectors/preferences';
import { SHORTCUT_MARKETPLACE } from '../../../constants/myartprice';
import { MYARTPRICE_STORE } from '../../../constants/search/scope';
import { isInViewport, scrollToElement } from '../../../utils/ui';
import Pagination from '../../common/Pagination';
import MainLayout from '../common/MainLayout';
import NoResults from '../common/NoResults';
import StoreTile from './StoreTile';

import './stylesheet.scss';
import { updateSearchScope } from '../../../redux/actions/myartprice/data';
import { getCurrentQueryObject } from '../../../utils/http';

const Store = () => {
  const dispatch = useDispatch();
  const perPage = useSelector(getItemsPerpage);
  const dataContainerRef = useRef(null);
  const refTitle = useRef(null);
  const { fetch, advancedParams, ids, page, totalCount, loading } = usePaginatedSearch({ scope: MYARTPRICE_STORE });

  useInitializeActiveShorcut({ forcedShortcut: SHORTCUT_MARKETPLACE });

  useEffect(() => {
    dispatch(updateSearchScope(MYARTPRICE_STORE));
    fetch(getCurrentQueryObject());
  }, []);

  const handlePaginationClick = p => {
    fetch({ ...advancedParams, p });

    if (!isInViewport('#top-pagination')) {
      scrollToElement(refTitle);
    }
  };

  // Breakcrumb
  useEffect(() => {
    const breadcrumb = document.querySelector('.breadcrumb');
    if (breadcrumb) {
      breadcrumb.classList.add('hidden-xs', 'hidden-sm');
      breadcrumb.innerHTML = `<li><a href="/">${i18next.t('common._marketplace_menu.html.m39')}</a></li> 
                              <li>${i18next.t('artprice.lib.myartprice.artists.m13')}</li> 
                              <li>${i18next.t('layouts.header.m44')}</li>`;
    }
  }, []);

  return (
    <MainLayout>
      <div className="col-container">
        {(loading && (
          <div className="spinner">
            <i className="fa fa-5x fa-spinner fa-spin" />
          </div>
        )) || (
          <>
            {/* No Results / List of Store */}
            {(ids.length === 0 && <NoResults messageRsxKey="component.myartprice.marketplace.m4" linkRsxKey="component.myartprice.marketplace.m5" href="/marketplace/store" />) || (
              <div className="data-container" ref={dataContainerRef}>
                {ids.map(idstore => (
                  <StoreTile id={idstore} key={idstore} />
                ))}
              </div>
            )}

            {/* Pagination */}
            {totalCount / perPage > 1 && totalCount > 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: 25,
                }}
              >
                <Pagination className="pagination" perPage={perPage} pagePadding={1} loading={loading} totalCount={totalCount} activePage={page} onClick={p => handlePaginationClick(p)} />
              </div>
            )}
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Store;
