import React from 'react';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const Consignment = ({ consignment }) => {
  if (!consignment) return null;

  return (
    <div className="container">
      <div className="ails-landingpage-consignment" style={{ backgroundImage: `url(${consignment.images.square})` }}>
        <div className="consignment">
          <h2>
            <A18n rsx="ails.landingpage.consignment.m4" />
          </h2>

          <p>{consignment.title}</p>

          {(consignment.url || consignment.email) && (
            <p>
              <a className="artp-btn" href={consignment.url ? consignment.url : `mailto:${consignment.email}`}>
                <A18n rsx="ails.landingpage.consignment.m5" />
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Consignment;
