import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import StandaloneCreate from '../../../account/Lotslists/AddToListButton/StandaloneCreate';
import ClosingDropDown from '../../../search/common/ClosingDropDown';
import BannerNoAccess from '../../../subscriptions/BannerNoAccess';
import CommonHorizontalBar from '../../../common/HorizontalBar';
import ButtonBack from '../../../common/ButtonBack';
import ButtonsNavigation from './ButtonsNavigation';
import Details from '../../../lots/Show';
import './stylesheet.scss';
import { updateSearchScope } from '../../../../redux/actions/myartprice/data';
import { getCurrentPage } from '../../../../redux/selectors/myartprice/data';

const Show = ({ searchScope, past }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { idlot: defaultIdlot, crypto: defaultCrypto } = useParams();
  const [crypto, setCrypto] = useState(parseInt(defaultCrypto, 10));
  const [idlot, setIdlot] = useState(parseInt(defaultIdlot, 10));
  const page = useSelector(getCurrentPage);

  const handleBackClick = () => {
    navigate(`./../../${window.location.search}`);
  };

  useEffect(() => {
    setCrypto(defaultCrypto);
    setIdlot(defaultIdlot);
  }, [defaultCrypto, defaultIdlot]);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(updateSearchScope(searchScope));
  }, [searchScope]);

  return (
    <div className="myartprice-lot">
      <CommonHorizontalBar className="bar">
        <div className="container">
          <div className="filters-container grid-override">
            <ButtonBack style={{ marginRight: 5 }} onClick={handleBackClick} />
            <ButtonsNavigation idlot={idlot} searchScope={searchScope} page={page} />
            {past && <StandaloneCreate ids={[idlot]} newSelectionKey={`lot-${idlot}`} />}
            <ClosingDropDown className="preferences" displayIconDesktop />
          </div>
        </div>
      </CommonHorizontalBar>
      {crypto && idlot && (
        <>
          <Details id={idlot} crypto={crypto} />
          <BannerNoAccess idlot={idlot} />
        </>
      )}
    </div>
  );
};

export default Show;
