import React from 'react';
import { useSelector } from 'react-redux';
import { getParams } from '../../../../redux/slices/payments';
import { usePromotionDescriptionFor } from './constant';
import AvailablePayments from '../AvailablePayments';
import Promocode from './Promocode';
import Help from '../Help';
import './stylesheet.scss';
import Cart from './Cart';

const CartSummary = ({ withPromocode }) => {
  const { promocode = '' } = useSelector(getParams);

  return (
    <div className="payments-common-cart-summary">
      <Cart />
      {withPromocode && !usePromotionDescriptionFor.includes(promocode) && <Promocode />}

      <div className="help-on-desktop">
        <Help />
        <AvailablePayments />
      </div>
    </div>
  );
};
export default CartSummary;
