/* eslint-disable camelcase */
import i18next from 'i18next';
import { formatFns } from '../../../utils/dates/format';
import { CURRENCIES } from '../../../constants/preferences';

const intervalDisplay = ({ from, to, i18n, addOn }) => {
  const values = [];
  if (from) values.push(`${from}${addOn || ''} <=`);
  if (i18n) values.push(i18next.t(i18n));
  if (to) values.push(`<= ${to}${addOn || ''}`);

  return values.join(' ');
};

export const config = [
  {
    id: 'only_in_future_sales',
    fields: ['only_in_future_sales'],
    paramsToDelete: ['only_in_future_sales'],
    display: ({ only_in_future_sales }) => {
      if (+only_in_future_sales === 1) return i18next.t('lots.search.filters.inputs.saledate.m1');
      return null;
    },
  },
  {
    id: 'fav',
    fields: ['fav'],
    paramsToDelete: ['fav'],
    display: ({ fav }) => {
      if (+fav === 1) return i18next.t('search.helpers.items.favorite.artists.m1');
      return null;
    },
  },
  {
    id: 'limit_keywords_on_title',
    fields: ['limit_keywords_on_title'],
    paramsToDelete: ['limit_keywords_on_title'],
    display: ({ limit_keywords_on_title }) => {
      if (+limit_keywords_on_title === 1) return i18next.t('lots.search.form.keyword.m2');
      return null;
    },
  },
  {
    id: 'birth_year',
    fields: ['birth_year_from', 'birth_year_to'],
    paramsToDelete: ['birth_year_from', 'birth_year_to'],
    display: params =>
      intervalDisplay({
        from: params.birth_year_from,
        to: params.birth_year_to,
        i18n: 'components.search.helpers.items.artist_birth_year.m1',
      }),
  },
  {
    id: 'death_year',
    fields: ['death_year_from', 'death_year_to'],
    paramsToDelete: ['death_year_from', 'death_year_to'],
    display: params =>
      intervalDisplay({
        from: params.death_year_from,
        to: params.death_year_to,
        i18n: 'components.search.helpers.items.artist_death_year.m1',
      }),
  },
  {
    id: 'indicator',
    fields: ['indicator'],
    paramsToDelete: ['indicator'],
    display: ({ indicator }) => {
      if (+indicator === 1) return i18next.t('subscription.index.m27');
      return null;
    },
  },
  {
    id: 'reproduction',
    fields: ['reproduction'],
    paramsToDelete: ['reproduction'],
    display: ({ reproduction }) => {
      if (+reproduction === 1) return i18next.t('artprice.lib.fsresultctrl.m36');
      return null;
    },
  },
  {
    id: 'signed',
    fields: ['signed'],
    paramsToDelete: ['signed'],
    display: ({ signed }) => {
      if (+signed === 1) return i18next.t('artprice.web.pages.classifieds.decorative.details.m18');
      return null;
    },
  },
  {
    id: 'sold',
    fields: ['sold'],
    paramsToDelete: ['sold'],
    display: ({ sold }) => {
      if (+sold === 1) return i18next.t('lots.search.form.sold.m1');
      return null;
    },
  },
  {
    id: 'exact_match',
    fields: ['exact_match'],
    paramsToDelete: ['exact_match'],
    display: ({ exact_match }) => {
      if (+exact_match === 1) return i18next.t('artists.search.form.keyword.m3');
      return null;
    },
  },
  {
    id: 'lastname_only',
    fields: ['lastname_only'],
    paramsToDelete: ['lastname_only'],
    display: ({ lastname_only }) => {
      if (+lastname_only === 1) return i18next.t('artists.search.form.keyword.m4');
      return null;
    },
  },
  {
    id: 'terms',
    fields: ['terms'],
    paramsToDelete: ['terms'],
    display: params => `${i18next.t('lots.search.form.keyword.m3')}: "${params.terms}"`,
  },
  {
    id: 'keyword',
    fields: ['keyword'],
    paramsToDelete: ['keyword'],
    display: params => `${i18next.t('lots.search.form.keyword.m3')}: "${params.keyword}"`,
  },
  {
    id: 'size',
    fields: ['length', 'width', 'height', 'percent'],
    paramsToDelete: ['length', 'width', 'height', 'percent'],
    display: params => {
      const values = [];
      if (params.length > 0) values.push(`${i18next.t('lots.search.form.size.m2')} ${params.length}`);
      if (params.width > 0) values.push(`${i18next.t('lots.search.form.size.m1')} ${params.width}`);
      if (params.height > 0) values.push(`${i18next.t('lots.search.form.size.m3')} ${params.height}`);
      if (params.percent > 0) values.push(`+/- ${params.percent}%`);

      return values.join(', ');
    },
  },
  {
    id: 'price_min_max',
    fields: ['price_min', 'price_max'],
    paramsToDelete: ['price_min', 'price_max', 'price_idcurrency'],
    display: params =>
      intervalDisplay({
        from: params.price_min,
        to: params.price_max,
        i18n: 'marketplace.searchbars.classifieds.bar.m9',
        addOn: Object.values(CURRENCIES).find(currency => currency.id === parseInt(params.price_idcurrency, 10)).iso1,
      }),
  },
  {
    id: 'price_from_to',
    fields: ['price_from', 'price_to'],
    paramsToDelete: ['price_from', 'price_to', 'price_idcurrency'],
    display: params =>
      intervalDisplay({
        from: params.price_from,
        to: params.price_to,
        i18n: 'marketplace.searchbars.classifieds.bar.m9',
        addOn: Object.values(CURRENCIES).find(currency => currency.id === parseInt(params.price_idcurrency, 10)).iso1,
      }),
  },
  {
    id: 'estimation',
    fields: ['estimation_from', 'estimation_to'],
    paramsToDelete: ['estimation_from', 'estimation_to', 'estimation_idcurrency'],
    display: params =>
      intervalDisplay({
        from: params.estimation_from,
        to: params.estimation_to,
        i18n: 'lots.show.m12',
        addOn: Object.values(CURRENCIES).find(currency => currency.id === parseInt(params.estimation_idcurrency, 10)).iso1,
      }),
  },
  {
    id: 'year',
    fields: ['year_from', 'year_to'],
    paramsToDelete: ['year_from', 'year_to'],
    i18next: 'search.drawers.lot.m1',
    display: params =>
      intervalDisplay({
        from: params.year_from,
        to: params.year_to,
        i18n: 'search.drawers.lot.m1',
      }),
  },
  {
    id: 'dates',
    fields: ['dt_from', 'dt_to'],
    paramsToDelete: ['dt_from', 'dt_to'],
    display: params =>
      intervalDisplay({
        from: formatFns(params.dt_from && new Date(params.dt_from), 'P'),
        to: formatFns(params.dt_to && new Date(params.dt_to), 'P'),
        i18n: 'filters.dates.m1',
      }),
  },
  {
    id: 'birth',
    fields: ['birth_from', 'birth_to'],
    paramsToDelete: ['birth_from', 'birth_to'],
    display: params =>
      intervalDisplay({
        from: params.birth_from,
        to: params.birth_to,
      }),
  },
  {
    id: 'death',
    fields: ['death_from', 'death_to'],
    paramsToDelete: ['death_from', 'death_to'],
    display: params =>
      intervalDisplay({
        from: params.death_from,
        to: params.death_to,
      }),
  },
];

const fieldsWithConfig = new Set(config.flatMap(({ fields }) => fields));
export const hasConfig = field => fieldsWithConfig.has(field);
export const getIdByField = field => {
  const groupConfig = config.find(({ fields }) => fields.includes(field));
  return groupConfig?.id;
};
export const getConfigById = idToFind => config.find(({ id }) => id === idToFind);
export const getConfigByField = field => {
  const idToFind = getIdByField(field);
  return config.find(({ id }) => id === idToFind);
};
