import React, { forwardRef } from 'react';
import A18n from '../../../common/A18n';
import Sale from './Sale';

const Sales = forwardRef(({ sales, salelots }, ref) => {
  if (!sales) return null;

  return (
    <div className="ails-landingpage-sales bg" ref={ref}>
      <div className="container">
        <h2>
          <A18n rsx="ails.landingpage.sales.m2" />
        </h2>

        {sales?.map(sale => (
          <Sale key={sale.id} ail={sale} lots={salelots?.filter(lot => lot.idsale === sale.discriminant)} />
        ))}
      </div>
    </div>
  );
});

export default Sales;
