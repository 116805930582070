import classNames from 'classnames';
import React, { useState } from 'react';
import { FOLD_ICON_OPTION, FOLD_ICON_POSITION_LEFT, FOLD_ICON_POSITION_RIGHT } from './constants';
import Icon from './Icon';

const Accordion = ({ className, items = [], defaultActiveIndex = 0, foldIconPosition = FOLD_ICON_POSITION_LEFT, foldIcon = FOLD_ICON_OPTION }) => {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);

  return (
    <div className={classNames(['common-accordion', className])}>
      {items.map((item, idx) => (
        <div
          className={classNames({ 'accordion-item': true, 'accordion-item-active': activeIndex === idx })}
          key={item.key || Math.random() * 10000}
          onClick={() => {
            if (idx !== activeIndex) {
              setActiveIndex(idx);
              item.onUnfold?.();
            } else {
              item.onFold?.();
              setActiveIndex(-1);
            }
          }}
        >
          <div className="title">
            <h3>
              {foldIconPosition === FOLD_ICON_POSITION_LEFT && <Icon active={idx === activeIndex} foldIcon={foldIcon} className="left" />}
              <span>{item.title}</span>
              {foldIconPosition === FOLD_ICON_POSITION_RIGHT && <Icon active={idx === activeIndex} foldIcon={foldIcon} className="right" />}
            </h3>
          </div>

          <section className="accordion-animation-wrapper">
            <div className="accordion-animation">
              <div className="accordion-transform-wrapper">
                <div className="accordion-content">{item.children}</div>
              </div>
            </div>
          </section>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
