import React from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MYARTPRICE_LOTS, MYARTPRICE_LOTS_FOLLOW } from '../../../../constants/search/scope';
import { getSearchScope } from '../../../../redux/selectors/myartprice/data';
import { off as closeDrawer } from '../../../../redux/actions/ui/switches';
import { MYARTPRICE_DRAWER_ID } from '../../../../constants/myartprice';
import CommonDrawer from '../../../common/Drawer';
import Content from './Content';
import './stylesheet.scss';

const Bar = ({ className, children, ...otherProps }) => {
  const dispatch = useDispatch();
  const currentScope = useSelector(getSearchScope);

  const mapScopeToTitleRsx = () => {
    if (MYARTPRICE_LOTS.includes(currentScope)) return 'layouts.header.m16';
    if (MYARTPRICE_LOTS_FOLLOW.includes(currentScope)) return 'layouts.header.m43';
    return 'layouts.header.m16';
  };

  return (
    <>
      <CommonDrawer
        id={MYARTPRICE_DRAWER_ID}
        render={() => (
          <div className="drawer-content">
            <div className="drawer-title">
              <span>
                {i18next.t('component.myartprice.lot.m9')} - {i18next.t(mapScopeToTitleRsx())}
              </span>
              <i role="presentation" className="fa fa-times text-right" onClick={() => dispatch(closeDrawer(MYARTPRICE_DRAWER_ID))} />
            </div>
            <div className="pad pad-5">
              <Content drawerId={MYARTPRICE_DRAWER_ID}>{children}</Content>
            </div>
          </div>
        )}
      />

      <div className={`hidden-sm hidden-xs myartprice-lots-vertical-bar ${className || ''}`.trim()} {...otherProps}>
        <Content>{children}</Content>
      </div>
    </>
  );
};

export default Bar;
