import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { getLotSimilar as getLotSimilarSelector } from '../../../redux/selectors/myartprice/data';
import { getLotSimilar as getLotSimilarAction } from '../../../redux/actions/myartprice/data';
import { fetchArtists } from '../../../redux/actions/dbEntities/artist';
import { getArtist } from '../../../redux/selectors/dbEntities';
import ClassifiedTile from './ClassifiedTile';
import LotTile from './LotTile';
import './stylesheet.scss';
import Record from './Record';
import Scroll from '../../common/Scroll';
import A18n from '../../common/A18n';

const LotSimilar = ({ idlot, idartist }) => {
  const dispatch = useDispatch();
  const lotsSimilar = useSelector(state => getLotSimilarSelector(state, { id: idlot }));
  const artist = useSelector(state => getArtist(state, { id: idartist }));

  useEffect(() => {
    dispatch(getLotSimilarAction(idlot));

    if (!artist) {
      dispatch(fetchArtists({ id: idartist, withDates: false }));
    }
  }, [idlot]);

  if (isEmpty(lotsSimilar)) return <div />;

  const { similar, futures, classifieds, artistRecords } = lotsSimilar;

  if (futures.length === 0 && similar.length === 0 && classifieds.length === 0 && artistRecords.length === 0) {
    return null;
  }

  return (
    <div className="lots-lot-similar container">
      <h2>{artist && <A18n rsx="lots.show.m16" replace={[['%artist%', artist.name ? artist.name : artist.artist]]} />}</h2>
      <Scroll
        containerClassname={classNames({ 'lots-container': true, 'with-records': !isEmpty(artistRecords) })}
        containerStyle={{
          gridTemplateColumns: [
            ...Array.from({ length: (artistRecords || []).length }, () => '260px'),
            ...Array.from({ length: (futures || []).length + (similar || []).length + (classifieds || []).length }, () => '220px'),
          ].join(' '),
        }}
      >
        {artistRecords && artistRecords.filter(l => l.discriminant !== idlot).map(l => <Record key={l.discriminant} aillandingpagerecord={l} />)}
        {futures && futures.map(l => <LotTile key={l.lot.id} future lot={l.lot} url={l.url} img={l.img} location={l.location} auctioneer={l.auctioneer} />)}
        {similar && similar.map(l => <LotTile key={l.lot.id} lot={l.lot} url={l.url} img={l.img} location={l.location} auctioneer={l.auctioneer} />)}
        {classifieds && (
          <>
            {classifieds.map(c => (
              <ClassifiedTile key={c.classified.id} classified={c.classified} url={c.url} img={c.img} location={c.location} />
            ))}
          </>
        )}
      </Scroll>
    </div>
  );
};

export default LotSimilar;
