import React from 'react';
import { FOLD_ICON_CHEVRON } from '../constants';
import Option from './Option';
import Arrow from './Arrow';
import './stylesheet.scss';

const Icon = ({ active, foldIcon, className }) => (
  <span className={className}>
    {foldIcon === FOLD_ICON_CHEVRON && <Arrow active={active} />}
    {foldIcon !== FOLD_ICON_CHEVRON && <Option active={active} />}
  </span>
);

export default Icon;
