import React, { useEffect, useState } from 'react';
import { useViewport } from '../../../../../hooks/useViewport';
import Display from './Display';

const Terms = ({ aillandingpagedetails }) => {
  const [termDesktop, setTermDesktop] = useState({ part1: null, part2: null });
  const [termMobile, setTermMobile] = useState({ part1: null, part2: null });
  const { viewportWidth } = useViewport();

  useEffect(() => {
    if (aillandingpagedetails?.term) {
      let terms;

      terms = aillandingpagedetails?.term.replace('%split-desktop%', '').split('%split-mobile%');
      setTermMobile({ part1: terms[0], part2: terms[1] });

      terms = aillandingpagedetails?.term.replace('%split-mobile%', '').split('%split-desktop%');
      setTermDesktop({ part1: terms[0], part2: terms[1] });
    }
  }, [aillandingpagedetails]);

  if (viewportWidth >= 768) return <Display terms={termDesktop} />;

  return <Display terms={termMobile} />;
};

export default Terms;
