import React, { useState } from 'react';
import i18next from 'i18next';
import { cleanPathname, objectToQueryString, queryStringToObject } from '../../../utils/http';
import CategorySelect from '../../search/helpers/items/CategorySelect';
import AdvancedButton from '../../search/helpers/AdvancedButton';
import Preferences from '../../search/helpers/Preferences';
import { ARTISTS } from '../../../constants/search/sorts';
import LoadingOverlay from '../../common/LoadingOverlay';
import { EMPTY_OBJECT } from '../../../constants/utils';
import Checkbox from '../../search/inputs/Checkbox';
import Select from '../../search/inputs/Select';
import Count from '../../search/helpers/Count';
import Years from '../../search/inputs/Years';
import Sort from '../../search/helpers/Sort';
import Item from '../../search/Search/Item';
import Search from '../../search/Search';
import Params from '../../search/Params';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const SearchBar = ({ searchScope, baseParams = EMPTY_OBJECT }) => {
  const urlParams = queryStringToObject(window.location.search);
  const [searching, setSearching] = useState(false);

  const handleChange = ({ params }) => {
    setSearching(true);

    const { sort } = urlParams;
    window.location.href = `${cleanPathname(window.location.pathname)}?${objectToQueryString({ ...{ sort }, ...baseParams, ...params, p: 1 })}`;
  };

  return (
    <>
      <Search
        searchScope={searchScope}
        baseParams={baseParams}
        advancedParams={urlParams}
        onChange={({ searchParams }) => {
          handleChange({ params: searchParams });
          return false; // stop the internal Search processing (we are going to reload the page)
        }}
        horizontalBar={({ drawerId, searchParams }) => (
          <>
            <div className="container-filters artists-horizontal-bar">
              <div className="container">
                <div className="filters-container">
                  <AdvancedButton drawerId={drawerId} />
                  <div className="filler" />
                  <Sort right options={ARTISTS} onChange={sortOption => handleChange({ params: { ...searchParams, sort: sortOption } })} value={urlParams?.sort} />
                  <Preferences
                    hiddenFields={{ freshness: true, currencies: true, lotsViewFormat: true, dimension: true }}
                    right
                    onChange={() => handleChange({ params: searchParams })}
                    rsx={{ perPage: 'artists.header_search.m10' }}
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <Params
                searchScope={searchScope}
                onChange={p => handleChange({ params: p })}
                extras={
                  urlParams.keyword
                    ? [
                        <a href="/search">
                          <div className="search-param">
                            {urlParams.keyword} <i className="fa fa-times" />
                          </div>
                        </a>,
                      ]
                    : []
                }
              />
            </div>
          </>
        )}
        drawer={() => (
          <div style={{ paddingLeft: 15 }}>
            <Item label={i18next.t('search.helpers.items.keyword.select.m1')} name="keyword">
              <span style={{ fontWeight: 'bold', textTransform: 'uppercase', fontStyle: 'italic' }}>{baseParams.keyword}</span>
            </Item>
            <Item name="exact_match">
              <Checkbox label={<A18n rsx="artists.search.form.keyword.m3" />} />
            </Item>
            <Item name="lastname_only">
              <Checkbox label={<A18n rsx="artists.search.form.keyword.m4" />} />
            </Item>
            <hr />
            <CategorySelect />
            <hr />
            <Item label={i18next.t('artists.searchbar.m1')} name="idcountry">
              <Select facet="idcountry" placeholder={i18next.t('search.helpers.items.country.select.m2')} />
            </Item>
            <Item label={i18next.t('artists.searchbar.m2')} name={{ birth_year_from: 'from', birth_year_to: 'to' }}>
              <Years />
            </Item>
            <Item label={i18next.t('artists.searchbar.m3')} name={{ death_year_from: 'from', death_year_to: 'to' }}>
              <Years />
            </Item>
          </div>
        )}
      />
      <LoadingOverlay visible={searching} />
      <Count searchScope={searchScope} />
    </>
  );
};

export default SearchBar;
