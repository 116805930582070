import React from 'react';
import i18next from 'i18next';
import './stylesheets.scss';

const Links = ({ url }) => (
  <div className="lots-tile-links">
    <a className="only-details" href={url}>
      {i18next.t('lots.tile.links.m2')}
    </a>
  </div>
);

export default Links;
