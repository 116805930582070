import React from 'react';
import { Link } from 'react-router';
import './stylesheet.scss';
import { useSelector } from 'react-redux';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { ANALYTICS_OPERATION_AUCTIONEERS_TO_SALE } from '../../../../constants/rails';
import { get } from '../../../../redux/selectors/ui/dictionary';
import { ACTIVE_AAPI_BEACON_FFROM } from '../../../../constants/auctioneers';

const NoCover = () => (
  <div className="card">
    <i className="fa fa-picture-o" />
  </div>
);

const Cover = ({ sale }) => {
  const from = useSelector(state => get(state, { id: ACTIVE_AAPI_BEACON_FFROM }));

  return (
    <div className={`auctioneers-common-cover ${sale.cover !== '' ? '' : 'empty'}`}>
      {sale.fineart && (
        <Link
          to={`/auctioneer/control-center/sale?id=${sale.id}`}
          onClick={() =>
            aapiBeacon('click', {
              from,
              op: ANALYTICS_OPERATION_AUCTIONEERS_TO_SALE,
              idsale: sale.id,
            })
          }
        >
          {sale.cover !== '' && <img className="shadow" src={sale.cover} alt={sale.title} />}
          {sale.cover === '' && <NoCover />}
        </Link>
      )}
      {!sale.fineart && (
        <>
          {sale.cover !== '' && <img className="shadow" src={sale.cover} alt={sale.title} />}
          {sale.cover === '' && <NoCover />}
        </>
      )}
    </div>
  );
};
export default Cover;
