import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import i18next from 'i18next';
import { VERTICAL_BAR_CURRENT_ELEMENT_KEY, SHORTCUTS_LOTS, SHORTCUTS_LOTS_FOLLOW, SHORTCUTS_SETTINGS } from '../../../../../../constants/myartprice';
import { get as getUiDictionary } from '../../../../../../redux/selectors/ui/dictionary';
import NewDataArtists from './NewDataArtists';
import NewDataLots from './NewDataLots';
import './stylesheet.scss';

const Data = ({ handleSectionClick }) => {
  const currentElement = useSelector(state => getUiDictionary(state, { id: VERTICAL_BAR_CURRENT_ELEMENT_KEY }), shallowEqual);

  return (
    <div className="myartprice-common-verticalbar-content-datas">
      <div className="new-data-header">{i18next.t('components.myartprice.common.verticalbar.content.m15').toUpperCase()}</div>
      {SHORTCUTS_LOTS.concat(SHORTCUTS_SETTINGS).includes(currentElement) && <NewDataArtists handleSectionClick={handleSectionClick} currentElement={currentElement} />}
      {SHORTCUTS_LOTS_FOLLOW.includes(currentElement) && <NewDataLots handleSectionClick={handleSectionClick} currentElement={currentElement} />}
    </div>
  );
};

export default Data;
