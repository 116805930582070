import classNames from 'classnames';
import React, { useState } from 'react';
import { aapiBeacon } from '../../../services/analytics/aapi';
import '../../common/stylesheets/buttons.scss';
import { a18n } from '../../../utils/a18n';
import {
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_CLASSIFIEDS_ALL,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_SELL,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_PRICING,
  ANALYTICS_FROM_HOMEPAGE,
  IMAGE_SOURCE,
} from '../../../constants/rails';
import A18n from '../../common/A18n';
import './stylesheet.scss';

const CARDS_CLASSES = [
  ['selected', 'next', 'nextRightSecond'],
  ['prev', 'selected', 'next'],
  ['prevLeftSecond', 'prev', 'selected'],
];

const LEFT = 'left';
const RIGHT = 'right';

function nextStep(currentStep) {
  return currentStep === CARDS_CLASSES[0].length - 1 ? 0 : currentStep + 1;
}

function previousStep(currentStep) {
  return currentStep === 0 ? CARDS_CLASSES[0].length - 1 : currentStep - 1;
}

const Marketplace = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const roll = ({ direction, to }) => {
    if (direction) {
      if (direction === LEFT) {
        setCurrentStep(previousStep);
      } else {
        setCurrentStep(nextStep);
      }
    } else {
      setCurrentStep(to);
    }
  };

  return (
    <div className="homepage-marketplace block">
      <div className="left-bg" />
      <div className="container">
        <div className="content-header">
          <h2>
            <A18n rsx="homepage.marketplace.m1" />
          </h2>
          <p>
            <A18n rsx="homepage.marketplace.m2" />
          </p>
        </div>
        <div className="content-buttons">
          <button type="button" className="artp-btn" onClick={() => roll({ direction: LEFT })}>
            <img src={`${IMAGE_SOURCE}artist/arrow-left.png`} alt="next" />
          </button>
          <button type="button" className="artp-btn" onClick={() => roll({ direction: RIGHT })}>
            <img src={`${IMAGE_SOURCE}artist/arrow-right.png`} alt="next" />
          </button>
        </div>

        <div className="all">
          <a href="/subscription/store" className="artp-btn artp-btn-transparent" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_PRICING })}>
            <A18n rsx="homepage.marketplace.m3" />
          </a>
        </div>
      </div>

      <div className="content-data">
        <div id="carousel">
          <div className={classNames('card', CARDS_CLASSES[currentStep][1])} style={{ backgroundImage: `url('${IMAGE_SOURCE}hp/mktp-2.jpg')` }}>
            {currentStep !== 1 && <div className="over" onClick={() => roll({ to: 1 })} />}
            <h2>
              <A18n rsx="homepage.marketplace.m7" />
            </h2>
            <p>
              <A18n rsx="homepage.marketplace.m8" />
            </p>

            <div className="marketplace-action">
              <a
                href={`/${a18n('routes.marketplace')}`}
                className="artp-btn artp-btn-transparent"
                onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_CLASSIFIEDS_ALL })}
              >
                <A18n rsx="homepage.marketplace.m9" />
              </a>
            </div>
          </div>

          <div className={classNames('card', CARDS_CLASSES[currentStep][2])} style={{ backgroundImage: `url('${IMAGE_SOURCE}hp/mktp-3.jpg')` }}>
            {currentStep !== 2 && <div className="over" onClick={() => roll({ to: 2 })} />}
            <h2>
              <A18n rsx="homepage.marketplace.m10" />
            </h2>
            <p>
              <A18n rsx="homepage.marketplace.m11" />
            </p>

            <div className="marketplace-action">
              <a
                href={`/${a18n('routes.marketplace')}/how-to-sell`}
                className="artp-btn artp-btn-transparent"
                onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_HOW_TO_SELL })}
              >
                <A18n rsx="homepage.marketplace.m12" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
