import i18next from 'i18next';
import { Container } from 'reactstrap';
import { Link } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPastEventsAction, fetchPastNotPartnerEventsAction, selectPageFilterAction } from '../../../../redux/actions/events';
import { getPasts, getPastsNotPartners, getCountriesOptions } from '../../../../redux/selectors/events';
import FiltersBar from '../FiltersBar';
import List from './List';

const RenderLinkToCurrents = ({ choosePage }) => (
  <Link
    to="/events/"
    onClick={() => {
      choosePage(1);
    }}
  >
    {i18next.t('events.link.pasts.to.futures')}
  </Link>
);

const Pasts = () => {
  const dispatch = useDispatch();
  const pasts = useSelector(getPasts);
  const pastsNotPartners = useSelector(getPastsNotPartners);
  const countriesOptions = useSelector(state => getCountriesOptions(state, { isPastList: true }));
  const [hasReceivedData, setHasReceivedData] = useState(false);

  const fetchEvents = () => {
    dispatch(fetchPastEventsAction());
    dispatch(fetchPastNotPartnerEventsAction());
  };

  const choosePage = page => {
    dispatch(selectPageFilterAction({ page }));
    dispatch(fetchPastEventsAction());
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    setHasReceivedData(![pasts, pastsNotPartners].every(l => l === undefined));
  }, [pasts, pastsNotPartners]);

  return (
    <>
      <Container>
        <div className="breadcrumb">
          <li>
            <a href="/">{i18next.t('artprice.lib.navigbarartprice.m_home')}</a>
          </li>
          <li>
            <Link to="/events">{i18next.t('events.list.common.navigationbar.list')}</Link>
          </li>
          <li className="active">{i18next.t('events.list.pasts.partners.title.past')}</li>
        </div>
      </Container>
      <FiltersBar fetchEvents={fetchEvents} options={countriesOptions} linkToOtherList={<RenderLinkToCurrents choosePage={choosePage} />} />

      {hasReceivedData && <List choosePage={choosePage} />}
      {!hasReceivedData && <span>{i18next.t('events.list.pasts.nonefound')}</span>}
    </>
  );
};

export default Pasts;
