import i18next from 'i18next';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSortedArtists } from '../../../../redux/selectors/myartprice/data';
import { updatePreferences } from '../../../../redux/actions/myartprice/settings';
import ModaleArtistFollowSettings from '../../common/ModaleArtistFollowSettings';
import { SHORTCUT_SETTINGS_ARTISTS } from '../../../../constants/myartprice';
import { unfollowArtist } from '../../../../redux/actions/myartprice/data';
import { getPushEmail } from '../../../../redux/slices/userContext/selectors';
import Artist from '../../../common/dbEntitiesSmallViews/Artist';
import '../../common/styles/myartpriceCheckboxes.scss';
import '../../common/styles/myartpriceSettings.scss';
import ArtistAsyncSelect from './ArtistAsyncSelect';
import '../../common/styles/myartpriceBar.scss';
import Config from './Config';
import Checkbox from '../../../common/ui/Checkbox';
import './stylesheet.scss';

const Artists = () => {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState(false);
  const [openModale, setOpenModale] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [selectedIdartist] = useState(null);

  const artistsList = useSelector(state => getSortedArtists(state, { sort: 'name_for_sorting', direction: 'asc' }));
  const hasPushMail = useSelector(getPushEmail);

  const handleOnClose = () => {
    setOpenModale(false);
  };

  const handleDeleteArtist = idartists => {
    dispatch(unfollowArtist({ idartists }));
    setDeleteMode(false);
  };

  const handleSelectAll = () => {
    setIdsToDelete(artistsList.map(artist => artist.id));
    setSelection(true);
  };

  const handleUnselectAll = () => {
    setIdsToDelete([]);
    setSelection(false);
  };

  const handleSelection = (id, check) => {
    if (check) {
      setIdsToDelete([...[id], ...idsToDelete]);
      setSelection(true);
    } else {
      const ids = idsToDelete.filter(idartist => id !== idartist);
      setIdsToDelete(ids);

      if (ids.length === 0) {
        setSelection(false);
      }
    }
  };

  const handleSwitch = (artist, v) => {
    dispatch(updatePreferences(SHORTCUT_SETTINGS_ARTISTS, v, { idartist: artist.id }));
  };

  return (
    <div className="myartprice-settings myartprice-settings-artists myartprice-flipswitches myartprice-checkboxes">
      <ModaleArtistFollowSettings handleOnClose={handleOnClose} open={openModale} idartist={selectedIdartist} />
      <Config />
      <div className="filters-container col-xs-12">
        <ArtistAsyncSelect />
        {artistsList.length > 0 && (
          <div className="delete">
            {!deleteMode && (
              <a href="#" onClick={() => setDeleteMode(true)}>
                <i className="fa fa-trash-o marg marg-r-5" />
                {i18next.t('components.myartprice.settings.artists.m2')}
              </a>
            )}
          </div>
        )}
      </div>
      <div className="col-xs-12">
        {artistsList.length > 0 && (
          <>
            <div className="info">
              {!deleteMode && i18next.t('components.myartprice.settings.artists.m1')}
              {deleteMode && !selection && (
                <a href="#" onClick={() => handleSelectAll()}>
                  <i className="fa fa-square-o marg marg-r-5" />
                  {i18next.t('components.myartprice.artists.m8')}
                </a>
              )}
              {deleteMode && selection && (
                <a href="#" onClick={() => handleUnselectAll()}>
                  <i className="fa fa-check-square-o marg marg-r-5" />
                  {i18next.t('components.myartprice.artists.m9')}
                </a>
              )}
            </div>
            <div className="list">
              {artistsList.map(artist => (
                <div className="artist" key={`artist-${artist.id}`}>
                  {deleteMode && <Checkbox checked={idsToDelete.includes(artist.id) || false} onChange={checked => handleSelection(artist.id, checked)} label={<Artist id={artist.id} />} />}
                  {!deleteMode && (
                    <Checkbox
                      style={{ marginBottom: 5 }}
                      disabled={!hasPushMail}
                      name={`toggle-alerts-${artist.id}`}
                      label={<Artist id={artist.id} />}
                      checked={hasPushMail ? artist.include_in_push : undefined}
                      onChange={v => handleSwitch(artist, v)}
                    />
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {deleteMode && (
        <div className="row delete-bar">
          <div className="col-xs-12">
            <button type="button" className="btn btn-danger marg marg-r-15" disabled={!selection} onClick={() => handleDeleteArtist(idsToDelete)}>
              {i18next.t('components.myartprice.artists.m10')}
            </button>
            <button type="button" className="btn btn-default marg marg-r-15" onClick={() => setDeleteMode(false)}>
              {i18next.t('components.myartprice.settings.artists.m3')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Artists;
