import React from 'react';
import ReduxProvider from '../../../providers/ReduxProvider';
import Viewport from '..';

const WithProviders = () => (
  <ReduxProvider>
    <Viewport />
  </ReduxProvider>
);

export default WithProviders;
