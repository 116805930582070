import _ from 'lodash';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHORTCUT_LOTS_AUCTIONED_OFF, SHORTCUT_LOTS_FUTURE_ALL, SHORTCUT_LOTS_FUTURE_NEW, SHORTCUT_LOTS_MARKETPLACE } from '../../../../../../../constants/myartprice';
import { fetchSettings } from '../../../../../../../redux/actions/myartprice/settings';
import { getFreshness } from '../../../../../../../redux/selectors/preferences';
import { getSettings } from '../../../../../../../redux/selectors/myartprice';
import { Shortcut, ShortcutLink } from '../Shortcut';

const NewDataArtists = ({ currentElement, handleSectionClick }) => {
  const cookieFreshness = useSelector(getFreshness);
  const dispatch = useDispatch();
  const { ended = null, futures = null, auctions = null, fixedPrice = null } = useSelector(getSettings);

  useEffect(() => {
    if (_.isNil(futures) || _.isNil(ended) || _.isNil(auctions) || _.isNil(fixedPrice)) {
      dispatch(fetchSettings());
    }
  }, []);

  const freshnessRsx = () => {
    const freshness = parseInt(cookieFreshness, 10);
    switch (freshness) {
      case 10:
        return i18next.t('components.myartprice.common.verticalbar.content.datas.m1');
      case 5:
        return i18next.t('components.myartprice.common.verticalbar.content.datas.m2');
      default:
        return i18next.t('components.myartprice.common.verticalbar.content.m13');
    }
  };

  return (
    <div className="shortcuts">
      <div className="links" style={{ maxHeight: 22 + 6 * 24 }}>
        {futures && (
          <>
            {currentElement !== SHORTCUT_LOTS_FUTURE_ALL && currentElement !== SHORTCUT_LOTS_FUTURE_NEW && (
              <ShortcutLink text={i18next.t('components.myartprice.common.verticalbar.content.m3')} onClick={() => handleSectionClick(SHORTCUT_LOTS_FUTURE_NEW)} to="/myartprice/artists/lots-futures/new" />
            )}

            {(currentElement === SHORTCUT_LOTS_FUTURE_NEW || currentElement === SHORTCUT_LOTS_FUTURE_ALL) && (
              <>
                <Shortcut text={i18next.t('components.myartprice.common.verticalbar.content.m3')} />
                {(currentElement === SHORTCUT_LOTS_FUTURE_NEW && <Shortcut text={freshnessRsx()} style={{ marginLeft: 15 }} />) || (
                  <ShortcutLink text={freshnessRsx()} onClick={() => handleSectionClick(SHORTCUT_LOTS_FUTURE_NEW)} to="/myartprice/artists/lots-futures/new" style={{ marginLeft: 15 }} />
                )}

                {(currentElement === SHORTCUT_LOTS_FUTURE_ALL && <Shortcut text={i18next.t('components.myartprice.common.verticalbar.content.m14')} style={{ marginLeft: 15 }} />) || (
                  <ShortcutLink
                    text={i18next.t('components.myartprice.common.verticalbar.content.m14')}
                    onClick={() => handleSectionClick(SHORTCUT_LOTS_FUTURE_ALL)}
                    to="/myartprice/artists/lots-futures/all"
                    style={{ marginLeft: 15 }}
                  />
                )}
              </>
            )}
          </>
        )}

        {ended &&
          ((currentElement !== SHORTCUT_LOTS_AUCTIONED_OFF && (
            <ShortcutLink text={i18next.t('components.myartprice.common.verticalbar.content.m4')} onClick={() => handleSectionClick(SHORTCUT_LOTS_AUCTIONED_OFF)} to="/myartprice/artists/lots-auctioned-off" />
          )) || <Shortcut text={i18next.t('components.myartprice.common.verticalbar.content.m4')} />)}

        {(auctions || fixedPrice) &&
          ((currentElement !== SHORTCUT_LOTS_MARKETPLACE && (
            <ShortcutLink text={i18next.t('components.myartprice.common.verticalbar.content.m5')} onClick={() => handleSectionClick(SHORTCUT_LOTS_MARKETPLACE)} to="/myartprice/artists/marketplace" />
          )) || <Shortcut text={i18next.t('components.myartprice.common.verticalbar.content.m5')} />)}
      </div>
    </div>
  );
};

export default NewDataArtists;
