/* eslint-disable global-require,import/no-mutable-exports */
import React from 'react';
import { Route, Routes, BrowserRouter, StaticRouter } from 'react-router';
import { onServer } from '../../../utils/ssr';
import Root from './Root';

const createChildren = routes =>
  routes.map(route => {
    const props = { ...route };
    if (route.children) {
      props.children = createChildren(route.children);
    }

    return <Route key={route.key || route.path} {...props} />;
  });

let RouterProvider;

if (onServer()) {
  RouterProvider = function ({ routes }) {
    const children = createChildren(routes);
    return (
      <StaticRouter location={`${window.location.pathname}${window.location.search || ''}`}>
        <Routes>{children}</Routes>
      </StaticRouter>
    );
  };
} else {
  RouterProvider = function ({ routes, ...props }) {
    const children = createChildren(routes);

    return (
      <BrowserRouter {...props}>
        <Routes>
          <Route path="/" element={<Root />}>
            {children}
          </Route>
        </Routes>
      </BrowserRouter>
    );
  };
}

export default RouterProvider;
