import axios from 'axios';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentPage, updateEntities, updateIdEntities, updateLoading, updateTotalCount } from '../../../redux/actions/myartprice/data';
import { getCurrentPage, getEntities, getIds, getLoading, getTotalCount } from '../../../redux/selectors/myartprice/data';
import { getAdvancedParams } from '../../../redux/slices/search/scoped';
import { getItemsPerpage } from '../../../redux/selectors/preferences';
import { update } from '../../../redux/actions/dbEntities';
import { fetchResults } from '../../../services/search';

export function usePaginatedSearch({ scope }) {
  const perPage = useSelector(getItemsPerpage);
  const dispatch = useDispatch();
  const ids = useSelector(getIds);
  const entities = useSelector(getEntities);
  const totalCount = useSelector(getTotalCount);
  const loading = useSelector(getLoading);
  const page = useSelector(getCurrentPage);
  const advancedParams = useSelector(state => getAdvancedParams(state, { searchScope: scope }));

  const fetch = useCallback(
    params => {
      if (!scope) return;

      dispatch(updateLoading(true));

      return fetchResults({ ...params, ipp: perPage }, scope, true)
        .then(data => {
          if (!isEmpty(data)) {
            const {
              totalCount: innerTotalCount,
              page: innerPage,
              results: { lots, idlot, classifieds, idclassified, idstore, stores },
            } = data;

            dispatch(updateIdEntities(idlot || idclassified || idstore));
            dispatch(update({ classifieds, stores, lots }, true));
            dispatch(updateEntities(lots));
            dispatch(updateTotalCount(innerTotalCount));
            dispatch(updateCurrentPage(innerPage));
            dispatch(updateLoading(false));

            return data;
          }
        })
        .catch(err => {
          if (!axios.isCancel(err)) {
            throw new Error(err);
          }
        });
    },
    [scope, perPage],
  );

  return { fetch, ids, entities, totalCount, loading, page, advancedParams };
}
